import type { FieldErrors, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form'

import Block from '../block'
import RoundedButton from '../button-rounded'
import TextInput from '../text-input'

export interface FormData {
  searchText: string
}

type FormProps = JSX.IntrinsicElements['form']

interface Props extends FormProps {
  handleSubmit: UseFormHandleSubmit<FormData>
  onSearchFormSubmit: (formData: FormData) => void
  register: UseFormRegister<FormData>
  errors: FieldErrors<FormData>
  placeholder?: string
}

export default function SearchForm(props: Props) {
  const { handleSubmit, onSearchFormSubmit, register, errors, placeholder, ...otherFormProps } = props

  return (
    <form {...otherFormProps} className="mb-4" onSubmit={handleSubmit(onSearchFormSubmit)} noValidate>
      <Block
        as="div"
        className="flex flex-col space-y-3 p-4 lg:flex-row lg:items-center lg:justify-between lg:space-x-12 lg:space-y-0"
      >
        <TextInput
          {...register('searchText')}
          type="search"
          labelText={<span className="text-lg font-bold text-mediumGrey">Search For</span>}
          placeholder={placeholder}
          validationMessage={errors?.searchText}
          helpText="TIP: When searching by phone, enter the number without any spaces or dashes."
          helpTextProps={{ className: 'text-sm font-normal text-gray-400' }}
        />

        <RoundedButton type="submit" className="mt-6 justify-center px-20 lg:mt-0 lg:place-self-center">
          Search
        </RoundedButton>
      </Block>
    </form>
  )
}
