import CarIcon from '../../svg/car-icon'
import EmptyContainer from '../empty-container'
import Headline from '../headline'
import NavigationLink from '../navigation-link'

export default function QuoteSearchEmptyResults() {
  return (
    <EmptyContainer>
      <Headline level={4} className="mb-4">
        No active quotes found.
      </Headline>
      <NavigationLink as="button" to="/customers" className="inline-flex">
        <CarIcon className="mx-2 h-6 w-6" />
        Create Quote
      </NavigationLink>
    </EmptyContainer>
  )
}
