import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, RequestBody, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/customer/{customerUuid}'
const method = 'put'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type CustomerUpdateInput = PathParams<ApiOperation> & RequestBody<ApiOperation>

export function customerUpdate(input: CustomerUpdateInput) {
  const { customerUuid, ...body } = input
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, { customerUuid }),
    method,
    data: body,
  })
}

export type CustomerUpdateResult = ResultType<typeof customerUpdate>
