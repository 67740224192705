import type { RideDetailResult } from '../../services/rideDetailGet'
import CarIcon from '../../svg/car-icon'
import Block from '../block'
import DisplayDateRange from '../display-date-range'
import DisplayMoney from '../display-money'
import Headline from '../headline'
import NavigationLink from '../navigation-link'
import TableRow from '../table-row'

import DisplayRideStatus from './display-ride-status'
import RideRelativeDays from './ride-relative-days'

interface Props {
  ride: RideDetailResult
}

export default function RideOverviewInfoTable(props: Props) {
  const { ride } = props

  const {
    reservation,
    itinerary,
    rideDate,
    riders,
    status,
    totalPaid,
    totalRefunded,
    totalScheduled,
    totalUnpaid,
    hasManualPayments,
    isFullyPaid,
  } = ride
  const { eventName, externalId, serviceType, vehicleType, vehicleCapacity, numberOfRiders } = reservation

  const pickup = itinerary.at(0)
  const dropOff = itinerary.at(-1)

  const startDate = pickup?.date ?? rideDate
  const endDate = dropOff?.date ?? rideDate

  const groupLead = riders.find(rider => rider.riderRole === 'groupLeader')

  const riderCount = riders.filter(x => x.riderStatus === 'rider').length
  const invitedCount = riders.filter(x => x.riderStatus === 'invited').length

  return (
    <Block as="div" className="mb-6 p-6">
      <div className="mb-6 flex flex-col justify-between gap-y-4 border-b-2 border-darkGrey/10 pb-3 md:flex-row">
        <Headline level={3} className="inline-flex items-center gap-x-1.5">
          <CarIcon className="-ml-0.5 h-5 w-6 text-formIconsOnLight" aria-hidden="true" /> Ride Details
        </Headline>
        <RideRelativeDays startDate={startDate} endDate={endDate} />
        <DisplayRideStatus rideStatus={status} />
      </div>
      <div className="divide-y">
        {!!externalId && <TableRow label="ID">{externalId}</TableRow>}
        <TableRow label="Event Name">{eventName}</TableRow>
        <TableRow label="Ride Dates">
          <DisplayDateRange startDate={startDate} endDate={endDate} />
        </TableRow>
        <TableRow label="Organizer">
          <NavigationLink to={`/customers/${groupLead?.customerUuid}`}>{groupLead?.fullName}</NavigationLink>
        </TableRow>
        <TableRow label="Service">
          <span className="capitalize">{serviceType}</span>
        </TableRow>
        <TableRow label="Vehicle">
          <span className="capitalize">{vehicleType}</span>
        </TableRow>
        <TableRow label="Vehicle Capacity">{vehicleCapacity}</TableRow>
        <TableRow label="# of Riders (planned)">{numberOfRiders}</TableRow>
        <TableRow label="# of Riders (app)">
          {!!riderCount && <span className="mr-3 text-successOnLight">{riderCount} signed up</span>}
          {!!invitedCount && <span className="text-mediumGrey">{invitedCount} invited</span>}
        </TableRow>
        <Headline level={5} className="py-6 text-mediumGrey">
          Payment Status
        </Headline>
        <TableRow label="Paid in Full">
          {isFullyPaid ? 'Yes' : 'No'}
          {hasManualPayments && <>*</>}
        </TableRow>
        {totalScheduled > 0 && (
          <TableRow label="Total Scheduled">
            <DisplayMoney amount={totalScheduled} />
          </TableRow>
        )}
        {totalRefunded > 0 && (
          <TableRow className="font-bold text-primary" label="Total Refunded">
            <DisplayMoney amount={totalRefunded} />
          </TableRow>
        )}
        {totalUnpaid > 0 && (
          <TableRow className="font-bold text-successOnLight" label="Total Paid">
            <DisplayMoney amount={totalPaid} />
            {hasManualPayments && <>*</>}
          </TableRow>
        )}
        {totalUnpaid > 0 && (
          <TableRow className="font-bold text-errorOnLight" label="Total Unpaid">
            <DisplayMoney amount={totalUnpaid} />
          </TableRow>
        )}
      </div>
      {hasManualPayments && (
        <span className="mt-4 text-xs font-normal text-mediumGrey">
          * includes manual payments which are not verified by the system
        </span>
      )}
    </Block>
  )
}
