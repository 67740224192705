import { Fragment } from 'react'

import { Menu, Transition } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

import AvatarImage from '../components/avatar-image'
import AvatarInitials from '../components/avatar-initials'
import type { User } from '../context/app-state'

import userNavigation from './navigation-routes-user'

interface Props {
  user: User
}

export default function DesktopUserMenu(props: Props) {
  const { user } = props
  return (
    <Menu as="div" className="relative ml-3 flex-shrink-0">
      <div>
        <Menu.Button className="flex rounded-full bg-primary text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary">
          <span className="sr-only">Open user menu</span>
          {user.imageUrl ? (
            <AvatarImage size="sm" imageUrl={user.imageUrl} />
          ) : (
            <AvatarInitials size="sm" initials={user.initials} className="bg-buttonPrimary dark:bg-tertiary" />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map(item => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <a
                  href={item.href}
                  className={twMerge(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-grey700')}
                >
                  {item.name}
                </a>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
