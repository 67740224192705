import MessageBanner from '../message-banner'

import { LoginFormActionType, useLoginContext, useLoginFormDispatch } from './login-form-context'

export default function Messages() {
  const { successMessage, errorMessage } = useLoginContext()
  const loginFormDispatch = useLoginFormDispatch()

  const onErrorCloseClick = () => {
    loginFormDispatch({
      type: LoginFormActionType.SetLoginState,
      payload: { errorMessage: undefined },
    })
  }

  const onSuccessCloseClick = () => {
    loginFormDispatch({
      type: LoginFormActionType.SetLoginState,
      payload: { successMessage: undefined },
    })
  }

  return (
    <>
      {successMessage && (
        <MessageBanner variant="positive" message={successMessage} onCloseClick={onSuccessCloseClick} />
      )}
      {errorMessage && <MessageBanner variant="negative" message={errorMessage} onCloseClick={onErrorCloseClick} />}
    </>
  )
}
