import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { DateTime } from 'luxon'

import type { ItineraryItem } from '../services/types'
import getDateTimeValue from '../utils/getDateTimeValue'

import RoundedButton from './button-rounded'
import FormBlocker from './form-blocker'
import TextInput from './text-input'

interface FormData {
  location: string
  date: string
  time: string
}

interface Props {
  itineraryItem: ItineraryItem
  open: boolean
  updateItineraryItem: (item: ItineraryItem) => void
  onClose: () => void
}

export default function ItineraryEdit({ itineraryItem, open, updateItineraryItem, onClose }: Props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: itineraryItem })

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setSubmitting(false)
    reset(itineraryItem)
  }, [itineraryItem, reset])

  const onUpdate = (data: FormData) => {
    if (!itineraryItem) {
      return
    }

    const newTime = getDateTimeValue(data.time)

    const update = {
      date: data.date,
      uuid: itineraryItem.uuid,
      location: data.location,
      time: newTime?.toLocaleString(DateTime.TIME_24_WITH_SECONDS) || '',
    }

    setSubmitting(true)

    updateItineraryItem(update)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-base font-semibold leading-6 text-darkGrey">
                            {itineraryItem?.location || 'New Location'}
                          </Dialog.Title>
                        </div>
                        <div className="flex h-7 items-center">
                          <button type="button" className="text-lightGrey hover:text-grey500" onClick={onClose}>
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Content */}
                    <FormBlocker active={submitting}>
                      <form
                        className="flex flex-1 flex-col space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0"
                        onSubmit={handleSubmit(onUpdate)}
                        noValidate
                      >
                        {/* Form Fields */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-1 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <TextInput
                              {...register('location', { required: true })}
                              labelText="Location"
                              className="mb-8"
                              placeholder="Enter the location of the stop"
                              multiline
                              rows={5}
                              validationMessage={errors.location?.message}
                            />
                          </div>
                          <div>
                            <TextInput
                              {...register('date', { required: true })}
                              labelText="Date"
                              className="mb-8"
                              placeholder="Enter the date of the stop"
                              type="date"
                              validationMessage={errors.date?.message}
                            />
                          </div>
                          <div>
                            <TextInput
                              {...register('time', { required: true })}
                              labelText="Time (approx.)"
                              className="mb-8"
                              placeholder="Enter the time of the stop"
                              type="time"
                              validationMessage={errors.time?.message}
                            />
                          </div>
                        </div>
                        {/* Action buttons */}
                        <div className="flex-shrink-0 border-t border-formBorderOnLight px-4 py-5 sm:px-6">
                          <div className="flex justify-end space-x-3">
                            <RoundedButton variant="secondary" className="px-10" onClick={onClose}>
                              Cancel
                            </RoundedButton>
                            <RoundedButton variant="positive" className="px-10" type="submit">
                              Update
                            </RoundedButton>
                          </div>
                        </div>
                      </form>
                    </FormBlocker>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
