import type { ReactNode } from 'react'

import { DateTime } from 'luxon'

import getDateTimeValue from '../utils/getDateTimeValue'

type SpanProps = JSX.IntrinsicElements['span']

interface Props extends SpanProps {
  startDate: Date | string | undefined | null
  startOptions?: Intl.DateTimeFormatOptions
  startProps?: SpanProps
  endDate: Date | string | undefined | null
  endOptions?: Intl.DateTimeFormatOptions
  endProps?: SpanProps
  junction?: ReactNode
}

export default function DisplayDateRange({
  startDate,
  endDate,
  junction,
  startOptions,
  endOptions,
  startProps,
  endProps,
  ...spanProps
}: Props) {
  const dtStart = getDateTimeValue(startDate)
  const dtEnd = getDateTimeValue(endDate)

  const areEqual = dtStart && dtEnd && dtStart.equals(dtEnd)

  const fallbackOptions = DateTime.DATE_SHORT

  const startValue = dtStart && dtStart.toLocaleString(startOptions ?? endOptions ?? fallbackOptions)
  const endValue = dtEnd && dtEnd.toLocaleString(endOptions ?? startOptions ?? fallbackOptions)

  return (
    <span {...spanProps}>
      {areEqual && <span {...startProps}>{startValue}</span>}
      {startValue && !endValue && <span {...startProps}>{startValue}</span>}
      {endValue && !startValue && <span {...startProps}>{endValue}</span>}
      {!areEqual && startValue && endValue && (
        <>
          <span className="sr-only">From </span>
          <span {...startProps}>{startValue}</span>
          <span className="sr-only"> to </span>
          {junction ?? ' - '}
          <span {...endProps}>{endValue}</span>
        </>
      )}
    </span>
  )
}
