import { useCallback, useEffect, useState } from 'react'
import type { MouseEvent } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { ArrowLeftCircleIcon, ArrowRightIcon, CurrencyDollarIcon, UserIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { DateTime } from 'luxon'

import Block from '../components/block'
import RoundedButton from '../components/button-rounded'
import DisplayDate from '../components/display-date'
import DisplayDateRange from '../components/display-date-range'
import Headline from '../components/headline'
import LoadingDetailPage from '../components/loading-detail-page'
import NavigationLink from '../components/navigation-link'
import DisplayQuoteStatus from '../components/quotes/display-quote-status'
import QuoteDeleteModal from '../components/quotes/quote-delete-modal'
import QuoteSendModal from '../components/quotes/quote-send-modal'
import DisplayUser from '../components/shared/display-user'
import RideItinerary from '../components/shared/ride-itinerary'
import TextInput from '../components/text-input'
import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'
import type { QuoteGetResult } from '../services/quoteGet'
import { quoteGet } from '../services/quoteGet'
import { quoteItineraryCreate } from '../services/quoteItineraryCreate'
import { quoteItineraryDelete } from '../services/quoteItineraryDelete'
import { quoteItineraryUpdate, type QuoteItineraryUpdateResult } from '../services/quoteItineraryUpdate'
import { quoteUpdate } from '../services/quoteUpdate'
import type { ItineraryItem } from '../services/types'
import CarIcon from '../svg/car-icon'
import createErrorToast from '../utils/createErrorToast'
import createSuccessToast from '../utils/createSuccessToast'

interface FormData {
  eventName: string
  numberOfRiders: number
  vehicleCapacity: number
  expiresAt: string
  externalId: string
  notes: string
  vehicleType: string
  serviceType: string
  depositAmount: number
  depositDueDate: string
  totalAmount: number
  totalDueDate: string
}

export default function QuoteEdit() {
  const dispatch = useAppDispatch()
  const { addToast } = useToasts()
  const { id } = useParams()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>()

  const [quote, setQuote] = useState<QuoteGetResult>()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [sendModalOpen, setSendModalOpen] = useState<boolean>(false)

  const eventName = watch('eventName')
  const expiresAt = watch('expiresAt')

  const quoteUuid = id ?? ''

  const { quoteStatus = 'draft' } = quote ?? {}
  const isEditable = quoteStatus !== 'booked' && quoteStatus !== 'cancelled' && quoteStatus !== 'declined'

  const setQuoteData = useCallback(
    (data: QuoteGetResult) => {
      setQuote(data)
      setValue('eventName', data.eventName)
      setValue('numberOfRiders', data.numberOfRiders)
      setValue('vehicleCapacity', data.vehicleCapacity)
      setValue('expiresAt', data.expiresAt)
      setValue('externalId', data.externalId || '')
      setValue('notes', data.notes || '')
      setValue('vehicleType', data.vehicleType)
      setValue('serviceType', data.serviceType)
      setValue('depositAmount', data.depositAmount)
      setValue('depositDueDate', data.depositDueDate)
      setValue('totalAmount', data.totalAmount)
      setValue('totalDueDate', data.totalDueDate)
    },
    [setValue],
  )

  const fetchQuoteData = useCallback(() => {
    quoteGet({ quoteUuid }).then(setQuoteData)
  }, [quoteUuid, setQuoteData])

  const onSubmit = useCallback(
    async (data: FormData) => {
      if (!quoteUuid) {
        return Promise.reject(new Error('Quote UUID is required'))
      }

      return quoteUpdate({
        quoteUuid,
        depositAmount: data.depositAmount,
        depositDueDate: data.depositDueDate,
        expiresAt: data.expiresAt,
        externalId: data.externalId,
        eventName: data.eventName,
        notes: data.notes,
        numberOfRiders: data.numberOfRiders,
        totalAmount: data.totalAmount,
        totalDueDate: data.totalDueDate,
        vehicleType: data.vehicleType,
        vehicleCapacity: data.vehicleCapacity,
        serviceType: data.serviceType,
      })
        .then(updatedQuote => {
          createSuccessToast('Quote saved', addToast)
          setQuoteData(updatedQuote)
        })
        .catch(err => {
          createErrorToast(err, addToast)
        })
    },
    [quoteUuid, addToast, setQuoteData],
  )

  const openDeleteModal = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setDeleteModalOpen(true)
  }, [])

  const closeDeleteModal = useCallback(() => {
    setDeleteModalOpen(false)
  }, [])

  const openSendModal = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      handleSubmit(formData => {
        onSubmit(formData).then(() => {
          setSendModalOpen(true)
        })
      })()
    },
    [handleSubmit, onSubmit],
  )

  const closeSendModal = useCallback(
    (sentQuote?: QuoteGetResult) => {
      setSendModalOpen(false)
      if (sentQuote) {
        setQuoteData(sentQuote)
      }
    },
    [setQuoteData],
  )

  const deleteItineraryItem = (itemUuid: string) => {
    if (!quote) {
      return
    }
    quoteItineraryDelete({
      quoteUuid: quote.quoteUuid,
      itemUuid,
    })
      .then(() => fetchQuoteData())
      .catch(err => {
        createErrorToast(err, addToast)
      })
  }

  const toggleItineraryDrawer = useCallback(() => {
    setDrawerOpen(s => !s)
  }, [])

  const onItineraryUpdated = useCallback(
    (itinerary: QuoteItineraryUpdateResult) => {
      if (quote) {
        setQuote({
          ...quote,
          itinerary,
        })
      }
      toggleItineraryDrawer()
    },
    [quote, toggleItineraryDrawer],
  )

  const updateItineraryItem = (itineraryItem: ItineraryItem) => {
    if (!quoteUuid) {
      return
    }

    const { uuid, date, location, time } = itineraryItem

    if (uuid) {
      const updatedItem = {
        date,
        itemUuid: uuid,
        location,
        time,
        quoteUuid,
      }

      quoteItineraryUpdate(updatedItem)
        .then(onItineraryUpdated)
        .catch(err => {
          createErrorToast(err, addToast)
        })
    } else {
      const newItem = {
        quoteUuid,
        time,
        date,
        location,
      }

      quoteItineraryCreate(newItem)
        .then(onItineraryUpdated)
        .catch(err => {
          createErrorToast(err, addToast)
        })
    }
  }

  useEffect(() => {
    dispatch({ type: AppStateActionType.SetNavTitle, payload: 'Edit Quote' })
    dispatch({
      type: AppStateActionType.SetBackPanel,
      payload: (
        <NavigationLink to="/" className="inline-flex items-center gap-x-1.5" variant="accent">
          <ArrowLeftCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> Back to All Quotes
        </NavigationLink>
      ),
    })
  }, [dispatch])

  useEffect(() => {
    dispatch({
      type: AppStateActionType.SetActionPanel,
      payload: (
        <>
          {isEditable && (
            <RoundedButton onClick={handleSubmit(onSubmit)} size="lg" className="px-12" variant="accent">
              Save Quote
            </RoundedButton>
          )}
          {quoteStatus === 'booked' && (
            <NavigationLink as="button" to={`/rides/${quoteUuid}`} className="px-12" variant="accent">
              Go to Ride <ArrowRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </NavigationLink>
          )}
        </>
      ),
    })
  }, [dispatch, handleSubmit, isEditable, onSubmit, quoteStatus, quoteUuid])

  useEffect(() => {
    fetchQuoteData()
  }, [fetchQuoteData])

  if (!quote) {
    return <LoadingDetailPage />
  }

  return (
    <div>
      <div className="mb-4 flex items-center justify-between p-4 md:p-0">
        <div className="mb-4 flex flex-col md:flex-row md:items-end">
          <Headline level={2}>
            <span className="text-mainHeadline">{eventName}</span>
          </Headline>
          <DisplayDateRange
            className="font-medium text-grey500 md:ml-4"
            startDate={quote?.itinerary?.[0]?.date}
            endDate={quote?.itinerary?.at(-1)?.date}
            startOptions={{ weekday: 'short', month: 'short', day: 'numeric' }}
            endOptions={DateTime.DATE_MED_WITH_WEEKDAY}
            junction=" thru "
          />
        </div>
        <DisplayQuoteStatus quoteStatus={quote?.quoteStatus ?? 'draft'} />
      </div>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div>
          <div className="flex flex-col gap-x-4 border-b border-darkGrey/10">
            <div className="flex w-full flex-1 flex-col gap-x-6 gap-y-8">
              <Block as="div" className="p-6">
                <div className="mb-6 flex flex-row justify-between border-b-2 border-darkGrey/10 pb-3">
                  <Headline level={3} className="inline-flex items-center gap-x-1.5">
                    <UserIcon className="-ml-0.5 h-5 w-6 text-formIconsOnLight" aria-hidden="true" /> Event Organizer
                  </Headline>
                </div>
                <DisplayUser user={quote?.customer} />
              </Block>
              {isEditable && (
                <Block as="div" className="mt-6 flex flex-row justify-between bg-primary p-6">
                  <TextInput
                    {...register('expiresAt', { required: true })}
                    labelText="Quote Expiration"
                    placeholder="Enter the expiration date for the quote"
                    type="date"
                    labelAccessory={
                      <DisplayDate
                        date={expiresAt}
                        relative
                        className="dark:text-negative hidden text-errorOnLight sm:inline"
                      >
                        {date => <>expires {date}</>}
                      </DisplayDate>
                    }
                    validationMessage={errors.expiresAt?.message}
                  />
                  {quote && (
                    <>
                      <QuoteSendModal open={sendModalOpen} onComplete={closeSendModal} quote={quote} />
                      <RoundedButton
                        variant="accent"
                        onClick={openSendModal}
                        className="ml-6 flex flex-nowrap items-center self-end"
                      >
                        <PaperAirplaneIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
                        {quoteStatus === 'draft' ? 'Send' : 'Resend'}
                        <span className="hidden sm:inline">&nbsp;Now</span>
                      </RoundedButton>
                    </>
                  )}
                </Block>
              )}
              <Block as="div" className="mt-6 p-6">
                <div className="mb-6 flex flex-row justify-between border-b-2 border-darkGrey/10 pb-3">
                  <Headline level={3} className="inline-flex items-center gap-x-1.5">
                    <CarIcon className="-ml-0.5 h-5 w-6 text-formIconsOnLight" aria-hidden="true" /> Ride Details
                  </Headline>
                </div>
                <div className="mt-4 flex flex-col justify-between gap-y-3 lg:flex-row lg:gap-x-3">
                  <TextInput
                    {...register('eventName', {
                      required: 'Event Name required',
                      maxLength: {
                        value: 255,
                        message: 'Event Name must be less than 255 characters',
                      },
                    })}
                    labelText="Event Name"
                    placeholder="Enter the name for the group"
                    type="text"
                    validationMessage={errors.eventName?.message}
                  />
                  <TextInput
                    {...register('externalId')}
                    labelText="External ID"
                    placeholder="(optional) Enter LimoAnywhere number or other vendor system identifier"
                    type="text"
                    helpText="TIP: This value is searchable."
                  />
                </div>
                <div className="mt-4 flex flex-col justify-between gap-y-3 lg:flex-row lg:gap-x-3">
                  <TextInput
                    {...register('serviceType', {
                      required: 'Service Type required',
                      maxLength: {
                        value: 255,
                        message: 'Service Type must be less than 255 characters',
                      },
                    })}
                    labelText="Service Type"
                    placeholder="Enter the service type for the quote"
                    type="text"
                    validationMessage={errors.serviceType?.message}
                  />
                  <TextInput
                    {...register('vehicleType', {
                      required: 'Vehicle Type required',
                      maxLength: {
                        value: 255,
                        message: 'Vehicle Type must be less than 255 characters',
                      },
                    })}
                    labelText="Vehicle Type"
                    placeholder="Enter the type of vehicle for the group"
                    type="text"
                    validationMessage={errors.vehicleType?.message}
                  />
                </div>
                <div className="mt-4 flex flex-col justify-between gap-y-3 lg:flex-row lg:gap-x-3">
                  <TextInput
                    {...register('numberOfRiders', {
                      min: {
                        value: 1,
                        message: 'Number of riders must be 1 or more',
                      },
                    })}
                    labelText="Number of riders"
                    placeholder="Enter the number of riders"
                    type="number"
                    min={1}
                    validationMessage={errors.numberOfRiders?.message}
                  />
                  <TextInput
                    {...register('vehicleCapacity', {
                      min: {
                        value: 1,
                        message: 'Vehicle Capacity must be 1 or more',
                      },
                    })}
                    labelText="Vehicle Capacity"
                    placeholder="Enter the vehicle's capacity"
                    type="number"
                    min={1}
                    validationMessage={errors.vehicleCapacity?.message}
                  />
                </div>
              </Block>
              <Block as="div" className="mt-3 p-6">
                <div className="mb-6 flex flex-row justify-between border-b-2 border-darkGrey/10 pb-3">
                  <Headline level={3} className="inline-flex items-center gap-x-1.5">
                    <CurrencyDollarIcon className="-ml-0.5 h-5 w-5 text-formIconsOnLight" aria-hidden="true" /> Ride
                    Costs
                  </Headline>
                </div>
                <div className="mt-4 flex flex-col justify-between gap-y-3 lg:flex-row lg:gap-x-3">
                  <TextInput
                    {...register('totalAmount', {
                      min: {
                        value: 1,
                        message: 'Total Amount required',
                      },
                    })}
                    labelText="Total Amount"
                    placeholder="Enter the total amount due"
                    type="number"
                    min={1}
                    validationMessage={errors.totalAmount?.message}
                  />
                  <TextInput
                    {...register('totalDueDate', { required: true })}
                    labelText="Total Due Date"
                    placeholder="Enter the due date for the total"
                    type="date"
                    validationMessage={errors.totalDueDate?.message}
                  />
                </div>
                <div className="mt-4 flex flex-col justify-between gap-y-3 lg:flex-row lg:gap-x-3">
                  <TextInput
                    {...register('depositAmount', {
                      min: {
                        value: 0,
                        message: 'Deposit cannot be less than 0',
                      },
                    })}
                    labelText="Deposit Amount"
                    placeholder="Enter the amount of the deposit"
                    type="number"
                    min={1}
                    validationMessage={errors.depositAmount?.message}
                    helpText="Enter 0 if no deposit is required"
                  />

                  <TextInput
                    {...register('depositDueDate')}
                    labelText="Deposit Due Date"
                    placeholder="Enter the due date for the deposit"
                    type="date"
                    validationMessage={errors.depositDueDate?.message}
                  />
                </div>
              </Block>
              <RideItinerary
                itinerary={quote?.itinerary}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                updateItineraryItem={updateItineraryItem}
                deleteItineraryItem={deleteItineraryItem}
                editable={isEditable}
              />
              <div className="mt-4 w-full p-4 md:p-0">
                <TextInput
                  {...register('notes')}
                  labelText="Notes"
                  placeholder="Enter any notes for the quote"
                  multiline
                  rows={6}
                />
              </div>
            </div>
          </div>
        </div>
        {isEditable && (
          <div className="mt-6 flex items-center justify-between p-4 md:p-0">
            <QuoteDeleteModal
              open={deleteModalOpen}
              onComplete={closeDeleteModal}
              quoteUuid={quoteUuid}
              quoteStatus={quoteStatus}
            />
            <RoundedButton variant="negative" onClick={openDeleteModal} className="mr-5 flex flex-nowrap items-center">
              <XMarkIcon className="-ml-0.5 h-5 w-5 " aria-hidden="true" />
              {quoteStatus === 'draft' ? 'Delete Quote' : 'Cancel Quote'}
            </RoundedButton>
            <RoundedButton type="submit" size="lg" className="px-12" variant="accent">
              Save Quote
            </RoundedButton>
          </div>
        )}
        <div className="mt-6 p-4 text-gray-500 md:p-0">
          Last updated: <DisplayDate date={quote?.updatedAt} relative />
        </div>
      </form>
    </div>
  )
}
