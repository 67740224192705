import type { DateTime } from 'luxon'

export default function addBusinessDays(date: DateTime, days: number): DateTime {
  const newDate = date.plus({ days })
  if (newDate.weekday === 6) {
    return addBusinessDays(newDate, 2)
  }
  if (newDate.weekday === 7) {
    return addBusinessDays(newDate, 1)
  }
  return newDate
}
