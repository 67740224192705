import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import { ArrowLeftIcon } from '@heroicons/react/20/solid'

import { AppStateActionType, useAppDispatch } from '../../context/app-state-reducer'
import { RideStateActionType, useRideDispatch } from '../../context/ride-state-reducer'
import type { RideDetailResult } from '../../services/rideDetailGet'
import { rideUpdate } from '../../services/rideUpdate'
import CarIcon from '../../svg/car-icon'
import createErrorToast from '../../utils/createErrorToast'
import createSuccessToast from '../../utils/createSuccessToast'
import Block from '../block'
import RoundedButton from '../button-rounded'
import DisplayDate from '../display-date'
import Headline from '../headline'
import TextInput from '../text-input'

interface FormData {
  eventName: string
  numberOfRiders: number
  vehicleCapacity: number
  externalId: string
  notes: string
  vehicleType: string
  serviceType: string
}

interface Props {
  ride: RideDetailResult
}

export default function RideEdit(props: Props) {
  const appDispatch = useAppDispatch()
  const rideDispatch = useRideDispatch()
  const { addToast } = useToasts()

  const { ride } = props
  const { uuid: rideUuid, reservation, adminNotes, updatedAt } = ride

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: {
      eventName: reservation.eventName,
      numberOfRiders: reservation.numberOfRiders,
      vehicleCapacity: reservation.vehicleCapacity,
      externalId: reservation.externalId || '',
      notes: adminNotes || '',
      vehicleType: reservation.vehicleType,
      serviceType: reservation.serviceType,
    },
  })

  const onSubmit = useCallback(
    (data: FormData) => {
      rideUpdate({
        rideUuid,
        eventName: data.eventName,
        numberOfRiders: data.numberOfRiders,
        vehicleCapacity: data.vehicleCapacity,
        externalId: data.externalId,
        notes: data.notes,
        vehicleType: data.vehicleType,
        serviceType: data.serviceType,
      })
        .then(updatedRide => {
          rideDispatch({ type: RideStateActionType.SetRide, payload: updatedRide })
          rideDispatch({ type: RideStateActionType.SetEditMode, payload: 'view' })
          createSuccessToast('Ride saved', addToast)
        })
        .catch(err => {
          createErrorToast(err, addToast)
        })
    },
    [rideUuid, rideDispatch, addToast],
  )

  const goBack = useCallback(() => {
    rideDispatch({ type: RideStateActionType.SetEditMode, payload: 'view' })
  }, [rideDispatch])

  useEffect(() => {
    appDispatch({
      type: AppStateActionType.SetActionPanel,
      payload: (
        <>
          <RoundedButton onClick={goBack} size="lg" className="mr-4 px-12" variant="secondary">
            <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" /> Back
          </RoundedButton>
          <RoundedButton onClick={handleSubmit(onSubmit)} size="lg" className="px-12" variant="accent">
            Save Ride
          </RoundedButton>
        </>
      ),
    })
  }, [appDispatch, goBack, handleSubmit, onSubmit])

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div>
        <div className="flex flex-col gap-x-4 border-b border-darkGrey/10">
          <div className="flex w-full flex-1 flex-col gap-x-6 gap-y-8">
            <Block as="div" className="mt-6 p-6">
              <div className="mb-6 flex flex-row justify-between border-b-2 border-darkGrey/10 pb-3">
                <Headline level={3} className="inline-flex items-center gap-x-1.5">
                  <CarIcon className="-ml-0.5 h-5 w-6 text-formIconsOnLight" aria-hidden="true" /> Edit Ride Details
                </Headline>
              </div>
              <div className="mt-4 flex flex-col justify-between gap-y-3 lg:flex-row lg:gap-x-3">
                <TextInput
                  {...register('eventName', {
                    required: 'Event Name required',
                    maxLength: {
                      value: 255,
                      message: 'Event Name must be less than 255 characters',
                    },
                  })}
                  labelText="Event Name"
                  placeholder="Enter the name for the group"
                  type="text"
                  validationMessage={errors.eventName?.message}
                />
                <TextInput
                  {...register('externalId')}
                  labelText="External ID"
                  placeholder="(optional) Enter LimoAnywhere number or other vendor system identifier"
                  type="text"
                  helpText="TIP: This value is searchable."
                />
              </div>
              <div className="mt-4 flex flex-col justify-between gap-y-3 lg:flex-row lg:gap-x-3">
                <TextInput
                  {...register('serviceType', {
                    required: 'Service Type required',
                    maxLength: {
                      value: 255,
                      message: 'Service Type must be less than 255 characters',
                    },
                  })}
                  labelText="Service Type"
                  placeholder="Enter the service type for the quote"
                  type="text"
                  validationMessage={errors.serviceType?.message}
                />
                <TextInput
                  {...register('vehicleType', {
                    required: 'Vehicle Type required',
                    maxLength: {
                      value: 255,
                      message: 'Vehicle Type must be less than 255 characters',
                    },
                  })}
                  labelText="Vehicle Type"
                  placeholder="Enter the type of vehicle for the group"
                  type="text"
                  validationMessage={errors.vehicleType?.message}
                />
              </div>
              <div className="mt-4 flex flex-col justify-between gap-y-3 lg:flex-row lg:gap-x-3">
                <TextInput
                  {...register('numberOfRiders', {
                    min: {
                      value: 1,
                      message: 'Number of riders must be 1 or more',
                    },
                  })}
                  labelText="Number of riders"
                  placeholder="Enter the number of riders"
                  type="number"
                  min={1}
                  validationMessage={errors.numberOfRiders?.message}
                />
                <TextInput
                  {...register('vehicleCapacity', {
                    min: {
                      value: 1,
                      message: 'Vehicle Capacity must be 1 or more',
                    },
                  })}
                  labelText="Vehicle Capacity"
                  placeholder="Enter the vehicle's capacity"
                  type="number"
                  min={1}
                  validationMessage={errors.vehicleCapacity?.message}
                />
              </div>
              <div className="mt-8 w-full">
                <TextInput
                  {...register('notes')}
                  labelText="Notes"
                  placeholder="Enter any notes for the ride"
                  multiline
                  rows={6}
                />
              </div>
            </Block>
          </div>
        </div>
      </div>
      <div className="mt-4 font-medium text-mediumGrey">
        Last updated: <DisplayDate date={updatedAt} relative />
      </div>
    </form>
  )
}
