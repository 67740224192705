import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'

import CircleLogo from '../svg/circle-logo'

export default function CustomerLayout() {
  return (
    <>
      <Helmet title="Login" titleTemplate="%s | Catch Transport Admin" htmlAttributes={{ 'data-mode': 'light' }} />
      <div
        className="flex min-h-screen flex-col bg-white bg-repeat"
        style={{ backgroundImage: 'url(background-pattern.png)' }}
      >
        <div className="w-full bg-primary">
          <CircleLogo className="mx-auto my-2" height={118} />
        </div>
        <div className="mx-auto w-full xl:w-[60%]">
          <div className="flex min-h-full flex-1">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}
