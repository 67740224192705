import { useCallback, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

import { AppStateActionType, useAppDispatch } from '../../context/app-state-reducer'
import { useRideContext } from '../../context/ride-state'
import { RideStateActionType, useRideDispatch } from '../../context/ride-state-reducer'
import type { RideDetailResult } from '../../services/rideDetailGet'
import RoundedButton from '../button-rounded'

import RideEdit from './ride-edit'
import RideManager from './ride-manager'
import RideOverviewCosts from './ride-overview-costs'
import RideOverviewInfoTable from './ride-overview-info-table'
import RideScheduleManager from './ride-schedule-manager'

interface RideOverviewProps {
  ride: RideDetailResult
}

export default function RideOverview(props: RideOverviewProps) {
  const { editMode } = useRideContext()
  const rideDispatch = useRideDispatch()
  const appDispatch = useAppDispatch()
  const { ride } = props
  const { adminNotes } = ride

  const toggleEditMode = useCallback(() => {
    rideDispatch({ type: RideStateActionType.SetEditMode, payload: editMode === 'edit' ? 'view' : 'edit' })
  }, [editMode, rideDispatch])

  useEffect(() => {
    if (editMode === 'edit') {
      return
    }

    appDispatch({
      type: AppStateActionType.SetActionPanel,
      payload: (
        <RoundedButton onClick={toggleEditMode} size="lg" className="px-12" variant="secondary">
          Edit Ride
        </RoundedButton>
      ),
    })
  }, [appDispatch, editMode, toggleEditMode])

  if (editMode === 'edit') {
    return <RideEdit ride={ride} />
  }

  return (
    <>
      <RideOverviewInfoTable ride={ride} />
      {!!adminNotes && (
        <section className="px-4 py-5 sm:p-6">
          <h2 className="text-lg font-medium text-gray-900">Notes</h2>
          <div className="prose prose-sm mt-3 text-gray-500">
            <ReactMarkdown>{adminNotes}</ReactMarkdown>
          </div>
        </section>
      )}
      <RideOverviewCosts ride={ride} />
      <RideScheduleManager />
      <RideManager ride={ride} />
    </>
  )
}
