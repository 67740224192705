import { useState } from 'react'

import { CurrencyDollarIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/20/solid'

import type { RideDetailResult } from '../../services/rideDetailGet'
import Block from '../block'
import RoundedButton from '../button-rounded'
import DisplayDate from '../display-date'
import DisplayMoney from '../display-money'
import Headline from '../headline'
import TableRow from '../table-row'

import AddOverageCharge from './add-overage-charge'

interface Props {
  ride: RideDetailResult
}

export default function RideOverviewCosts(props: Props) {
  const { ride } = props

  const [isOverageDrawerOpen, setIsOverageDrawerOpen] = useState(false)

  const closeOverageDrawer = () => setIsOverageDrawerOpen(false)

  const openOverageDrawer = () => setIsOverageDrawerOpen(true)

  const { reservation, overages, totalOverages, totalWithOverages } = ride
  const { depositAmount, depositDueDate, finalAmount, finalDueDate, totalAmount } = reservation

  return (
    <>
      <AddOverageCharge ride={ride} open={isOverageDrawerOpen} onComplete={closeOverageDrawer} />
      <Block as="div" className="mb-6 p-6">
        <div className="mb-6 flex flex-row justify-between border-b-2 border-darkGrey/10 pb-3">
          <Headline level={3} className="inline-flex items-center gap-x-1.5">
            <CurrencyDollarIcon className="-ml-0.5 h-5 w-5 text-formIconsOnLight" aria-hidden="true" /> Ride Costs
          </Headline>
          <RoundedButton
            type="button"
            className="inline-flex w-16 items-center gap-x-1.5 lg:w-36"
            onClick={openOverageDrawer}
          >
            <PlusCircleIcon className="text-light-100 -ml-0.5 h-5 w-5" aria-hidden="true" />
            <span className="sr-only lg:not-sr-only">Add Overage</span>
          </RoundedButton>
        </div>
        <div className="divide-y px-6">
          {!!depositAmount && depositAmount > 0 && (
            <TableRow
              label={
                <>
                  Deposit Amount <br />
                  <DisplayDate date={depositDueDate} className="text-xs text-errorOnLight">
                    {formattedDate => <>due {formattedDate}</>}
                  </DisplayDate>
                </>
              }
            >
              <DisplayMoney amount={depositAmount} />
            </TableRow>
          )}
          <TableRow
            label={
              <>
                Final Amount <br />
                <DisplayDate date={finalDueDate} className="text-xs text-errorOnLight">
                  {formattedDate => <>due {formattedDate}</>}
                </DisplayDate>
              </>
            }
          >
            <DisplayMoney amount={finalAmount} />
          </TableRow>
          <TableRow label={<span className="font-bold">Ride Total</span>}>
            <DisplayMoney className="font-bold" amount={totalAmount} />
          </TableRow>
          {overages.map(overage => (
            <TableRow
              key={overage.uuid}
              label={
                <span className="ml-2 text-errorOnLight">
                  <PlusIcon className="inline-block h-4 w-4" /> Overage
                </span>
              }
            >
              <DisplayMoney amount={overage.amount} />
              &nbsp;<span className="text-mediumGrey">({overage.description})</span>
            </TableRow>
          ))}
          {totalOverages > 0 && (
            <>
              {overages.length > 1 && (
                <TableRow label="Overage Total">
                  <DisplayMoney className="font-bold" amount={totalOverages} />
                </TableRow>
              )}
              <TableRow className="font-bold text-errorOnLight" label="Total w/ Overages">
                <DisplayMoney amount={totalWithOverages} />
              </TableRow>
            </>
          )}
        </div>
      </Block>
    </>
  )
}
