import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, RequestBody, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/quotes/{quoteUuid}/itinerary'
const method = 'post'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type QuoteItineraryCreateInput = PathParams<ApiOperation> & RequestBody<ApiOperation>

export function quoteItineraryCreate(input: QuoteItineraryCreateInput) {
  const { quoteUuid, ...body } = input
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, { quoteUuid }),
    method,
    data: body,
  })
}

export type QuoteItineraryCreateResult = ResultType<typeof quoteItineraryCreate>
