import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { ArrowLeftCircleIcon } from '@heroicons/react/20/solid'

import RoundedButton from '../components/button-rounded'
import CustomerUpdateForm from '../components/customer-update-form'
import RideRequestsList from '../components/customers/ride-request-list'
import LoadingTable from '../components/loading-table'
import NavigationLink from '../components/navigation-link'
import DisplayUser from '../components/shared/display-user'
import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'
import type { CustomerRideRequestResponse } from '../services/customerRideRequests'
import { customerRideRequests } from '../services/customerRideRequests'
import { quoteCreateForCustomer } from '../services/quoteCreateForCustomer'
import { quoteCreateFromRideRequest } from '../services/quoteCreateFromRideRequest'
import createErrorToast from '../utils/createErrorToast'

interface Props {
  customerUuid: string
}

function CustomerDetail(props: Props) {
  const { customerUuid } = props

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const [editMode, setEditMode] = useState<boolean>(false)

  const [customerRideRequestsResponse, setCustomerRideRequestsResponse] = useState<CustomerRideRequestResponse>()
  const [searching, setSearching] = useState<boolean>(false)

  const switchToEditMode = useCallback(() => {
    setEditMode(true)
  }, [])

  const updateComplete = useCallback((data?: CustomerRideRequestResponse) => {
    if (data) {
      setCustomerRideRequestsResponse(data)
    }
    setEditMode(false)
  }, [])

  const { customer, paymentProfileLink, requests } = customerRideRequestsResponse || {}

  useEffect(() => {
    dispatch({ type: AppStateActionType.SetNavTitle, payload: 'Customer Detail' })
  }, [dispatch])

  useEffect(() => {
    dispatch({
      type: AppStateActionType.SetBackPanel,
      payload: (
        <NavigationLink to="/customers" className="inline-flex items-center gap-x-1.5" variant="accent">
          <ArrowLeftCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> Back to All Customers
        </NavigationLink>
      ),
    })
  }, [dispatch])

  useEffect(() => {
    const abortController = new AbortController()

    setSearching(true)
    setCustomerRideRequestsResponse(undefined)

    if (customerUuid) {
      customerRideRequests({ customerUuid }, abortController.signal)
        .then(setCustomerRideRequestsResponse)
        .catch(err => {
          createErrorToast(err, addToast)
        })
        .finally(() => {
          setSearching(false)
        })
    }
    return () => {
      abortController.abort()
    }
  }, [addToast, customerUuid])

  const createQuoteClick = useCallback(
    (rideRequestUuid: string) => {
      if (!customerUuid) {
        return
      }
      quoteCreateFromRideRequest({ customerUuid, rideRequestUuid }).then(quote => {
        navigate(`/quotes/${quote.quoteUuid}`)
      })
    },
    [customerUuid, navigate],
  )

  const createEmptyQuoteClick = useCallback(() => {
    if (!customerUuid) {
      return
    }
    quoteCreateForCustomer({ customerUuid })
      .then(quote => {
        navigate(`/quotes/${quote.quoteUuid}`)
      })
      .catch(err => {
        createErrorToast(err, addToast)
      })
  }, [addToast, customerUuid, navigate])

  useEffect(() => {
    dispatch({
      type: AppStateActionType.SetActionPanel,
      payload: (
        <>
          {!editMode && (
            <RoundedButton onClick={switchToEditMode} variant="secondary" className="mr-4">
              Edit Customer
            </RoundedButton>
          )}
          <RoundedButton onClick={createEmptyQuoteClick} variant="accent">
            Create New Quote
          </RoundedButton>
        </>
      ),
    })
  }, [dispatch, createEmptyQuoteClick, switchToEditMode, editMode])

  if (editMode && customer) {
    return <CustomerUpdateForm onComplete={updateComplete} customer={customer} />
  }

  return (
    <div>
      {searching ? (
        <LoadingTable />
      ) : (
        <>
          <DisplayUser user={customer} paymentProfile={paymentProfileLink?.href} />
          <RideRequestsList
            rideRequests={requests || []}
            createQuoteClick={createQuoteClick}
            createEmptyQuoteClick={createEmptyQuoteClick}
          />
        </>
      )}
    </div>
  )
}

export default function CustomerDetailPage() {
  const { customerUuid } = useParams<{ customerUuid: string }>()

  const { addToast } = useToasts()
  const navigate = useNavigate()

  if (!customerUuid || customerUuid === '00000000-0000-0000-0000-000000000000') {
    addToast('Customer not found', { appearance: 'error', autoDismiss: true })
    navigate('/customers')
    return null
  }

  return <CustomerDetail customerUuid={customerUuid} />
}
