import { twMerge } from 'tailwind-merge'

type ImgProps = JSX.IntrinsicElements['img']

interface Props extends ImgProps {
  imageUrl: string
  alt?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export default function AvatarImage(props: Props) {
  const { alt = '', className, imageUrl, size } = props

  return (
    <img
      className={twMerge(
        'rounded-full',
        size === 'xs' && 'h-6 w-6',
        size === 'sm' && 'h-8 w-8',
        size === 'md' && 'h-10 w-10',
        size === 'lg' && 'h-12 w-12',
        size === 'xl' && 'h-14 w-14',
        className,
      )}
      src={imageUrl}
      alt={alt}
    />
  )
}
