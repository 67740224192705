import { memo } from 'react'

import type { SvgComponentProps } from './SvgComponentProps'

function SvgComponent(props: SvgComponentProps) {
  const { width, height, desc, descId, title, titleId, ...extraProps } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...extraProps}
    >
      {desc ? <desc id={descId}>{desc}</desc> : null}
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#a)">
        <path d="m21.933 7.726-.562-1.464-.996-2.595C19.518 1.439 17.479 0 15.175 0h-6.35c-2.303 0-4.344 1.44-5.2 3.667L2.63 6.262l-.562 1.464C.861 8.203 0 9.409 0 10.833v2.5c0 .84.308 1.598.8 2.184v2.816c0 .92.716 1.667 1.6 1.667H4c.883 0 1.6-.746 1.6-1.667v-1.666h12.8v1.666c0 .92.716 1.667 1.6 1.667h1.6c.883 0 1.6-.746 1.6-1.667v-2.816c.492-.586.8-1.344.8-2.184v-2.5c0-1.424-.861-2.63-2.067-3.107ZM6.596 4.905c.365-.95 1.247-1.572 2.229-1.572h6.35c.982 0 1.864.623 2.229 1.572L18.4 7.5H5.6l.997-2.595ZM4 13.323c-.96 0-1.6-.665-1.6-1.662C2.4 10.665 3.04 10 4 10c.96 0 2.4 1.495 2.4 2.492 0 .997-1.44.83-2.4.83Zm16 0c-.96 0-2.4.166-2.4-.83C17.6 11.494 19.04 10 20 10s1.6.665 1.6 1.662c0 .996-.64 1.66-1.6 1.66Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgComponent)
export default Memo
