import { NavLink } from 'react-router-dom'

import { Disclosure } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

import { useAppContext } from '../context/app-state'
import type NavLinkStateCallback from '../types/nav-link-state-callback'

import MobileUserMenu from './mobile-menu-user'
import navigation from './navigation-routes'

const linkStyle: NavLinkStateCallback = ({ isActive }) =>
  twMerge(
    isActive ? 'bg-primary-700 text-white' : 'text-white hover:bg-primary-500 hover:bg-opacity-75',
    'block rounded-md py-2 px-3 text-base font-medium',
  )

export default function MobileMenu() {
  const { user } = useAppContext()

  return (
    <Disclosure.Panel className="lg:hidden">
      <div className="space-y-1 px-2 pb-3 pt-2">
        {navigation.map(item => (
          <Disclosure.Button key={item.name} as={NavLink} to={item.href} className={linkStyle}>
            {item.name}
          </Disclosure.Button>
        ))}
      </div>
      {user && <MobileUserMenu user={user} />}
    </Disclosure.Panel>
  )
}
