import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import { ArrowLeftIcon } from '@heroicons/react/20/solid'

import type { CustomerInfo, CustomerRideRequestResponse } from '../services/customerRideRequests'
import { customerUpdate, type CustomerUpdateInput } from '../services/customerUpdate'
import getErrorMessage from '../utils/getErrorMessage'

import RoundedButton from './button-rounded'
import Headline from './headline'
import LoadingContent from './loading-content'
import TextInput from './text-input'

interface Props {
  customer: CustomerInfo
  onComplete: (data?: CustomerRideRequestResponse) => void
}

export default function CustomerUpdateForm(props: Props) {
  const { customer, onComplete } = props

  const customerUuid = customer.uuid

  const toasts = useToasts()
  const [busy, setBusy] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomerUpdateInput>({
    defaultValues: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
    },
  })

  const onGoBackClick = () => {
    onComplete()
  }

  const onSubmit = (data: CustomerUpdateInput) => {
    setBusy(true)
    customerUpdate({
      ...data,
      customerUuid,
    })
      .then(result => {
        toasts.addToast('Customer updated', { appearance: 'success', autoDismiss: true })
        onComplete(result)
      })
      .catch(err => {
        toasts.addToast(getErrorMessage(err), { appearance: 'error', autoDismiss: true, autoDismissTimeout: 10_000 })
      })
      .finally(() => setBusy(false))
  }

  if (busy) {
    return <LoadingContent />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-4 md:p-0">
        <Headline level={3} className="mb-6 text-mediumGrey">
          Edit Customer
        </Headline>
        <div className="space-y-6">
          <TextInput
            {...register('firstName', { required: { value: true, message: 'First Name is required' } })}
            labelText="First Name"
            autoComplete="none"
            size="lg"
            validationMessage={errors?.firstName}
          />
          <TextInput
            {...register('lastName', { required: { value: true, message: 'Last Name is required' } })}
            labelText="Last Name"
            autoComplete="none"
            size="lg"
            validationMessage={errors?.firstName}
          />
          <TextInput
            {...register('email', {
              required: { value: true, message: 'Email is required' },
              pattern: { value: /.+@.+\..+/, message: 'Enter not valid' },
            })}
            labelText="Email"
            type="email"
            autoComplete="none"
            placeholder="Enter your email address"
            size="lg"
            validationMessage={errors?.email}
          />
        </div>

        <div className="flex justify-around py-4 md:justify-start">
          <RoundedButton onClick={onGoBackClick} size="xl" className="mr-4 px-12" variant="secondary">
            <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" /> Back
          </RoundedButton>
          <RoundedButton type="submit" size="xl">
            Update Customer
          </RoundedButton>
        </div>
      </div>
    </form>
  )
}
