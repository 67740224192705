import type { AxiosRequestConfig } from 'axios'

import type { Response200 } from '../types/schema-utils'

import ApiClient from './api-client'

export default async function sendRequest<T>(options: AxiosRequestConfig) {
  const apiClient = new ApiClient()
  const { data } = await apiClient.instance.request<Response200<T>>(options)
  return data
}
