import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import CustomerSearchEmptyResults from '../components/customers/customer-search-empty-results'
import CustomerSearchHit from '../components/customers/customer-search-hit'
import LoadingTable from '../components/loading-table'
import SearchForm from '../components/shared/search-form'
import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'
import type { CustomerSearchInput, CustomerSearchResults } from '../services/customerSearch'
import customerSearch from '../services/customerSearch'
import createErrorToast from '../utils/createErrorToast'

interface FormData {
  searchText: string
}

export default function CustomersPage() {
  const dispatch = useAppDispatch()
  const { addToast } = useToasts()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const [searchResults, setSearchResults] = useState<CustomerSearchResults>()
  const [searchFilter, setSearchFilter] = useState<CustomerSearchInput>()
  const [searching, setSearching] = useState(false)

  useEffect(() => {
    dispatch({ type: AppStateActionType.SetNavTitle, payload: 'Customers' })
  }, [dispatch])

  useEffect(() => {
    const abortController = new AbortController()
    if (searchFilter !== undefined) {
      setSearching(true)
      setSearchResults(undefined)

      customerSearch(searchFilter, abortController.signal)
        .then(setSearchResults)
        .catch(err => {
          createErrorToast(err, addToast)
        })
        .finally(() => setSearching(false))
    }
    return () => {
      abortController.abort()
    }
  }, [addToast, searchFilter])

  const onSearchFormSubmit = (formData: CustomerSearchInput) => {
    setSearchFilter({
      searchText: formData.searchText,
    })
  }

  useEffect(() => {
    setSearchFilter({ searchText: '', perPage: 40, orderBy: 'updatedAt', sortDirection: 'desc' })
  }, [])

  const hits = searchResults?.results ?? []

  return (
    <>
      <SearchForm
        handleSubmit={handleSubmit}
        onSearchFormSubmit={onSearchFormSubmit}
        register={register}
        errors={errors}
        placeholder="Search by name, email, or phone"
      />
      <div>
        {searching && <LoadingTable />}
        {!searching && hits.length === 0 && <CustomerSearchEmptyResults />}
        {!searching && hits.length > 0 && (
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {hits.map(customer => (
              <CustomerSearchHit key={customer.uuid} customer={customer} />
            ))}
          </ul>
        )}
      </div>
    </>
  )
}
