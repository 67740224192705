import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { ArrowLeftIcon } from '@heroicons/react/20/solid'

import type { UserCreateInput } from '../services/userCreate'
import { userCreate } from '../services/userCreate'
import getErrorMessage from '../utils/getErrorMessage'

import RoundedButton from './button-rounded'
import LoadingContent from './loading-content'
import TextInput from './text-input'

export default function UserCreateForm() {
  const toasts = useToasts()
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserCreateInput>()

  const onSubmit = (data: UserCreateInput) => {
    setBusy(true)
    userCreate(data)
      .then(() => {
        toasts.addToast('User created', { appearance: 'success', autoDismiss: true })
        navigate('/users')
      })
      .catch(err => {
        toasts.addToast(getErrorMessage(err), { appearance: 'error', autoDismiss: true, autoDismissTimeout: 10_000 })
      })
      .finally(() => setBusy(false))
  }

  const onGoBackClick = () => {
    navigate('/users')
  }

  if (busy) {
    return <LoadingContent />
  }

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        {...register('firstName', { required: { value: true, message: 'First Name is required' } })}
        labelText="First Name"
        autoComplete="none"
        size="lg"
        validationMessage={errors?.firstName}
      />
      <TextInput
        {...register('lastName', { required: { value: true, message: 'Last Name is required' } })}
        labelText="Last Name"
        autoComplete="none"
        size="lg"
        validationMessage={errors?.firstName}
      />
      <TextInput
        {...register('email', {
          required: { value: true, message: 'Email is required' },
          pattern: { value: /.+@.+\..+/, message: 'Enter not valid' },
        })}
        labelText="Email"
        type="email"
        autoComplete="none"
        placeholder="first.last@catchtrasportation.com"
        size="lg"
        validationMessage={errors?.email}
      />

      <div className="flex justify-around py-4 md:justify-start">
        <RoundedButton onClick={onGoBackClick} size="xl" className="mr-4 px-12" variant="secondary">
          <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" /> Back
        </RoundedButton>
        <RoundedButton type="submit" size="xl">
          Create User
        </RoundedButton>
      </div>
    </form>
  )
}
