import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'

import { type CognitoUser } from 'amazon-cognito-identity-js'

import RoundedButton from '../components/button-rounded'
import LoadingContent from '../components/loading-content'
import { LoginFormActionType, useLoginFormDispatch } from '../components/login/login-form-context'
import TextInput from '../components/text-input'
import authenticateCognitoUser from '../services/cognito/authenticateCognitoUser'
import completeCognitoNewPasswordChallenge from '../services/cognito/completeCognitoNewPasswordChallenge'
import createCognitoUser from '../services/cognito/createCognitoUser'
import getErrorMessage from '../utils/getErrorMessage'

export default function PasswordChangePage() {
  const { state: locationState } = useLocation()
  const navigate = useNavigate()
  const loginFormDispatch = useLoginFormDispatch()
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)

  const passwordRef = useRef<HTMLInputElement>(null)

  const { username, password, returnUrl } = locationState

  useEffect(() => {
    createCognitoUser(username).then(setCognitoUser)
  }, [username])

  useEffect(() => {
    loginFormDispatch({ type: LoginFormActionType.SetLoginState, payload: { formTitle: 'Change Password' } })
  }, [loginFormDispatch])

  if (!username) {
    navigate('/login')
    return null
  }

  if (!cognitoUser) {
    return <LoadingContent />
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const newPassword = passwordRef.current?.value

    if (!newPassword) {
      return
    }

    authenticateCognitoUser(username, password).then(({ type, user }) => {
      switch (type) {
        case 'success': {
          navigate(returnUrl)
          break
        }
        case 'newPasswordRequired': {
          completeCognitoNewPasswordChallenge(user, newPassword)
            .then(({ type: resultType }) => {
              switch (resultType) {
                case 'success': {
                  const q = new URLSearchParams({ email: username, returnUrl })
                  navigate(`/login?${q.toString()}`, {
                    state: { successMessage: 'Your password has been updated. Please log in.' },
                  })
                  break
                }
                default: {
                  loginFormDispatch({
                    type: LoginFormActionType.SetLoginState,
                    payload: { errorMessage: `Unexpected result type ${resultType}` },
                  })
                  break
                }
              }
            })
            .catch(err => {
              loginFormDispatch({
                type: LoginFormActionType.SetLoginState,
                payload: {
                  errorMessage: getErrorMessage(err),
                },
              })
            })
          break
        }
        default: {
          loginFormDispatch({
            type: LoginFormActionType.SetLoginState,
            payload: { errorMessage: `Unexpected result type ${type}` },
          })
          break
        }
      }
    })
  }

  return (
    <>
      <Helmet title="Change Password" />
      <form className="space-y-6" onSubmit={onSubmit}>
        <TextInput
          ref={passwordRef}
          labelText="New Password"
          id="password"
          name="password"
          type="password"
          autoComplete="new-password"
          required
        />
        <div>
          <RoundedButton type="submit" size="xl" className="w-full" variant="accent">
            Change Password
          </RoundedButton>
        </div>
      </form>
    </>
  )
}
