import type { ReactNode } from 'react'

import { twMerge } from 'tailwind-merge'

import NavigationLink from './navigation-link'

type NavigationLinkProps = React.ComponentProps<typeof NavigationLink>

interface Props extends NavigationLinkProps {
  children: ReactNode
}

export default function CardActionLink(props: Props) {
  const { children, className, ...otherProps } = props
  return (
    <NavigationLink
      {...otherProps}
      className={twMerge(
        'relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 border border-transparent bg-buttonTertiary py-4 text-sm font-semibold hover:bg-buttonTertiaryHover',
        className,
      )}
    >
      {children}
    </NavigationLink>
  )
}
