import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import signOutCognitoUser from '../services/cognito/signOutCognitoUser'

export default function LogoutPage() {
  const [didSignOut, setDidSignOut] = useState(false)

  useEffect(() => {
    signOutCognitoUser().then(() => setDidSignOut(true))
  }, [])

  return didSignOut ? <Navigate to="/login" /> : null
}
