import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, QueryParams, ResultItemType, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'

const url = '/api/v1/reservations'
const method = 'get'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type RideSearchInput = QueryParams<ApiOperation>

export function rideSearch(input: RideSearchInput, abortSignal?: AbortSignal) {
  return sendRequest<ApiOperation>({
    url,
    method,
    params: input,
    signal: abortSignal,
  })
}

export type RideSearchResults = ResultType<typeof rideSearch>

export type RideSearchResultItem = ResultItemType<RideSearchResults>
