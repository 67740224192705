import { DateTime } from 'luxon'

import type { QuoteSearchItem } from '../../services/quoteSearch'
import getDateTimeValue from '../../utils/getDateTimeValue'
import DisplayDate from '../display-date'

interface Props {
  quote: QuoteSearchItem
}

export default function QuoteExpiresIn(props: Props) {
  const { quote } = props

  const { expiresAt, quoteStatus } = quote

  if (quoteStatus === 'expired') {
    return <span className="text-errorOnLight">Expired</span>
  }

  if (quoteStatus !== 'booked' && quoteStatus !== 'cancelled') {
    return (
      <DisplayDate date={expiresAt} relative className="text-errorOnLight">
        {date => <>expires {date}</>}
      </DisplayDate>
    )
  }

  const dateTimeValue = getDateTimeValue(expiresAt)

  return dateTimeValue && dateTimeValue < DateTime.now() ? <span className="text-errorOnLight">Expired</span> : null
}
