// eslint-disable-next-line import/no-unresolved, simple-import-sort/imports
import { createRoot } from 'react-dom/client'

import { StrictMode } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import ErrorPage from './routes/error-page'
import App from './app'
import reportWebVitals from './reportWebVitals.js'
import Routes from './routes'
import * as serviceWorkerRegistration from './serviceWorkerRegistration.js'

import './styles.min.css'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root') as HTMLElement
const root = createRoot(rootElement)

const router = createBrowserRouter(
  [
    {
      element: <App />,
      errorElement: <ErrorPage />,
      children: Routes,
    },
  ],
  {
    basename: baseUrl ?? undefined,
  },
)

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
