import EmptyContainer from '../empty-container'
import Headline from '../headline'

export default function CustomerSearchEmptyResults() {
  return (
    <EmptyContainer>
      <Headline level={4} className="my-4">
        No customers found.
      </Headline>
    </EmptyContainer>
  )
}
