import { createContext, useContext } from 'react'
import type { Dispatch } from 'react'

import type { AppState } from './app-state'

export enum AppStateActionType {
  SetNavTitle = 'SET_NAV_TITLE',
  SetUser = 'SET_USER',
  SetTheme = 'SET_THEME',
  SetActionPanel = 'SET_ACTION_PANEL',
  SetBackPanel = 'SET_BACK_PANEL',
  ResetHeaderUx = 'RESET_HEADER_UX',
  SetConfig = 'SET_CONFIG',
}

export type AppStateAction =
  | {
      type: AppStateActionType.SetNavTitle
      payload: string
    }
  | {
      type: AppStateActionType.SetUser
      payload: AppState['user']
    }
  | {
      type: AppStateActionType.SetTheme
      payload: AppState['colorTheme']
    }
  | {
      type: AppStateActionType.SetActionPanel
      payload: AppState['actionPanel']
    }
  | {
      type: AppStateActionType.SetBackPanel
      payload: AppState['backPanel']
    }
  | {
      type: AppStateActionType.ResetHeaderUx
    }
  | {
      type: AppStateActionType.SetConfig
      payload: AppState['config']
    }

export const appStateReducer = (state: AppState, action: AppStateAction): Readonly<AppState> => {
  switch (action.type) {
    case AppStateActionType.SetNavTitle:
      return {
        ...state,
        navTitle: action.payload,
      }
    case AppStateActionType.SetUser:
      return {
        ...state,
        user: action.payload,
      }
    case AppStateActionType.SetActionPanel:
      return {
        ...state,
        actionPanel: action.payload,
      }
    case AppStateActionType.SetBackPanel:
      return {
        ...state,
        backPanel: action.payload,
      }
    case AppStateActionType.SetTheme:
      if (action.payload) {
        localStorage.setItem('colorTheme', action.payload)
      } else {
        localStorage.removeItem('colorTheme')
      }
      return {
        ...state,
        colorTheme: action.payload,
      }
    case AppStateActionType.ResetHeaderUx:
      return {
        ...state,
        actionPanel: undefined,
        backPanel: undefined,
      }
    case AppStateActionType.SetConfig:
      return {
        ...state,
        config: action.payload,
      }
    default:
      return state
  }
}

export const AppDispatchContext = createContext<Dispatch<AppStateAction>>(undefined as never)

export const useAppDispatch = () => useContext(AppDispatchContext)
