import type { CustomerRideRequestItem } from '../../services/customerRideRequests'
import CarIcon from '../../svg/car-icon'
import Block from '../block'
import EmptyContainer from '../empty-container'
import Headline from '../headline'
import NavigationLink from '../navigation-link'

import CustomerRequestRow from './customer-request-row'

interface Props {
  rideRequests: CustomerRideRequestItem[]
  createQuoteClick: (rideRequestUuid: string) => void
  createEmptyQuoteClick: () => void
}

export default function RideRequestsList(props: Props) {
  const { rideRequests, createQuoteClick, createEmptyQuoteClick } = props

  const orCreateQuoteClick: JSX.IntrinsicElements['a']['onClick'] = e => {
    e.preventDefault()
    createEmptyQuoteClick()
  }

  if (rideRequests.length === 0) {
    return (
      <EmptyContainer>
        <Headline level={4} className="mb-4">
          No ride requests found for this customer with the last 30 days.
        </Headline>
        <NavigationLink as="button" to="#" onClick={orCreateQuoteClick} className="inline-flex">
          <CarIcon className="mx-2 h-6 w-6" />
          Create Quote
        </NavigationLink>
      </EmptyContainer>
    )
  }

  return (
    <>
      <Headline level={3} className="mt-4 border-t border-formBorderOnLight py-4">
        Recent Requests
      </Headline>
      <Block as="div" className="p-6">
        <table className="min-w-full divide-y divide-formBorderOnLight">
          <thead>
            <tr className="text-light-200 bg-primary text-white dark:bg-darkGrey">
              <th scope="col" className="p-3 text-left text-sm font-semibold">
                Group Name
              </th>
              <th scope="col" className="hidden p-3 text-left text-sm font-semibold lg:table-cell">
                Ride Date(s)
              </th>
              <th scope="col" className="hidden p-3 text-left text-sm font-semibold sm:table-cell">
                Riders
              </th>
              <th scope="col" className="hidden p-3 text-left text-sm font-semibold sm:table-cell">
                Vehicle
              </th>
              <th scope="col" className="p-3 text-left text-sm font-semibold">
                Service
              </th>
              <th scope="col" className="p-3 text-left text-sm font-semibold">
                Created At
              </th>
              <th scope="col" className="relative p-3">
                <span className="sr-only">Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-formBorderOnLight">
            {rideRequests.map(item => (
              <CustomerRequestRow key={item.requestUuid} item={item} createQuoteClick={createQuoteClick} />
            ))}
          </tbody>
        </table>
      </Block>
      <div className="mt-4 flex">
        <NavigationLink to="/" onClick={orCreateQuoteClick}>
          Or start a new quote
          <span aria-hidden="true"> &rarr;</span>
        </NavigationLink>
      </div>
    </>
  )
}
