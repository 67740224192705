import { createContext, useContext } from 'react'
import type { Dispatch } from 'react'

export interface LoginFormState {
  formTitle: string
  errorMessage?: string
  successMessage?: string
}

export const defaultLoginFormState: LoginFormState = {
  formTitle: 'Sign in to your account',
}

export enum LoginFormActionType {
  SetLoginState = 'SetLoginState',
}

export type LoginFormAction = {
  type: LoginFormActionType.SetLoginState
  payload: Partial<LoginFormState>
}

export const loginFormReducer = (state: LoginFormState, action: LoginFormAction) => {
  switch (action.type) {
    case LoginFormActionType.SetLoginState:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export const LoginFormStateContext = createContext<LoginFormState>(defaultLoginFormState)

export const LoginFormDispatchContext = createContext<Dispatch<LoginFormAction>>(undefined as never)

export const useLoginFormDispatch = () => useContext(LoginFormDispatchContext)

export const useLoginContext = () => useContext(LoginFormStateContext)
