import type { CognitoUserSession } from 'amazon-cognito-identity-js'

import getCurrentCognitoUser from './getCurrentCognitoUser'

export default async function getCurrentCognitoSession() {
  const user = await getCurrentCognitoUser()

  return new Promise<CognitoUserSession | null>((resolve, reject) => {
    if (user) {
      user.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err) {
          reject(err)
        }
        if (session) {
          resolve(session)
        } else {
          resolve(null)
        }
      })
    } else {
      resolve(null)
    }
  })
}
