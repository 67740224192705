import type { RouteObject } from 'react-router-dom'

import CustomerLayout from '../layout/customer-layout'
import LoginLayout from '../layout/login-layout'

import ColorSwatches from './color-swatches'
import CustomerDetailPage from './customer-detail'
import LinkToAppPage from './customer-link-to-app-page'
import CustomersPage from './customers-page'
import LogoutPage from './logout-page'
import PasswordChangePage from './password-change-page'
import PasswordConfirmForgotPage from './password-confirm-page'
import PasswordForgotPage from './password-forgot-page'
import PasswordLoginPage from './password-login-page'
import ProtectedRoute from './protected-route'
import QuoteEdit from './quote-edit'
import QuotesPage from './quotes-page'
import RideDetail from './ride-detail'
import RidesPage from './rides-page'
import UserCreatePage from './user-create-page'
import UsersPage from './users-page'

const AppRoutes: RouteObject[] = [
  {
    path: '/logout',
    element: <LogoutPage />,
  },
  {
    path: '/q',
    element: <CustomerLayout />,
    children: [
      {
        path: ':catchId',
        element: <LinkToAppPage />,
      },
    ],
  },
  {
    path: '/r',
    element: <CustomerLayout />,
    children: [
      {
        path: ':catchId',
        element: <LinkToAppPage />,
      },
    ],
  },
  {
    path: '/catch-a-ride',
    element: <CustomerLayout />,
    children: [
      {
        path: '',
        element: <LinkToAppPage />,
      },
    ],
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: '/login',
        element: <PasswordLoginPage />,
      },
      {
        path: '/change-password',
        element: <PasswordChangePage />,
      },
      {
        path: '/forgot-password',
        element: <PasswordForgotPage />,
      },
      {
        path: '/confirm-forgot-password',
        element: <PasswordConfirmForgotPage />,
      },
    ],
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        index: true,
        element: <QuotesPage />,
      },
      {
        path: '/quotes/:id',
        element: <QuoteEdit />,
      },
      {
        path: '/rides',
        element: <RidesPage />,
      },
      {
        path: '/rides/:rideUuid',
        element: <RideDetail />,
      },
      {
        path: '/customers',
        element: <CustomersPage />,
      },
      {
        path: '/customers/:customerUuid',
        element: <CustomerDetailPage />,
      },
      {
        path: '/users',
        element: <UsersPage />,
      },
      {
        path: '/users/create',
        element: <UserCreatePage />,
      },
      {
        path: '/colors',
        element: <ColorSwatches />,
      },
    ],
  },
]

export default AppRoutes
