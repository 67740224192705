import { Fragment, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

import { RideStateActionType, useRideDispatch } from '../../context/ride-state-reducer'
import { rideCancel } from '../../services/rideCancel'
import type { RideDetailResult } from '../../services/rideDetailGet'
import createErrorToast from '../../utils/createErrorToast'
import createSuccessToast from '../../utils/createSuccessToast'
import RoundedButton from '../button-rounded'
import FormBlocker from '../form-blocker'
import Headline from '../headline'
import TextInput from '../text-input'

interface Props {
  ride: RideDetailResult
  open: boolean
  onComplete: () => void
}

interface FormData {
  reason: string
}

export default function CancelRideModal(props: Props) {
  const { open, onComplete, ride } = props

  const rideDispatch = useRideDispatch()
  const { addToast } = useToasts()
  const cancelButtonRef = useRef(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      reason: '',
    },
  })

  const [submitting, setSubmitting] = useState(false)

  const rideName = ride.reservation.eventName
  const rideUuid = ride.uuid

  const onSubmit = (data: FormData) => {
    const { reason } = data

    setSubmitting(true)

    rideCancel({ rideUuid, reason })
      .then(cancelledRide => {
        rideDispatch({ type: RideStateActionType.SetRide, payload: cancelledRide })
        createSuccessToast(`Ride ${rideName} has been canceled`, addToast)
        onComplete()
      })
      .catch(err => createErrorToast(err, addToast))
      .finally(() => setSubmitting(false))
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onComplete}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <FormBlocker active={submitting}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-errorOnLight">
                        <ExclamationTriangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Are you sure you want to cancel this ride?
                        </Dialog.Title>
                        <div className="mt-2 rounded-sm border border-errorOnDark p-6 text-left font-sans text-lg text-paragraphText">
                          <Headline level={4} className="mb-3 text-errorOnLight">
                            NOTE:
                          </Headline>
                          <p className="my-3">
                            Cancelling the ride will cancel any scheduled payments and send a notification to the{' '}
                            <strong>Event Organizer</strong> that their ride has been cancelled.
                          </p>
                          <p className="my-3">
                            Any payment refunds or adjustments must be manually handled by the Catch Admin team
                          </p>
                          <div>
                            <TextInput
                              {...register('reason', { required: false })}
                              labelText={<span className="font-bold text-errorOnLight">Reason for cancellation</span>}
                              placeholder="(optional)"
                              multiline
                              rows={3}
                              validationMessage={errors.reason?.message}
                              helpText="NOTE: This will be sent to the Event Organizer"
                              helpTextProps={{ className: 'text-errorOnLight' }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 grid grid-flow-row-dense grid-cols-2 gap-3">
                      <RoundedButton
                        type="button"
                        variant="secondary"
                        size="lg"
                        onClick={onComplete}
                        ref={cancelButtonRef}
                      >
                        Close
                      </RoundedButton>
                      <RoundedButton type="submit" variant="negative" size="lg">
                        Cancel Ride
                      </RoundedButton>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </form>
        </FormBlocker>
      </Dialog>
    </Transition.Root>
  )
}
