import { memo } from 'react'

import type { SvgComponentProps } from './SvgComponentProps'

function SvgComponent(props: SvgComponentProps) {
  const { width, height, desc, descId, title, titleId, ...extraProps } = props

  return (
    <svg
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      {...extraProps}
    >
      {desc ? <desc id={descId}>{desc}</desc> : null}
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M6.7364 3.55674C7.55266 3.57217 8.55086 3.87619 8.89878 4.52933C9.0801 4.86362 9.48824 4.98535 9.82137 4.80306L10.6229 4.37732C10.9554 4.19504 11.0765 3.78475 10.8952 3.44985C10.3358 2.38581 9.27743 1.67153 7.90116 1.42802V0.684026C7.90116 0.304011 7.59875 0 7.22074 0H6.32887C5.95086 0 5.64845 0.304011 5.64845 0.684026V1.42861C3.9545 1.71777 2.26115 2.69038 2.30651 4.71224C2.36676 7.26609 4.877 7.69186 6.54025 7.96486C8.18809 8.26887 9.01971 8.5426 9.00505 9.21118C9.00505 10.2752 7.28161 10.3667 6.73697 10.3667C5.78426 10.3667 4.52907 10.0627 4.12097 9.22663C4.04537 9.05918 3.89416 8.93806 3.7276 8.87689C3.56103 8.81633 3.36495 8.83117 3.19838 8.92261L2.36675 9.34835C2.03422 9.5158 1.91314 9.9261 2.06434 10.2604C2.66916 11.5067 3.98449 12.3582 5.63308 12.5862V13.316C5.63308 13.696 5.93549 14 6.31351 14H7.19062C7.56863 14 7.87104 13.696 7.87104 13.316V12.5714C9.67008 12.2828 11.3187 11.2033 11.3333 9.24277C11.3333 6.50666 8.65715 6.03514 6.87278 5.73113C4.9673 5.39684 4.58928 5.10766 4.58928 4.68253C4.58928 4.46997 4.64953 4.30252 4.78597 4.15051C5.14862 3.78476 5.90465 3.55675 6.73621 3.55675L6.7364 3.55674Z"
        fill="currentColor"
      />
    </svg>
  )
}
const Memo = memo(SvgComponent)
export default Memo
