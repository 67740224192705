import axios from 'axios'

export default function getErrorMessage(error: unknown): string | undefined {
  if (typeof error === 'string') {
    return error
  }
  if (axios.isCancel(error)) {
    return undefined
  }
  if (axios.isAxiosError(error)) {
    if (error.response) {
      return error.response.data.message
    }
    return error.message
  }
  if (error instanceof Error) {
    return error.message
  }
  if (error && typeof error === 'object') {
    if ('message' in error) {
      return error.message as string
    }
  }
  return 'There was a problem'
}
