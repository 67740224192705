import type { ReactNode } from 'react'
import type { FieldError } from 'react-hook-form'

interface Props {
  message: ReactNode | FieldError | undefined
}

export default function ValidationMessage({ message: error }: Props): JSX.Element | null {
  if (!error) {
    return null
  }

  if (typeof error === 'object' && 'message' in error) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{error.message}</>
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{error}</>
}
