import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import QRCodeStyling from 'qr-code-styling'

import Block from '../components/block'
import Headline from '../components/headline'
import type { LandingPageGetResult } from '../services/landingPageQuoteGet'
import { landingPageGet } from '../services/landingPageQuoteGet'

function getLink(data: LandingPageGetResult) {
  if (data.notFound) {
    return null
  }
  switch (data.platform) {
    case 'apple':
      return data.appleLink
    case 'android':
      return data.androidLink
    default:
      return null
  }
}

const qrCode = new QRCodeStyling({
  width: 200,
  image: 'android-chrome-192x192.png',
  dotsOptions: {
    gradient: {
      type: 'linear',
      rotation: 45,
      colorStops: [
        { offset: 0, color: '#252526' },
        { offset: 1, color: '#20acdf' },
      ],
    },
    type: 'extra-rounded',
  },
  qrOptions: {
    errorCorrectionLevel: 'Q',
  },
  cornersSquareOptions: {
    type: 'square',
    color: '#252526',
  },
  cornersDotOptions: {
    color: '#252526',
    type: 'square',
  },
  backgroundOptions: {
    color: 'transparent',
  },
})

export default function LinkToAppPage() {
  const { catchId = '0000' } = useParams()
  const [landingPageData, setLandingPageData] = useState<LandingPageGetResult>()
  const qrRef = useRef<HTMLDivElement>(null)
  const [didAppendQrCode, setDidAppendQrCode] = useState(false)

  useEffect(() => {
    if (catchId) {
      landingPageGet(catchId).then(setLandingPageData)
    }
  }, [catchId])

  const qrCoreUrl = landingPageData?.qrCodeUrl

  useEffect(() => {
    if (qrCoreUrl) {
      qrCode.update({
        data: qrCoreUrl,
      })
      if (qrRef.current && !didAppendQrCode) {
        qrCode.append(qrRef.current)
        setDidAppendQrCode(true)
      }
    }
  }, [didAppendQrCode, qrCoreUrl])

  if (!landingPageData) {
    return null
  }

  const link = getLink(landingPageData)

  if (window.location && link) {
    window.location.href = link
    return null
  }

  return (
    <>
      <Helmet title="Ready to Catch a Ride?" />
      <div className="grid grid-cols-1 md:grid-cols-2">
        <img className="mx-auto object-contain" src="/mobile-app.webp" alt="Mobile App" />
        <div className="flex flex-col justify-center text-center">
          <div className="mx-auto w-[80%] md:w-[50%] xl:w-[60%]">
            <Headline
              level={1}
              className="mt-0 text-4xl font-bold leading-relaxed tracking-wide text-mainHeadline sm:mt-12 md:text-5xl md:leading-[60px] xl:text-6xl xl:leading-[72px]"
            >
              Download
              <br />
              our app!
            </Headline>
            <Block as="div" className="mt-8 border-0 bg-transparent text-xl font-medium shadow-transparent">
              Download the <span className="font-bold">Catch Transportation</span> app to view your quote, manage rides
              and more!
            </Block>
          </div>
          <div className="mx-auto mt-6 flex w-[70%] flex-row items-center justify-center">
            <div ref={qrRef} />
            <div className="ml-4 grid min-w-[50%] grid-cols-1 gap-y-4">
              <a href={landingPageData.appStoreUrl} target="_blank" rel="noreferrer">
                <img src="/app-store.webp" alt="Download on the App Store" />
              </a>
              <a href={landingPageData.playStoreUrl} target="_blank" rel="noreferrer">
                <img src="/play-store.webp" alt="Get in on Google Play" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
