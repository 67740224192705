import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import LoadingTable from '../components/loading-table'
import RideSearchEmptyResults from '../components/rides/ride-search-empty-results'
import RideSearchHit from '../components/rides/ride-search-hit'
import SearchForm from '../components/shared/search-form'
import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'
import type { CustomerSearchInput } from '../services/customerSearch'
import type { RideSearchInput, RideSearchResults } from '../services/rideSearch'
import { rideSearch } from '../services/rideSearch'
import createErrorToast from '../utils/createErrorToast'
import getErrorMessage from '../utils/getErrorMessage'

interface FormData {
  searchText: string
}

const excludeRideStatus = ['cancelled', 'completed'] as RideSearchInput['excludeRideStatus']

export default function RidesPage() {
  const dispatch = useAppDispatch()
  const { addToast } = useToasts()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const [searchResults, setSearchResults] = useState<RideSearchResults>()
  const [searchFilter, setSearchFilter] = useState<RideSearchInput>()
  const [searching, setSearching] = useState(false)

  useEffect(() => {
    dispatch({ type: AppStateActionType.SetNavTitle, payload: 'Rides' })
  }, [dispatch])

  useEffect(() => {
    const abortController = new AbortController()
    if (searchFilter !== undefined) {
      setSearching(true)
      setSearchResults(undefined)

      rideSearch(searchFilter, abortController.signal)
        .then(setSearchResults)
        .catch(error => {
          createErrorToast(getErrorMessage(error), addToast)
        })
        .finally(() => setSearching(false))
    }
    return () => {
      abortController.abort()
    }
  }, [searchFilter, addToast])

  const onSearchFormSubmit = (formData: CustomerSearchInput) => {
    setSearchFilter(s => ({
      ...s,
      excludeRideStatus: formData.searchText ? [] : excludeRideStatus,
      searchText: formData.searchText,
    }))
  }

  useEffect(() => {
    setSearchFilter({
      orderBy: 'rideDate',
      sortDirection: 'asc',
      excludeRideStatus,
      searchText: '',
    })
  }, [])

  const hits = searchResults?.results ?? []

  return (
    <>
      <SearchForm
        handleSubmit={handleSubmit}
        onSearchFormSubmit={onSearchFormSubmit}
        register={register}
        errors={errors}
        placeholder="Search by group name or by event organizer name, email, or phone"
      />
      <div className="-mx-4 mt-8 sm:-mx-0" />
      {searching && <LoadingTable />}
      {!searching && hits.length === 0 && <RideSearchEmptyResults />}
      {!searching && hits.length > 0 && (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          {searchResults?.results.map(ride => (
            <RideSearchHit key={ride.uuid} ride={ride} />
          ))}
        </ul>
      )}
    </>
  )
}
