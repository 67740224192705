import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'

import getErrorMessage from '../utils/getErrorMessage'

import getAuthToken from './cognito/getAuthToken'
import AxiosDefaultConfig from './api-config'
import userPoolGet from './userPoolGet'

export default class ApiClient {
  public instance: AxiosInstance

  constructor() {
    const instance = axios.create({
      ...AxiosDefaultConfig,
    })

    instance.interceptors.request.use(
      async config => {
        const token = await getCurrentAuthToken()

        if (token) {
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${token}`,
            },
          } as InternalAxiosRequestConfig
        }

        return config
      },
      error => Promise.reject(error),
    )

    this.instance = instance
  }
}

async function getCurrentAuthToken() {
  try {
    const userPool = await userPoolGet()
    const user = userPool.getCurrentUser()
    return await getAuthToken(user)
  } catch (error) {
    if (getErrorMessage(error) === 'Refresh Token has been revoked') {
      return null
    }
    return Promise.reject(error)
  }
}
