import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'

import FormTitle from '../components/login/form-title'
import LoginFormContextProvider from '../components/login/login-context-provider'
import Messages from '../components/login/messages'
import CircleLogo from '../svg/circle-logo'

export default function LoginLayout() {
  return (
    <>
      <Helmet title="Login" titleTemplate="%s | Catch Transport Admin" htmlAttributes={{ 'data-mode': 'dark' }} />
      <div className="flex min-h-screen dark:bg-primary">
        <div className="mx-auto w-full xl:w-[60%]">
          <div className="flex min-h-full flex-1">
            <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
              <LoginFormContextProvider>
                <div className="mx-auto w-full max-w-sm lg:w-96">
                  <div>
                    <div className="flex items-center justify-center">
                      <CircleLogo width={100} />
                    </div>
                    <FormTitle />
                    <Messages />
                  </div>
                  <div className="mt-10">
                    <div>
                      <Outlet />
                    </div>
                  </div>
                </div>
              </LoginFormContextProvider>
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
              <img
                className="absolute inset-0 h-full w-full object-cover opacity-40"
                src="/catch_login_bg.png"
                alt="Interior of Catch Transportation vehicle"
              />
              <div className="absolute -z-10 h-full w-full bg-gradient-to-r from-primary to-formBackgroundOnDark" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
