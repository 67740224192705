import { Fragment, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

import { quoteDelete } from '../../services/quoteDelete'
import type { QuoteGetResult } from '../../services/quoteGet'
import createErrorToast from '../../utils/createErrorToast'
import createSuccessToast from '../../utils/createSuccessToast'
import RoundedButton from '../button-rounded'

interface Props {
  quoteUuid: string
  quoteStatus: QuoteGetResult['quoteStatus']
  open: boolean
  onComplete: () => void
}

export default function QuoteDeleteModal(props: Props) {
  const { open, onComplete, quoteUuid, quoteStatus } = props

  const navigate = useNavigate()
  const { addToast } = useToasts()
  const cancelButtonRef = useRef(null)

  const onSubmit: JSX.IntrinsicElements['form']['onSubmit'] = e => {
    e.preventDefault()

    quoteDelete({ quoteUuid })
      .then(() => {
        createSuccessToast('Quote deleted', addToast)
        onComplete()
        navigate('/')
      })
      .catch(err => createErrorToast(err, addToast))
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onComplete}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <form onSubmit={onSubmit} noValidate>
          <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-errorOnLight">
                      <ExclamationTriangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Are you sure you want to {quoteStatus === 'draft' ? 'delete' : 'cancel'} this quote?
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 grid grid-flow-row-dense grid-cols-2 gap-3">
                    <RoundedButton
                      type="button"
                      variant="secondary"
                      size="lg"
                      onClick={onComplete}
                      ref={cancelButtonRef}
                    >
                      Close
                    </RoundedButton>
                    <RoundedButton type="submit" variant="negative" size="lg">
                      {quoteStatus === 'draft' ? 'Delete' : 'Cancel'} Quote
                    </RoundedButton>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  )
}
