import { type ReactNode } from 'react'

import { DateTime } from 'luxon'

import formatDateTime from '../utils/formatDateTime'
import getDateTimeValue from '../utils/getDateTimeValue'

import type { SpanProps } from './display-date'

interface Props extends Omit<SpanProps, 'children'> {
  time: DateTime | Date | string | undefined | null
  children?: (formattedDate: string) => ReactNode
  options?: Intl.DateTimeFormatOptions
}

export default function DisplayTime({ time, options, children, ...spanProps }: Props) {
  const dateTimeValue = getDateTimeValue(filterTime(time))
  const formattedDate = formatDateTime(dateTimeValue, false, options ?? DateTime.TIME_SIMPLE)
  return (
    <span {...spanProps}>
      {formattedDate && typeof children === 'function' ? children(formattedDate) : formattedDate}
    </span>
  )
}

function filterTime(time: DateTime | Date | string | undefined | null) {
  if (typeof time === 'string' && /^\d{2}:\d{2}:\d{2}\.\d{3,7}/.test(time)) {
    return DateTime.fromFormat(time.substring(0, 12), 'HH:mm:ss.SSS', { zone: 'utc' })
  }
  return time
}
