import axios from 'axios'

import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, Response200 } from '../types/schema-utils'

import AxiosDefaultConfig from './api-config'

export enum Client {
  admin = 'admin',
  app = 'app',
}

const adminUrl = '/api/v1/config/admin'
const appUrl = '/api/v1/config'
const method = 'get'

type AdminApiOperation = Operation<paths, typeof adminUrl, typeof method>
type AppApiOperation = Operation<paths, typeof appUrl, typeof method>

export type AdminConfig = Response200<AdminApiOperation>
export type AppConfig = Response200<AppApiOperation>

export async function configGet(client: Client.app): Promise<AppConfig>
export async function configGet(client: Client.admin): Promise<AdminConfig>
export async function configGet(client: Client): Promise<AdminConfig | AppConfig> {
  const url = client === Client.app ? appUrl : adminUrl

  const { data } = await axios.request({
    ...AxiosDefaultConfig,
    method,
    url,
  })
  return data
}
