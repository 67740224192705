import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export default function SearchInput() {
  return (
    <div className="w-full max-w-lg lg:max-w-xs">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative text-lightGrey focus-within:text-gray-600">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
        </div>
        <input
          id="search"
          className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-darkGrey focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary sm:text-sm sm:leading-6"
          placeholder="Search"
          type="search"
          name="search"
        />
      </div>
    </div>
  )
}
