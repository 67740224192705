import { useCallback, useState } from 'react'

import { useRideContext } from '../../context/ride-state'
import { RideStateActionType, useRideDispatch } from '../../context/ride-state-reducer'
import { rideItineraryCreate } from '../../services/rideItineraryCreate'
import { rideItineraryDelete } from '../../services/rideItineraryDelete'
import type { RideItineraryUpdateResult } from '../../services/rideItineraryUpdate'
import { rideItineraryUpdate } from '../../services/rideItineraryUpdate'
import type { ItineraryItem } from '../../services/types'
import RideItinerary from '../shared/ride-itinerary'

export default function RideScheduleManager() {
  const { ride } = useRideContext()
  const rideDispatch = useRideDispatch()

  const [showEditLocationDrawer, setShowEditLocationDrawer] = useState<boolean>(false)

  const { uuid: rideUuid, itinerary } = ride

  const deleteItineraryItem = (itemUuid: string) => {
    if (!rideUuid) {
      return
    }

    rideItineraryDelete({
      rideUuid,
      itemUuid,
    }).then(newItinerary => rideDispatch({ type: RideStateActionType.UpdateItinerary, payload: newItinerary }))
  }

  const toggleItineraryDrawer = useCallback(() => {
    setShowEditLocationDrawer(s => !s)
  }, [])

  const onItineraryUpdated = (newItinerary: RideItineraryUpdateResult) => {
    rideDispatch({ type: RideStateActionType.UpdateItinerary, payload: newItinerary })
    toggleItineraryDrawer()
  }

  const updateItineraryItem = (itineraryItem: ItineraryItem) => {
    if (!rideUuid) {
      return
    }

    const { uuid, date, location, time } = itineraryItem

    if (uuid) {
      const updatedItem = {
        date,
        itemUuid: uuid,
        location,
        time,
        rideUuid,
      }

      rideItineraryUpdate(updatedItem).then(onItineraryUpdated)
    } else {
      const newItem = {
        rideUuid,
        time,
        date,
        location,
      }

      rideItineraryCreate(newItem).then(onItineraryUpdated)
    }
  }

  return (
    <RideItinerary
      itinerary={itinerary}
      drawerOpen={showEditLocationDrawer}
      setDrawerOpen={setShowEditLocationDrawer}
      updateItineraryItem={updateItineraryItem}
      deleteItineraryItem={deleteItineraryItem}
    />
  )
}
