import { Outlet } from 'react-router-dom'

import AppContextProvider from './context/app-context-provider'

export default function App() {
  return (
    <AppContextProvider>
      <Outlet />
    </AppContextProvider>
  )
}
