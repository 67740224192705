import { CalendarDaysIcon } from '@heroicons/react/20/solid'
import { DateTime } from 'luxon'

import type { QuoteSearchItem } from '../../services/quoteSearch'
import DisplayDateRange from '../display-date-range'
import Headline from '../headline'

interface Props {
  quote: QuoteSearchItem
}

export default function QuoteSearchHitEventDisplay(props: Props) {
  const { quote } = props

  return (
    <div className="flex items-center gap-x-2 rounded-t-lg bg-secondaryMedium p-4 font-medium">
      <CalendarDaysIcon className="mx-2 h-6 w-6 text-formIconsOnLight" aria-hidden="true" />
      <div>
        <div>
          <Headline level={2} className="inline-block text-lg md:text-lg">
            {quote.eventName}
          </Headline>{' '}
          <span className="text-mediumGrey">({quote.serviceType})</span>
        </div>
        <div>
          <DisplayDateRange
            className="text-sm text-tertiary"
            startDate={quote.pickupDate}
            startOptions={{ weekday: 'short', month: 'short', day: 'numeric' }}
            endDate={quote.dropOffDate}
            endOptions={DateTime.DATE_MED_WITH_WEEKDAY}
            junction=" thru "
          />
        </div>
      </div>
    </div>
  )
}
