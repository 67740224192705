import { twMerge } from 'tailwind-merge'

type ParagraphProps = JSX.IntrinsicElements['p']
type TdProps = JSX.IntrinsicElements['td']

interface ElementProps extends ParagraphProps {
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl'
  as: 'p' | 'span' | 'div' | 'li'
}

interface TableElementProps extends TdProps {
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl'
  as: 'td' | 'th'
}

type Props = ElementProps | TableElementProps

export default function Block(props: Props) {
  const { as, size, className, children, ...elementProps } = props

  const Tag = `${as}` as 'p' | 'span' | 'div' | 'li' | 'td' | 'th'

  return (
    <Tag
      // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
      {...(elementProps as any)}
      className={twMerge(
        'rounded-lg border border-formBorderOnLight bg-sectionBackground font-sans font-extrabold tracking-tight text-paragraphText shadow-sm',
        size === 'xs' && 'text-xs sm:text-sm',
        size === 'sm' && 'text-sm sm:text-base',
        size === 'base' && 'text-base sm:text-lg',
        size === 'lg' && 'text-lg sm:text-xl',
        size === 'xl' && 'text-xl sm:text-2xl',
        size === '2xl' && 'text-2xl sm:text-3xl',
        size === '3xl' && 'text-3xl sm:text-4xl',
        className,
      )}
    >
      {children}
    </Tag>
  )
}
