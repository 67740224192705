import { PencilIcon } from '@heroicons/react/20/solid'

import type { RideSearchResultItem } from '../../services/rideSearch'
import CardActionContainer from '../card-action-container'
import CardActionLink from '../card-action-link'

interface Props {
  ride: RideSearchResultItem
}

export default function RideSearchHitActions(props: Props) {
  const { ride } = props

  return (
    <CardActionContainer>
      <div className="-ml-px flex w-0 flex-1">
        <CardActionLink to={`/rides/${ride.uuid}`} className="rounded-b-lg">
          <PencilIcon className="h-5 w-5 text-formIconsOnLight" aria-hidden="true" />
          View Ride
        </CardActionLink>
      </div>
    </CardActionContainer>
  )
}
