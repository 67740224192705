import type { AddToast } from 'react-toast-notifications'

import getErrorMessage from './getErrorMessage'

export default function createErrorToast(err: unknown, addToast: AddToast) {
  const errMsg = getErrorMessage(err)
  if (errMsg) {
    addToast(errMsg, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 10000 })
  }
}
