import { useCallback } from 'react'
import { NavLink } from 'react-router-dom'

import { Disclosure } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/24/outline'

import AvatarImage from '../components/avatar-image'
import AvatarInitials from '../components/avatar-initials'
import DarkModeSwitch from '../components/dark-mode-switch'
import type { User } from '../context/app-state'
import { useAppContext } from '../context/app-state'
import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'
import { disableDarkMode, disableNotifications } from '../layout/flags'

import userNavigation from './navigation-routes-user'

interface Props {
  user: User
}

export default function MobileUserMenu(props: Props) {
  const { user } = props
  const { colorTheme } = useAppContext()
  const appDispatch = useAppDispatch()

  const isDarkMode = disableDarkMode ? false : colorTheme === 'dark'

  const toggleTheme = useCallback(() => {
    appDispatch({ type: AppStateActionType.SetTheme, payload: isDarkMode ? 'light' : 'dark' })
  }, [appDispatch, isDarkMode])

  return (
    <div className="border-t border-dividerOnDark pb-3 pt-4">
      <div className="flex items-center px-5">
        <div className="flex-shrink-0">
          {user.imageUrl ? (
            <AvatarImage size="md" imageUrl={user.imageUrl} />
          ) : (
            <AvatarInitials size="md" initials={user.initials} className="bg-buttonPrimary" />
          )}
        </div>
        <div className="ml-3">
          <div className="text-base font-medium text-white">{user.firstName}</div>
          <div className="text-sm font-medium text-secondary">{user.email}</div>
        </div>
        {!disableNotifications && (
          <button
            type="button"
            className="text-primary-200 ml-auto flex-shrink-0 rounded-full bg-primary p-1 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary"
          >
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}
        {!disableDarkMode && (
          <DarkModeSwitch
            checked={isDarkMode}
            onChange={toggleTheme}
            sunColor="hsla(53, 91%, 58%, 1)"
            className="ml-3 h-6 w-6"
            title={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
          />
        )}
      </div>
      <div className="mt-3 space-y-1 px-2">
        {userNavigation.map(item => (
          <Disclosure.Button
            key={item.name}
            as={NavLink}
            to={item.href}
            className="hover:bg-primary-500 block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-opacity-75"
          >
            {item.name}
          </Disclosure.Button>
        ))}
      </div>
    </div>
  )
}
