import LoadingContent from './loading-content'
import LoadingTable from './loading-table'

export default function LoadingDetailPage() {
  return (
    <div className="flex h-full w-full flex-col lg:flex-row">
      <LoadingContent />
      <div className="ml-3 w-full">
        <div className="mb-6 grid grid-cols-1 space-x-4 space-y-5">
          <LoadingContent />
          <LoadingContent />
          <LoadingContent />
          <LoadingContent />
        </div>
        <LoadingTable />
      </div>
    </div>
  )
}
