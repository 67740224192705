import { twMerge } from 'tailwind-merge'

export type LabelProps = JSX.IntrinsicElements['label']

interface Props extends LabelProps {
  hideLabel?: boolean
  size?: 'sm' | 'md' | 'lg'
}

export default function FormLabel(props: Props) {
  const { className, htmlFor, hideLabel, children, size, ...labelProps } = props
  return (
    <label
      {...labelProps}
      htmlFor={htmlFor}
      className={twMerge(
        'block font-sans text-base font-medium leading-6 text-grey700',
        className,
        size === 'sm' && 'text-sm',
        size === 'md' && 'text-md',
        hideLabel && 'sr-only',
      )}
    >
      {children}
    </label>
  )
}
