import type { RideSearchResultItem } from '../../services/rideSearch'
import Card from '../card'

import RideSearchHitActions from './ride-search-hit-actions'
import RideSearchHitEventDisplay from './ride-search-hit-event-display'
import RideSearchHitInfoTable from './ride-search-hit-info-table'

interface Props {
  ride: RideSearchResultItem
}

export default function RideSearchHit(props: Props) {
  const { ride } = props

  const actionButtons = <RideSearchHitActions ride={ride} />

  return (
    <Card cardActions={actionButtons}>
      <div>
        <RideSearchHitEventDisplay ride={ride} />
        <RideSearchHitInfoTable ride={ride} />
      </div>
    </Card>
  )
}
