import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

interface Props {
  open: boolean
}

export default function MobileMenuButton(props: Props) {
  const { open } = props
  return (
    <Disclosure.Button className="text-primary-200 hover:bg-primary-500 inline-flex items-center justify-center rounded-md bg-primary p-2 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary">
      <span className="sr-only">Open main menu</span>
      {open ? (
        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
      ) : (
        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
      )}
    </Disclosure.Button>
  )
}
