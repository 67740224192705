// The order here is important, so we need to disable the import sorting rule
/* eslint-disable simple-import-sort/imports */
import https from 'https'
import type { AxiosRequestConfig } from 'axios'
/* eslint-enable simple-import-sort/imports */

import qs from 'qs'

const isRunningInJest = process.env.JEST_WORKER_ID !== undefined

const config: AxiosRequestConfig = {
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat', sort: (a, b) => a.localeCompare(b) }),
}

const AxiosDefaultConfig: AxiosRequestConfig = isRunningInJest
  ? {
      ...config,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
      proxy: {
        host: 'localhost',
        port: 7143,
        protocol: 'https',
      },
    }
  : config

export default AxiosDefaultConfig
