import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'
import DashboardLayout from '../layout/dashboard-layout'
import getCurrentCognitoSession from '../services/cognito/getCurrentCognitoSession'
import userGetCurrent from '../services/userGetCurrent'

export default function ProtectedRoute() {
  const location = useLocation()
  const appDispatch = useAppDispatch()

  const [didCheckUser, setDidCheckUser] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    getCurrentCognitoSession()
      .then(session => {
        if (session) {
          setIsLoggedIn(session.isValid())
          userGetCurrent().then(currentUser => {
            appDispatch({
              type: AppStateActionType.SetUser,
              payload: currentUser,
            })
          })
        }
      })
      .finally(() => {
        setDidCheckUser(true)
      })
  }, [appDispatch])

  if (didCheckUser) {
    return isLoggedIn ? (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    ) : (
      <Navigate
        to={{
          pathname: '/login',
          search: `?returnUrl=${encodeURIComponent(location.pathname + location.search)}`,
        }}
      />
    )
  }

  return null
}
