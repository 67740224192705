import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/quotes/{quoteUuid}'
const method = 'delete'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type QuoteDeleteInput = PathParams<ApiOperation>

export function quoteDelete(input: QuoteDeleteInput) {
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, input),
    method,
    data: input,
  })
}

export type QuoteDeleteResult = ResultType<typeof quoteDelete>
