import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { ArrowLeftCircleIcon } from '@heroicons/react/20/solid'

import LoadingDetailPage from '../components/loading-detail-page'
import NavigationLink from '../components/navigation-link'
import RideDetailSideBar from '../components/rides/ride-detail-side-bar'
import RideOverview from '../components/rides/ride-overview'
import RiderDetail from '../components/rides/rider-detail'
import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'
import RideContextProvider from '../context/ride-context-provider'
import { useRideContext } from '../context/ride-state'
import type { RideDetailResult } from '../services/rideDetailGet'
import { rideDetailGet } from '../services/rideDetailGet'
import createErrorToast from '../utils/createErrorToast'

export default function RideDetail() {
  const { rideUuid } = useParams()
  const dispatch = useAppDispatch()
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [ride, setRide] = useState<RideDetailResult>()

  useEffect(() => {
    dispatch({ type: AppStateActionType.SetNavTitle, payload: 'Ride Detail' })
  }, [dispatch])

  useEffect(() => {
    dispatch({
      type: AppStateActionType.SetBackPanel,
      payload: (
        <NavigationLink to="/rides" className="inline-flex items-center gap-x-1.5" variant="accent">
          <ArrowLeftCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> Back to All Rides
        </NavigationLink>
      ),
    })
  }, [dispatch])

  useEffect(() => {
    const abortController = new AbortController()

    if (rideUuid) {
      setLoading(true)

      rideDetailGet({ rideUuid }, abortController.signal)
        .then(setRide)
        .catch(err => {
          createErrorToast(err, addToast)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return () => {
      abortController.abort()
    }
  }, [rideUuid, addToast])

  if (loading || !ride) {
    return <LoadingDetailPage />
  }

  return (
    <RideContextProvider ride={ride}>
      <RideDetailPage />
    </RideContextProvider>
  )
}

function RideDetailPage() {
  const { ride, activeRider } = useRideContext()

  return (
    <div className="flex h-full w-full flex-col lg:flex-row">
      <RideDetailSideBar />
      <div className="ml-0 w-full sm:ml-3">
        {activeRider ? <RiderDetail ride={ride} rider={activeRider} /> : <RideOverview ride={ride} />}
      </div>
    </div>
  )
}
