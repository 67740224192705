import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/quotes/{quoteUuid}'
const method = 'get'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type QuoteGetInput = PathParams<ApiOperation>

export function quoteGet(input: QuoteGetInput, abortSignal?: AbortSignal) {
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, input),
    method,
    signal: abortSignal,
  })
}

export type QuoteGetResult = ResultType<typeof quoteGet>
export type QuoteItinerary = QuoteGetResult['itinerary'][number]
