import { useReducer } from 'react'
import type { ReactNode } from 'react'

import type { RideDetailResult } from '../services/rideDetailGet'

import { defaultRideState, RideStateContext } from './ride-state'
import { RideDispatchContext, rideStateReducer } from './ride-state-reducer'

interface Props {
  ride: RideDetailResult
  children?: ReactNode | undefined
}

export default function RideContextProvider(props: Props) {
  const { ride, children } = props
  const [state, dispatch] = useReducer(rideStateReducer, { ...defaultRideState, ride })

  return (
    <RideStateContext.Provider value={state}>
      <RideDispatchContext.Provider value={dispatch}>{children}</RideDispatchContext.Provider>
    </RideStateContext.Provider>
  )
}
