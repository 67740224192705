import { Link } from 'react-router-dom'

import type { QuoteSearchItem } from '../../services/quoteSearch'
import DisplayDate from '../display-date'
import DisplayMoney from '../display-money'
import TableRow from '../table-row'

import DisplayQuoteStatus from './display-quote-status'
import QuoteExpiresIn from './quote-expires-in'

interface Props {
  quote: QuoteSearchItem
}

export default function QuoteSearchHitInfoTable(props: Props) {
  const { quote } = props
  const {
    firstName,
    lastName,
    quoteStatus,
    vehicleCapacity,
    vehicleType,
    numberOfRiders,
    hasDeposit,
    externalId,
    depositAmount,
    depositDueDate,
    finalAmount,
    totalDueDate,
    totalAmount,
  } = quote

  const isActiveState = quoteStatus !== 'booked' && quoteStatus !== 'cancelled' && quoteStatus !== 'declined'

  return (
    <div className="divide-y">
      <TableRow label="Organizer">
        <Link to={`/customers/${quote.customerUuid}`} className="flex-shrink-0">
          <div className="flex items-center truncate text-lg text-primary">
            {firstName} {lastName}
            <span className="sr-only">
              Quote for {firstName} {lastName}
            </span>
          </div>
        </Link>
      </TableRow>
      {isActiveState && (
        <TableRow label="Expires">
          <QuoteExpiresIn quote={quote} />
        </TableRow>
      )}
      <TableRow label="Status">
        <DisplayQuoteStatus quoteStatus={quoteStatus} />
      </TableRow>
      <TableRow label="Vehicle">
        <div className="flex items-center gap-x-2">
          <span className="capitalize">{vehicleType}</span>&nbsp;({numberOfRiders}/{vehicleCapacity})
        </div>
      </TableRow>
      {!!externalId && <TableRow label="Quote ID">{externalId}</TableRow>}
      {hasDeposit && (
        <TableRow
          label={
            <>
              Deposit Amount <br />
              <DisplayDate date={depositDueDate} className="text-xs text-errorOnLight">
                {formattedDate => <>due {formattedDate}</>}
              </DisplayDate>
            </>
          }
        >
          <DisplayMoney amount={depositAmount} />
        </TableRow>
      )}
      <TableRow
        label={
          <>
            Final Amount <br />
            <DisplayDate date={totalDueDate} className="text-xs text-errorOnLight">
              {formattedDate => <>due {formattedDate}</>}
            </DisplayDate>
          </>
        }
      >
        <DisplayMoney amount={finalAmount} />
      </TableRow>
      <TableRow label={<span className="font-bold">Ride Total</span>}>
        <DisplayMoney className="font-bold" amount={totalAmount} />
      </TableRow>
    </div>
  )
}
