import type { CustomerRideRequestItem } from '../../services/customerRideRequests'
import CarIcon from '../../svg/car-icon'
import RoundedButton from '../button-rounded'
import DisplayDate from '../display-date'
import DisplayDateRange from '../display-date-range'

interface Props {
  item: CustomerRideRequestItem
  createQuoteClick: (rideRequestUuid: string) => void
}

export default function CustomerRequestRow(props: Props) {
  const { item, createQuoteClick } = props
  const { requestDate, form } = item

  const onCreateQuoteClick = () => {
    createQuoteClick(item.requestUuid)
  }

  return (
    <tr className="even:bg-primary/5">
      <td className="p-3 py-4 text-sm font-medium text-darkGrey sm:w-auto sm:max-w-none">
        <strong>{form.groupName}</strong>
        <dl className="font-normal lg:hidden">
          <DisplayDateRange startDate={form.pickupDate} endDate={form.dropOffDate} />
          <dd className="mt-1 truncate text-grey700">{form.amountOfRiders} Riders</dd>
          <dd className="mt-1 truncate capitalize text-grey700">{form.vehicleType}</dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-grey500 lg:table-cell">
        <DisplayDateRange startDate={form.pickupDate} endDate={form.dropOffDate} />
      </td>
      <td className="hidden px-3 py-4 text-sm text-grey500 sm:table-cell">{form.amountOfRiders}</td>
      <td className="hidden px-3 py-4 text-sm capitalize text-grey500 sm:table-cell">{form.vehicleType}</td>
      <td className="px-3 py-4 text-sm capitalize text-grey500">{form.serviceType}</td>
      <td className="px-3 py-4 text-sm text-grey500">
        <DisplayDate date={requestDate} />
      </td>
      <td className="px-3 py-4 text-right text-sm font-medium sm:pr-0">
        <RoundedButton type="button" onClick={onCreateQuoteClick} variant="accent">
          <CarIcon className="-ml-0.5 mr-2 h-5 w-6" aria-hidden="true" />
          <span className="sr-only lg:not-sr-only">Create Quote</span>
        </RoundedButton>
      </td>
    </tr>
  )
}
