import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { useAppContext } from '../context/app-state'
import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'

export default function NavHeader() {
  const { navTitle, actionPanel, backPanel } = useAppContext()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  useEffect(() => {
    return () => {
      dispatch({ type: AppStateActionType.ResetHeaderUx })
    }
  }, [dispatch, pathname])

  return (
    <>
      <Helmet titleTemplate={`%s | ${navTitle}`} />
      <header className="py-4">
        <div className="mx-auto mb-6 max-w-7xl px-4 sm:mb-2 sm:px-6 lg:px-8" data-mode="dark">
          {backPanel}
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <div className="min-w-0 flex-1">
              <h1 className="text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight">
                {navTitle}
              </h1>
            </div>
            <div className="mt-4 flex sm:ml-4 sm:mt-0">{actionPanel}</div>
          </div>
        </div>
      </header>
    </>
  )
}
