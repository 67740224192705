import { twMerge } from 'tailwind-merge'

type H1Props = JSX.IntrinsicElements['h1']

interface Props extends H1Props {
  level: 1 | 2 | 3 | 4 | 5 | 6
}

export default function Headline(props: Props) {
  const { level, className, children } = props

  const HTag = `h${level}` as keyof JSX.IntrinsicElements

  return (
    <HTag
      className={twMerge(
        'dark:text-light-100 font-sans font-extrabold tracking-tight text-mainHeadline',
        level === 1 && 'text-3xl md:text-4xl',
        level === 2 && 'text-2xl md:text-3xl',
        level === 3 && 'text-xl md:text-2xl',
        level === 4 && 'text-lg md:text-xl',
        level === 5 && 'text-base md:text-lg',
        level === 6 && 'text-sm md:text-base',
        className,
      )}
    >
      {children}
    </HTag>
  )
}
