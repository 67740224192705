import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'

const url = '/api/v1/users/me'
const method = 'get'

type ApiOperation = Operation<paths, typeof url, typeof method>

export default function userGetCurrent() {
  return sendRequest<ApiOperation>({
    url,
    method,
  })
}

export type UserGetCurrentResult = ResultType<typeof userGetCurrent>
