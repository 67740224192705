import { forwardRef, useId } from 'react'
import type { ReactNode } from 'react'
import type { FieldError } from 'react-hook-form'

import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { twMerge } from 'tailwind-merge'

import FormLabel from './form-label'
import ValidationMessage from './validation-message'

type InputProps = JSX.IntrinsicElements['input']
type TextAreaProps = JSX.IntrinsicElements['textarea']
type LabelProps = JSX.IntrinsicElements['label']
type DivProps = JSX.IntrinsicElements['div']
type ParagraphProps = JSX.IntrinsicElements['p']

interface AsInputProps extends Omit<InputProps, 'size'> {
  multiline?: false
}

interface AsTextAreaProps extends Omit<TextAreaProps, 'size'> {
  multiline: true
}

const InputControl = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return <input {...props} ref={ref} />
})

const TextareaControl = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  return <textarea {...props} ref={ref} />
})

export type TextInputProps = {
  labelText: ReactNode
  labelAccessory?: ReactNode
  helpText?: ReactNode
  helpTextProps?: ParagraphProps
  validationMessage?: FieldError | ReactNode
  validationMessageProps?: ParagraphProps
  labelProps?: LabelProps
  containerProps?: DivProps
  bottomBorderProps?: ParagraphProps
  hideLabel?: boolean
  size?: 'sm' | 'md' | 'lg'
  multiline?: boolean
} & (AsInputProps | AsTextAreaProps)

const TextInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputProps>((props, ref) => {
  const {
    className: inputClassName,
    bottomBorderProps,
    containerProps,
    helpText,
    helpTextProps,
    hideLabel,
    id,
    labelProps,
    labelText,
    labelAccessory,
    validationMessage,
    validationMessageProps,
    size,
    multiline,
    ...inputProps
  } = props
  const { className: containerClassName, ...otherContainerProps } = containerProps ?? {}
  const { className: helpTextClassName, ...otherHelpTextProps } = helpTextProps ?? {}
  const { className: validationMessageClassName, ...otherValidationMessageProps } = validationMessageProps ?? {}
  const { className: bottomBorderClassName, ...otherBottomBorderProps } = bottomBorderProps ?? {}
  const autoId = useId()

  const inputId = id || autoId
  const errorId = `${inputId}-error`
  const helpTextId = `${inputId}-description`

  const hasValidationMessage = !!validationMessage

  const describedby = [helpText && helpTextId, hasValidationMessage && errorId].filter(Boolean).join(' ')

  const Tag = multiline ? TextareaControl : InputControl

  return (
    <div {...otherContainerProps} className={twMerge('group/control flex-grow', containerClassName)}>
      {labelAccessory ? (
        <div className="flex items-center justify-between">
          <FormLabel size={size} {...labelProps} htmlFor={inputId} hideLabel={hideLabel}>
            {labelText}
          </FormLabel>
          {labelAccessory}
        </div>
      ) : (
        <FormLabel size={size} {...labelProps} htmlFor={inputId} hideLabel={hideLabel}>
          {labelText}
        </FormLabel>
      )}

      <div className="relative mt-2">
        <Tag
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(inputProps as any)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={ref as any}
          id={inputId}
          className={twMerge(
            'group/input translate-all peer block w-full border-0 px-1 py-1.5 font-sans duration-300 placeholder:italic focus:outline-0 focus:ring-0',
            // smaller screens
            'sm:text-sm sm:leading-6',
            // light mode
            'bg-formBackgroundOnLight text-formTextFocusedOnLight placeholder:text-formPlaceHolderOnLight',
            hasValidationMessage &&
              'placeholder:text-text-errorOnLight border-l-8 border-errorOnLight text-errorOnLight ring-errorLightTint transition-colors focus:border-errorOnDark focus:ring-errorOnDark',
            size === 'sm' && 'text-sm',
            size === 'lg' && 'sm:text-md px-4 py-2 text-lg',
            inputClassName,
          )}
          aria-invalid={hasValidationMessage ? 'true' : undefined}
          aria-describedby={describedby}
        />
        <div
          {...otherBottomBorderProps}
          className={twMerge(
            'absolute inset-x-0 bottom-0 border-t',
            'border-formBorderOnLight peer-focus:border-t-2 peer-focus:border-primary ',
            hasValidationMessage && 'border-errorOnLight',
            bottomBorderClassName,
          )}
          aria-hidden="true"
        />
        {hasValidationMessage && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-errorOnLight" aria-hidden="true" />
          </div>
        )}
      </div>
      {helpText && (
        <p
          {...otherHelpTextProps}
          className={twMerge(
            'invisible mt-2 text-sm text-paragraphText group-focus-within/control:visible',
            helpTextClassName,
          )}
          id={helpTextId}
        >
          {helpText}
        </p>
      )}
      {validationMessage && (
        <p
          {...otherValidationMessageProps}
          className={twMerge('mt-2 text-sm text-errorOnLight', validationMessageClassName)}
          id={errorId}
          role="alert"
        >
          <ValidationMessage message={validationMessage} />
        </p>
      )}
    </div>
  )
})

InputControl.displayName = 'input'
TextareaControl.displayName = 'textarea'
TextInput.displayName = 'TextInput'

export default TextInput
