import { useEffect } from 'react'

import UserCreateForm from '../components/user-create-form'
import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'

export default function UserCreatePage() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch({ type: AppStateActionType.SetNavTitle, payload: 'Create User' })
  }, [dispatch])

  return (
    <div>
      <UserCreateForm />
    </div>
  )
}
