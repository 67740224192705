import { useEffect } from 'react'

import { twMerge } from 'tailwind-merge'

import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'
import colors from '../theme/colors'

export default function ColorSwatches() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch({ type: AppStateActionType.SetNavTitle, payload: 'Paint Store' })
  }, [dispatch])

  const colorMap = Object.keys(colors)
    .sort()
    .reduce((acc, name) => {
      const key = name as keyof typeof colors
      acc.push({ name, value: colors[key] })
      return acc
    }, [] as { name: string; value: string }[])

  return (
    <div className="grid grid-cols-3 gap-4">
      {colorMap.map(({ name, value }) => {
        return (
          <div key={name} className="flex flex-col items-center">
            <div className={twMerge('flex h-40 w-40 items-center justify-center text-xs text-grey500', `bg-${name}`)}>
              {value}
            </div>
            <div className="text-sm text-grey500">{name}</div>
          </div>
        )
      })}
    </div>
  )
}
