import type { ReactNode } from 'react'

import { twMerge } from 'tailwind-merge'

type DivProps = JSX.IntrinsicElements['div']
type DtProps = JSX.IntrinsicElements['dt']
type DdProps = JSX.IntrinsicElements['dd']

interface TableRowProps extends DivProps {
  label: ReactNode
  labelProps?: DtProps
  valueProps?: DdProps
}

export default function TableRow(props: TableRowProps) {
  const { label, labelProps, children, valueProps, ...containerProps } = props
  const { className, ...divProps } = containerProps
  const { className: labelClassName, ...dtProps } = labelProps ?? {}
  const { className: valueClassName, ...ddProps } = valueProps ?? {}
  return (
    <div
      {...divProps}
      className={twMerge('grid grid-cols-3 py-4 text-sm even:bg-primary/5 sm:gap-4 sm:pl-5', className)}
    >
      <dt {...dtProps} className={twMerge('flex flex-col content-center pl-1 leading-6', labelClassName)}>
        {label}
      </dt>
      <dd {...ddProps} className={twMerge('col-span-2 flex items-center', valueClassName)}>
        {children}
      </dd>
    </div>
  )
}
