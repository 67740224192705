import { forwardRef } from 'react'

import { twMerge } from 'tailwind-merge'

import type { ButtonVariant } from '../types/ButtonVariant'

type ButtonProps = JSX.IntrinsicElements['button']

interface Props extends ButtonProps {
  variant?: ButtonVariant
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

const RoundedButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, className, size = 'md', type, variant = 'primary', disabled, ...buttonProps } = props

  return (
    <button
      {...buttonProps}
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type || 'button'}
      disabled={disabled}
      className={twMerge(
        'inline-flex items-center justify-center rounded-full border-2 font-sans font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2',
        size === 'sm' && 'px-2.5 py-1 text-xs',
        size === 'md' && 'px-3 py-1.5 text-sm',
        size === 'lg' && 'px-4 py-2 text-base',
        size === 'xl' && 'px-6 py-3 text-lg',
        variant === 'primary' &&
          'border-primary bg-buttonPrimary text-white hover:bg-buttonPrimaryHover focus-visible:ring-primary',
        variant === 'secondary' &&
          'border-buttonPrimary bg-buttonTertiary text-buttonPrimary hover:border-buttonPrimaryHover hover:text-buttonPrimaryHover focus-visible:ring-primary',
        variant === 'accent' &&
          'border-buttonSecondary bg-buttonSecondary text-darkGrey hover:bg-buttonSecondaryHover focus-visible:ring-buttonSecondaryHover',
        variant === 'negative' && 'bg-errorOnLight text-white hover:bg-errorOnDark focus-visible:ring-errorOnDark',
        variant === 'positive' &&
          'bg-successOnLight text-white hover:bg-successOnDark focus-visible:ring-successOnDark',
        disabled && 'cursor-not-allowed opacity-50',
        className,
      )}
    >
      {children}
    </button>
  )
})

RoundedButton.displayName = 'RoundedButton'

export default RoundedButton
