import type { ClientMetadata } from 'amazon-cognito-identity-js'

import createCognitoUser from './createCognitoUser'

interface InitiateForgotPasswordResponse {
  user: Awaited<ReturnType<typeof createCognitoUser>>
  attributeName: string
  deliveryMedium: string
  destination: string
}

export default async function initiateForgotPassword(email: string, clientMetaData?: ClientMetadata) {
  const user = await createCognitoUser(email)
  return new Promise<InitiateForgotPasswordResponse>((resolve, reject) => {
    user.forgotPassword(
      {
        onSuccess(data) {
          const {
            CodeDeliveryDetails: {
              AttributeName: attributeName,
              DeliveryMedium: deliveryMedium,
              Destination: destination,
            },
          } = data
          resolve({ user, attributeName, deliveryMedium, destination })
        },
        onFailure(err) {
          reject(err)
        },
      },
      clientMetaData,
    )
  })
}
