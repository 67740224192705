import { ClockIcon, DocumentTextIcon, UserIcon, UserMinusIcon } from '@heroicons/react/20/solid'

import type { Rider } from '../../services/rideDetailGet'
import CarIcon from '../../svg/car-icon'
import Block from '../block'
import DisplayDate from '../display-date'
import DisplayMoney from '../display-money'
import Headline from '../headline'
import TableRow from '../table-row'

interface Props {
  rider: Rider
}

export default function RiderDetailInfoTable(props: Props) {
  const { rider } = props

  const {
    riderStatus,
    contractStatus,
    createdAt,
    contractSignedAt,
    totalScheduled,
    totalRefunded,
    isFullyPaid,
    totalPaid,
    totalUnpaid,
    hasManualPayments,
  } = rider

  return (
    <Block as="div" className="mb-6 p-6">
      <div className="mb-6 flex flex-row justify-between border-b-2 border-darkGrey/10 pb-3">
        <Headline level={3} className="inline-flex items-center gap-x-1.5">
          <CarIcon className="-ml-0.5 h-5 w-6 text-formIconsOnLight" aria-hidden="true" /> Rider Details
        </Headline>
      </div>
      <div className="divide-y px-6">
        <TableRow label="Rider Status">
          {riderStatus === 'rider' && (
            <div className="flex items-center text-successOnLight">
              <UserIcon className="-ml-0.5 mr-1 h-4 w-4" aria-hidden="true" />
              Accepted on&nbsp;
              <DisplayDate date={createdAt} />
            </div>
          )}
          {riderStatus === 'invited' && (
            <div className="flex items-center text-primary">
              <ClockIcon className="-ml-0.5 mr-1 h-4 w-4" aria-hidden="true" />
              Invited on&nbsp;
              <DisplayDate date={createdAt} />
            </div>
          )}
          {riderStatus === 'declined' && (
            <div className="flex items-center text-errorOnLight">
              <UserMinusIcon className="-ml-0.5 mr-1 h-4 w-4" aria-hidden="true" />
              Declined
            </div>
          )}
        </TableRow>
        <TableRow label="Contract">
          {contractStatus === 'signed' ? (
            <div className="flex items-center text-successOnLight">
              <DocumentTextIcon className="-ml-0.5 mr-1 h-4 w-4" aria-hidden="true" /> Signed&nbsp;
              <DisplayDate date={contractSignedAt} />
            </div>
          ) : (
            <div className="flex items-center text-errorOnLight">
              <DocumentTextIcon className="-ml-0.5 mr-1 h-4 w-4" aria-hidden="true" /> Not Signed
            </div>
          )}
        </TableRow>
        <Headline level={5} className="py-6 text-mediumGrey">
          Payment Status
        </Headline>
        <TableRow label="Paid in Full">
          {isFullyPaid ? 'Yes' : 'No'}
          {hasManualPayments && <>*</>}
        </TableRow>
        {totalScheduled > 0 && (
          <TableRow label="Total Scheduled">
            <DisplayMoney amount={totalScheduled} />
          </TableRow>
        )}
        {totalRefunded > 0 && (
          <TableRow className="font-bold text-primary" label="Total Refunded">
            <DisplayMoney amount={totalRefunded} />
          </TableRow>
        )}
        {totalUnpaid > 0 && (
          <TableRow className="font-bold text-successOnLight" label="Total Paid">
            <DisplayMoney amount={totalPaid} />
            {hasManualPayments && <>*</>}
          </TableRow>
        )}
        {totalUnpaid > 0 && (
          <TableRow className="font-bold text-errorOnLight" label="Total Unpaid">
            <DisplayMoney amount={totalUnpaid} />
          </TableRow>
        )}
      </div>
    </Block>
  )
}
