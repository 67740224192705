import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { RideStateActionType, useRideDispatch } from '../../context/ride-state-reducer'
import { rideAddOverageCharge } from '../../services/rideAddOverageCharge'
import type { RideDetailResult } from '../../services/rideDetailGet'
import createErrorToast from '../../utils/createErrorToast'
import getDateTimeValue from '../../utils/getDateTimeValue'
import RoundedButton from '../button-rounded'
import FormBlocker from '../form-blocker'
import TextInput from '../text-input'

interface FormData {
  amount: number
  description: string
  dueOn: string
}

interface Props {
  ride: RideDetailResult
  open: boolean
  onComplete: () => void
}

export default function AddOverageCharge({ ride, open, onComplete }: Props) {
  const { addToast } = useToasts()
  const rideDispatch = useRideDispatch()
  const [submitting, setSubmitting] = useState(false)

  const { itinerary, rideDate } = ride

  const dropOff = itinerary.at(-1)

  const endDate = dropOff?.date ?? rideDate

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      dueOn: getDateTimeValue(endDate)?.plus({ days: 7 }).toISODate() ?? '',
    },
  })

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open, reset])

  const onSubmit = (data: FormData) => {
    const { amount, description, dueOn } = data

    setSubmitting(true)

    return rideAddOverageCharge({
      rideUuid: ride.uuid,
      amount,
      description,
      dueOn,
    })
      .then(updatedRide => {
        rideDispatch({ type: RideStateActionType.SetRide, payload: updatedRide })
        onComplete()
      })
      .catch(err => createErrorToast(err, addToast))
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onComplete}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Add Overage Charge
                          </Dialog.Title>
                        </div>
                        <div className="flex h-7 items-center">
                          <button type="button" className="text-gray-400 hover:text-gray-500" onClick={onComplete}>
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Content */}
                    <FormBlocker active={submitting}>
                      <form
                        className="flex flex-1 flex-col space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0"
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                      >
                        {/* Form Fields */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-1 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <TextInput
                              {...register('amount', { required: true })}
                              labelText="Amount"
                              placeholder="Enter the amount of the overage charge"
                              type="number"
                              min={0}
                              validationMessage={errors.amount?.message}
                            />
                          </div>
                          <div>
                            <TextInput
                              {...register('description', { required: false })}
                              labelText="Description"
                              className="mb-8"
                              placeholder="Enter a brief description for the charge"
                              multiline
                              rows={5}
                              validationMessage={errors.description?.message}
                            />
                          </div>
                          <div>
                            <TextInput
                              {...register('dueOn', { required: true })}
                              labelText="Due On"
                              className="mb-8"
                              placeholder="Enter the date on which this charge will occur"
                              type="date"
                              validationMessage={errors.dueOn?.message}
                            />
                          </div>
                        </div>
                        {/* Action buttons */}
                        <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                          <div className="flex justify-end space-x-3">
                            <RoundedButton variant="secondary" className="px-10" onClick={onComplete}>
                              Cancel
                            </RoundedButton>
                            <RoundedButton variant="positive" className="px-10" type="submit">
                              Add Charge
                            </RoundedButton>
                          </div>
                        </div>
                      </form>
                    </FormBlocker>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
