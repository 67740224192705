import { Link } from 'react-router-dom'

import { CreditCardIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'

import type { QuoteGetResult } from '../../services/quoteGet'
import AvatarInitials from '../avatar-initials'
import NavigationLink from '../navigation-link'

type Customer = QuoteGetResult['customer']

type User = Pick<Customer, 'firstName' | 'lastName' | 'phone' | 'email' | 'initials'>
interface Props {
  user?: User
  role?: string
  paymentProfile?: string
  customerUuid?: string
}
export default function DisplayUser(props: Props) {
  const { user, role, paymentProfile, customerUuid } = props

  if (!user) {
    return null
  }

  const userInfo = (
    <>
      <AvatarInitials initials={user.initials} size="lg" />
      <div className="ml-4">
        {user.firstName} {user.lastName}
        {role && <div className="mt-1 capitalize text-mediumGrey">{role}</div>}
      </div>
    </>
  )

  return (
    <div className="flex w-full flex-col p-4 md:flex-row md:p-0">
      <div className="flex-1 whitespace-nowrap pr-3">
        {customerUuid && customerUuid !== '00000000-0000-0000-0000-000000000000' ? (
          <Link className="flex h-full items-center" to={`/customers/${customerUuid}`}>
            {userInfo}
          </Link>
        ) : (
          <div className="flex h-full items-center">{userInfo}</div>
        )}
      </div>
      <div className="flex-1 whitespace-nowrap px-3">
        <div>
          {user.phone && (
            <NavigationLink to={`tel:${getPhoneDial(user)}`} className="mt-1 flex items-center">
              <PhoneIcon className="mr-3 h-5 w-5 text-formIconsOnLight" aria-hidden="true" />
              {getPhoneDisplay(user)}
            </NavigationLink>
          )}
        </div>
        <div>
          {user.email && (
            <NavigationLink to={`mailto:${user.email}`} className="mt-1 flex items-center">
              <EnvelopeIcon className="mr-3 h-5 w-5 text-formIconsOnLight" aria-hidden="true" />
              {user.email}
            </NavigationLink>
          )}
        </div>
        <div>
          {paymentProfile && (
            <NavigationLink to={paymentProfile} className="mt-1 flex items-center" target="_stripe">
              <CreditCardIcon className="mr-3 h-5 w-5 text-formIconsOnLight" aria-hidden="true" />
              Payment Profile
            </NavigationLink>
          )}
        </div>
      </div>
    </div>
  )
}

function getPhoneDisplay(user: unknown) {
  if (!user) return ''
  const x = user as { phoneFormatted: string; phone: string }
  return x.phoneFormatted || x.phone || ''
}

function getPhoneDial(user: unknown) {
  if (!user) return ''
  const x = user as { phoneNumberForDialing: string; phone: string }
  return x.phoneNumberForDialing || x.phone || ''
}
