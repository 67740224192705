import { CurrencyDollarIcon } from '@heroicons/react/20/solid'

import type { Rider } from '../../services/rideDetailGet'
import Block from '../block'
import EmptyContainer from '../empty-container'
import Headline from '../headline'

import PaymentCard from './payment-card'
import RiderManualPayment from './rider-manual-payment'

interface Props {
  rider: Rider
}

export default function RiderDetailPayments(props: Props) {
  const { rider } = props

  const { payments, hasManualPayments } = rider

  return (
    <Block as="div" className="mb-6 p-6">
      <div className="mb-6 flex flex-row justify-between border-b-2 border-darkGrey/10 pb-3">
        <Headline level={3} className="inline-flex items-center gap-x-1.5">
          <CurrencyDollarIcon className="-ml-0.5 h-5 w-5 text-formIconsOnLight" aria-hidden="true" /> Payments
        </Headline>
      </div>
      <RiderManualPayment />
      {payments.length === 0 && (
        <EmptyContainer>
          <p>No payments for this rider.</p>
        </EmptyContainer>
      )}
      {payments.length > 0 && (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          {payments.map(payment => (
            <PaymentCard key={payment.uuid} payment={payment} />
          ))}
        </ul>
      )}
      {hasManualPayments && (
        <span className="mt-4 text-xs font-normal text-mediumGrey">
          * includes manual payments which are not verified by the system
        </span>
      )}
    </Block>
  )
}
