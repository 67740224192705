import type { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'

export default function getAuthToken(user: CognitoUser | null): Promise<string | null> {
  return new Promise((resolve, _reject) => {
    if (user) {
      user.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err) {
          // eslint-disable-next-line no-console
          console.error(err)
          resolve(null)
        }
        if (session) {
          resolve(session.getIdToken().getJwtToken())
        } else {
          resolve(null)
        }
      })
    }
  })
}
