import axios from 'axios'

import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, Response200, ResultType } from '../types/schema-utils'

import substitutePathParams from './substitutePathParams'

const url = '/api/v1/catch-a-ride/{catchId}'
const method = 'get'

type ApiOperation = Operation<paths, typeof url, typeof method>

export async function landingPageGet(catchId: string, abortSignal?: AbortSignal) {
  const { data } = await axios<Response200<ApiOperation>>({
    url: substitutePathParams(url, { catchId }),
    method,
    signal: abortSignal,
  })
  return data
}

export type LandingPageGetResult = ResultType<typeof landingPageGet>
