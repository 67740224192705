import { twMerge } from 'tailwind-merge'

import type { CustomerSearchResults } from '../../services/customerSearch'
import CarIcon from '../../svg/car-icon'
import CardActionContainer from '../card-action-container'
import CardActionLink from '../card-action-link'

interface Props {
  customer: CustomerSearchResults['results'][number]
}

export default function CustomerSearchHitActions(props: Props) {
  const { customer } = props

  return (
    <CardActionContainer>
      <CardActionLink
        to={`/customers/${customer.uuid}`}
        className={twMerge(
          'rounded-b-lg bg-buttonSecondary text-darkGrey hover:bg-buttonSecondaryHover hover:text-darkGrey',
        )}
      >
        <CarIcon className="text-dark h-5 w-6" aria-hidden="true" />
        Create Quote
      </CardActionLink>
    </CardActionContainer>
  )
}
