import { useEffect, useReducer } from 'react'
import { useLocation } from 'react-router-dom'

import {
  defaultLoginFormState,
  LoginFormActionType,
  LoginFormDispatchContext,
  loginFormReducer,
  LoginFormStateContext,
} from './login-form-context'

interface Props {
  children?: React.ReactNode
}

export default function LoginFormContextProvider(props: Props) {
  const { children } = props
  const { state: locationState } = useLocation()

  const [state, dispatch] = useReducer(loginFormReducer, defaultLoginFormState)

  useEffect(() => {
    dispatch({
      type: LoginFormActionType.SetLoginState,
      payload: {
        successMessage: locationState?.successMessage,
        errorMessage: locationState?.errorMessage,
      },
    })
  }, [locationState])

  return (
    <LoginFormStateContext.Provider value={state}>
      <LoginFormDispatchContext.Provider value={dispatch}>{children}</LoginFormDispatchContext.Provider>
    </LoginFormStateContext.Provider>
  )
}
