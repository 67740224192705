import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/reservations/{rideUuid}'
const method = 'get'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type RideDetailGetInput = PathParams<ApiOperation>

export function rideDetailGet(input: RideDetailGetInput, abortSignal?: AbortSignal) {
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, input),
    method,
    signal: abortSignal,
  })
}

export type RideDetailResult = ResultType<typeof rideDetailGet>

export type RideItineraryItem = RideDetailResult['itinerary'][number]

export type Rider = RideDetailResult['riders'][number]

export type Payment = Rider['payments'][number]

export type PaymentScheduleItem = Rider['paymentSchedule'][number]

export type OverageCharge = RideDetailResult['overages'][number]
