import { DateTime } from 'luxon'
import { twMerge } from 'tailwind-merge'

import type { RideDetailResult } from '../../services/rideDetailGet'
import getDateTimeValue from '../../utils/getDateTimeValue'
import DisplayDate from '../display-date'

type DisplayDateProps = React.ComponentProps<typeof DisplayDate>

interface Props extends Omit<DisplayDateProps, 'date'> {
  startDate: string
  endDate: string
  status?: RideDetailResult['status']
}

export default function RideRelativeDays(props: Props) {
  const { startDate, endDate, className, status, ...otherProps } = props

  const startDateValue = getDateTimeValue(startDate)
  const endDateValue = getDateTimeValue(endDate)

  if (status === 'cancelled') {
    return (
      <span {...otherProps} className={twMerge('text-errorOnLight', className)}>
        event cancelled
      </span>
    )
  }

  if (startDateValue && startDateValue < DateTime.now() && endDateValue && endDateValue > DateTime.now()) {
    return (
      <span {...otherProps} className={twMerge('text-successOnLight', className)}>
        event in progress
      </span>
    )
  }

  return startDateValue && startDateValue < DateTime.now() ? (
    <span {...otherProps} className={twMerge('text-darkGrey', className)}>
      past event
    </span>
  ) : (
    <DisplayDate {...otherProps} date={startDate} relative className={twMerge('text-errorOnLight', className)}>
      {date => <>event {date}</>}
    </DisplayDate>
  )
}
