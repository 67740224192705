import { twMerge } from 'tailwind-merge'

import Block from './block'

type BlockProps = React.ComponentProps<typeof Block> & { as: 'li' }

interface Props extends Omit<BlockProps, 'as'> {
  children: React.ReactNode
  cardActions?: React.ReactNode
}

export default function Card(props: Props) {
  const { children, className, cardActions, ...otherCardProps } = props

  return (
    <Block {...otherCardProps} as="li" className={twMerge('rounded-lg drop-shadow-sm', className)}>
      <div className="flex h-full flex-col justify-between divide-y divide-dividerOnLight">
        <div>{children}</div>
        {!!cardActions && <div>{cardActions}</div>}
      </div>
    </Block>
  )
}
