import type { CustomerSearchResults } from '../../services/customerSearch'
import DisplayDate from '../display-date'
import TableRow from '../table-row'

interface Props {
  customer: CustomerSearchResults['results'][0]
}

export default function CustomerSearchHitInfoTable(props: Props) {
  const { customer } = props
  return (
    <div className="w-full divide-y px-6">
      <TableRow label="Signed Up">
        <DisplayDate date={customer.createdAt} />
      </TableRow>
    </div>
  )
}
