import getCurrentCognitoUser from './getCurrentCognitoUser'

export default async function signOutCognitoUser() {
  const user = await getCurrentCognitoUser()
  if (user) {
    return new Promise<void>(resolve => {
      user.signOut(resolve)
    })
  }
  return Promise.resolve()
}
