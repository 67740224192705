import { type ReactNode, useEffect, useState } from 'react'

import type { DateTime } from 'luxon'
import { useInterval } from 'usehooks-ts'

import formatDateTime from '../utils/formatDateTime'
import getDateTimeValue from '../utils/getDateTimeValue'

export type SpanProps = JSX.IntrinsicElements['span']

interface Props extends Omit<SpanProps, 'children'> {
  date: DateTime | Date | string | undefined | null
  children?: (formattedDate: string) => ReactNode
  options?: Intl.DateTimeFormatOptions
  relative?: boolean
}

function useFormattedDate(
  dateTimeValue: DateTime | undefined,
  relative: boolean | undefined,
  options?: Intl.DateTimeFormatOptions,
) {
  const [value, setValue] = useState<string | null>(null)
  useInterval(() => {
    if (relative) {
      setValue(formatDateTime(dateTimeValue, relative, options))
    }
  }, 60_000)

  useEffect(() => {
    setValue(formatDateTime(dateTimeValue, relative, options))
  }, [dateTimeValue, relative, options])

  return value
}

export default function DisplayDate({ date, options, children, relative, ...spanProps }: Props) {
  const dateTimeValue = getDateTimeValue(date)
  const formattedDate = useFormattedDate(dateTimeValue, relative, options)
  return (
    <span {...spanProps}>
      {formattedDate && typeof children === 'function' ? children(formattedDate) : formattedDate}
    </span>
  )
}
