import type { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export default function EmptyContainer(props: Props) {
  const { children } = props
  return (
    <div className="my-6 w-full rounded-lg border-2 border-dashed border-primary p-12 text-center hover:border-primaryMedium focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
      {children}
    </div>
  )
}
