/**
 * This component is used to show a loading skeleton for table data.
 */
export default function LoadingTable() {
  return (
    <div
      role="status"
      className="w-full animate-pulse space-y-4 divide-y divide-gray-200 rounded border border-formBorderOnLight p-4 shadow dark:divide-grey700 dark:border-grey700 md:p-6"
    >
      <div className="flex items-center justify-between">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-grey300 dark:bg-gray-600" />
          <div className="h-2 w-32 rounded-full bg-gray-200 dark:bg-grey700" />
        </div>
        <div className="h-2.5 w-12 rounded-full bg-grey300 dark:bg-grey700" />
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-grey300 dark:bg-gray-600" />
          <div className="h-2 w-32 rounded-full bg-gray-200 dark:bg-grey700" />
        </div>
        <div className="h-2.5 w-12 rounded-full bg-grey300 dark:bg-grey700" />
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-grey300 dark:bg-gray-600" />
          <div className="h-2 w-32 rounded-full bg-gray-200 dark:bg-grey700" />
        </div>
        <div className="h-2.5 w-12 rounded-full bg-grey300 dark:bg-grey700" />
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-grey300 dark:bg-gray-600" />
          <div className="h-2 w-32 rounded-full bg-gray-200 dark:bg-grey700" />
        </div>
        <div className="h-2.5 w-12 rounded-full bg-grey300 dark:bg-grey700" />
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="mb-2.5 h-2.5 w-24 rounded-full bg-grey300 dark:bg-gray-600" />
          <div className="h-2 w-32 rounded-full bg-gray-200 dark:bg-grey700" />
        </div>
        <div className="h-2.5 w-12 rounded-full bg-grey300 dark:bg-grey700" />
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  )
}
