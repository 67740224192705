import { Fragment, useState } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  CreditCardIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  HomeIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

import { useRideContext } from '../../context/ride-state'
import { RideStateActionType, useRideDispatch } from '../../context/ride-state-reducer'
import type { Rider } from '../../services/rideDetailGet'
import CarIcon from '../../svg/car-icon'
import CircleLogo from '../../svg/circle-logo'

export default function RideDetailSideBar() {
  const { ride, activeRider } = useRideContext()
  const rideDispatch = useRideDispatch()

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const showOverview = !!activeRider === false
  const focusedRider = activeRider

  const handleShowOverview = () => {
    rideDispatch({ type: RideStateActionType.ShowOverview })
    setSidebarOpen(false)
  }

  const handleShowRiderDetails = (rider: Rider) => {
    rideDispatch({ type: RideStateActionType.SetActiveRider, payload: rider })
    setSidebarOpen(false)
  }

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-darkGrey/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center pt-4">
                    <CircleLogo width={48} height={48} desc="Catch Transportation Logo" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          <li>
                            <button
                              type="button"
                              onClick={handleShowOverview}
                              className={twMerge(
                                showOverview
                                  ? 'bg-gray-50 text-primary'
                                  : 'text-darkGrey hover:bg-gray-50 hover:text-primary',
                                'group flex gap-x-3 whitespace-nowrap rounded-md p-2 text-sm font-semibold leading-6',
                              )}
                            >
                              <HomeIcon
                                className={twMerge(
                                  showOverview ? 'text-primary' : 'text-lightGrey group-hover:text-primary',
                                  'h-6 w-6 shrink-0',
                                )}
                                aria-hidden="true"
                              />
                              Ride Overview
                            </button>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className="text-xs font-semibold leading-6 text-lightGrey">Riders</div>
                        <ul className="-mx-2 mt-2 space-y-1">
                          {ride?.riders?.map(rider => (
                            <li key={rider.uuid}>
                              <button
                                type="button"
                                onClick={() => handleShowRiderDetails(rider)}
                                className={twMerge(
                                  rider.uuid === focusedRider?.uuid
                                    ? 'bg-gray-50 text-primary dark:bg-sky-900 dark:text-primaryMedium'
                                    : 'text-grey700 hover:bg-gray-50 hover:text-primary dark:text-white hover:dark:bg-sky-900',
                                  'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                )}
                              >
                                <span
                                  className={twMerge(
                                    rider.uuid === focusedRider?.uuid
                                      ? ' border-primary text-primary dark:border-secondary dark:text-primaryMedium'
                                      : 'border-formBorderOnLight text-lightGrey group-hover:text-primary dark:text-white group-hover:dark:text-white',
                                    'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium dark:bg-sky-950',
                                  )}
                                >
                                  {rider?.initials === '' ? '?' : rider.initials}
                                </span>
                                <span className="truncate text-left">
                                  {rider.fullName}
                                  <RiderIcons rider={rider} />
                                </span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:inset-y-0 lg:z-10 lg:flex lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-formBorderOnLight bg-white px-6 dark:border-transparent dark:bg-sky-950">
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  <li>
                    <button
                      type="button"
                      onClick={handleShowOverview}
                      className={twMerge(
                        showOverview ? 'bg-gray-50 text-primary' : 'text-darkGrey hover:bg-gray-50 hover:text-primary',
                        'group flex gap-x-3 whitespace-nowrap rounded-md p-2 text-sm font-semibold leading-6',
                      )}
                    >
                      <CarIcon
                        className={twMerge(
                          showOverview ? 'text-primary' : 'text-lightGrey group-hover:text-primary',
                          'h-6 w-8 shrink-0',
                        )}
                        aria-hidden="true"
                      />
                      Ride Overview
                    </button>
                  </li>
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-mediumGrey">Riders</div>

                <ul className="-mx-2 mt-2 space-y-1">
                  {ride?.riders?.map(rider => (
                    <li key={rider.uuid}>
                      <button
                        type="button"
                        onClick={() => handleShowRiderDetails(rider)}
                        className={twMerge(
                          rider.uuid === focusedRider?.uuid
                            ? 'bg-gray-50 text-primary'
                            : 'text-grey700 hover:bg-gray-50 hover:text-primary',
                          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                        )}
                      >
                        <span
                          className={twMerge(
                            rider.uuid === focusedRider?.uuid
                              ? ' border-primary text-primary'
                              : 'border-formBorderOnLight text-lightGrey group-hover:text-primary',
                            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium',
                          )}
                        >
                          {rider?.initials === '' ? '?' : rider.initials}
                        </span>
                        <span className="truncate text-left">
                          {rider.fullName}
                          <RiderIcons rider={rider} />
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white p-4 shadow-sm sm:px-6 lg:hidden">
        <button type="button" className="-m-2.5 p-2.5 text-grey700 lg:hidden" onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="inline-block h-6 w-6 dark:text-white" aria-hidden="true" />
          <span className="sm:hidden">&nbsp;Manage Ride</span>
        </button>
      </div>
    </>
  )
}

interface RiderRoleProps {
  rider: Rider
}

function RiderIcons(props: RiderRoleProps) {
  const { rider } = props
  return (
    <div className="flex flex-row flex-nowrap text-sm text-mediumGrey">
      {rider.contractStatus === 'signed' && (
        <DocumentTextIcon className={twMerge('mr-1 h-4 w-4', 'text-successOnLight')} />
      )}
      {rider.isPayingRider && (
        <>
          <CreditCardIcon
            className={twMerge('mr-1 h-4 w-4', rider.hasPaymentMethod ? 'text-successOnLight' : 'text-errorOnLight')}
          />
          <CurrencyDollarIcon className={twMerge('mr-1 h-4 w-4', rider.isFullyPaid && 'text-successOnLight')} />
        </>
      )}
    </div>
  )
}
