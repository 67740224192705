import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, RequestBody, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/quotes/{quoteUuid}/itinerary/{itemUuid}'
const method = 'put'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type QuoteItineraryUpdateInput = PathParams<ApiOperation> & RequestBody<ApiOperation>

export function quoteItineraryUpdate(input: QuoteItineraryUpdateInput) {
  const { quoteUuid, itemUuid, ...body } = input
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, { quoteUuid, itemUuid }),
    method,
    data: body,
  })
}

export type QuoteItineraryUpdateResult = ResultType<typeof quoteItineraryUpdate>
