import type { RideSearchResultItem } from '../../services/rideSearch'

import RideEventDisplay from './ride-event-display'

interface Props {
  ride: RideSearchResultItem
}

export default function RideSearchHitEventDisplay(props: Props) {
  const { ride } = props

  const { eventName, rideDate, rideEndDate, serviceType, rideStatus } = ride

  return (
    <RideEventDisplay
      eventName={eventName}
      startDate={rideDate}
      endDate={rideEndDate}
      serviceType={serviceType}
      status={rideStatus}
    />
  )
}
