import { Fragment, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import { Dialog, Transition } from '@headlessui/react'
import { DateTime } from 'luxon'

import type { QuoteGetResult } from '../../services/quoteGet'
import { quoteSendToCustomer } from '../../services/quoteSendToCustomer'
import CircleLogo from '../../svg/circle-logo'
import addBusinessDays from '../../utils/addBusinessDays'
import createErrorToast from '../../utils/createErrorToast'
import createSuccessToast from '../../utils/createSuccessToast'
import Block from '../block'
import RoundedButton from '../button-rounded'
import DisplayDate from '../display-date'
import FormBlocker from '../form-blocker'
import TextInput from '../text-input'

interface Props {
  quote: QuoteGetResult
  open: boolean
  onComplete: (quote?: QuoteGetResult) => void
}

interface FormData {
  expiresAt: string
  emailOverride?: string
}

export default function QuoteSendModal(props: Props) {
  const { open, onComplete, quote } = props

  const { quoteUuid } = quote

  const { addToast } = useToasts()
  const cancelButtonRef = useRef(null)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      expiresAt: addBusinessDays(DateTime.now(), 3).toFormat('yyyy-MM-dd'),
      emailOverride: quote.customer.email,
    },
  })

  const [submitting, setSubmitting] = useState(false)

  const expiresAt = watch('expiresAt')
  const emailOverride = watch('emailOverride')

  const onSubmit = async () => {
    try {
      setSubmitting(true)
      const sentQuote = await quoteSendToCustomer({ quoteUuid, expiresAt, emailOverride })
      createSuccessToast('Quote sent', addToast)
      onComplete(sentQuote)
    } catch (err) {
      createErrorToast(err, addToast)
    } finally {
      setSubmitting(false)
    }
  }

  const onClose = () => {
    onComplete()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <FormBlocker active={submitting}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <CircleLogo className="mx-auto mb-6" width={100} desc="Catch Transportation Logo" />

                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Ready to send this quote?
                        </Dialog.Title>
                      </div>
                    </div>
                    <Block as="div" className="mt-6 space-y-6 p-6">
                      <TextInput
                        {...register('expiresAt', { required: true })}
                        labelText="Quote Expiration"
                        type="date"
                        labelAccessory={
                          <DisplayDate date={expiresAt} relative className="dark:text-negative text-errorOnLight">
                            {date => <>expires {date}</>}
                          </DisplayDate>
                        }
                        validationMessage={errors.expiresAt?.message}
                      />
                      <TextInput
                        {...register('emailOverride', {
                          pattern: { value: /.+@.+\..+/, message: 'Enter not valid' },
                        })}
                        labelText="Send To"
                        type="email"
                        autoComplete="off"
                        validationMessage={errors?.emailOverride}
                        helpText="Leave blank to send to the customer's email address"
                      />
                    </Block>
                    <div className="mt-5 grid grid-flow-row-dense grid-cols-2 gap-3">
                      <RoundedButton
                        type="button"
                        variant="secondary"
                        size="lg"
                        onClick={onClose}
                        ref={cancelButtonRef}
                      >
                        Close
                      </RoundedButton>
                      <RoundedButton type="submit" variant="accent" size="lg">
                        Send Quote
                      </RoundedButton>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </form>
        </FormBlocker>
      </Dialog>
    </Transition.Root>
  )
}
