import { CalendarDaysIcon } from '@heroicons/react/20/solid'
import { DateTime } from 'luxon'
import { twMerge } from 'tailwind-merge'

import type { RideDetailResult } from '../../services/rideDetailGet'
import DisplayDateRange from '../display-date-range'
import Headline from '../headline'

import RideRelativeDays from './ride-relative-days'

interface Props {
  eventName: string
  startDate: string
  endDate: string
  serviceType: string
  status?: RideDetailResult['status']
}

export default function RideEventDisplay(props: Props) {
  const { eventName, startDate, endDate, serviceType, status } = props

  return (
    <div
      className={twMerge(
        'flex items-center gap-x-2 rounded-t-lg bg-secondaryMedium p-4 font-medium',
        status === 'cancelled' && 'bg-errorLightTint',
        (status === 'completed' || status === 'paidInFull') && 'bg-successLightTint',
      )}
    >
      <CalendarDaysIcon className="mx-2 h-6 w-6 text-formIconsOnLight" aria-hidden="true" />
      <div className="w-full">
        <div>
          <Headline level={2} className="inline-block text-lg md:text-lg">
            {eventName}
          </Headline>{' '}
          <span className="text-mediumGrey">({serviceType})</span>
        </div>
        <div className="flex justify-between text-sm">
          <DisplayDateRange
            className="text-tertiary"
            startDate={startDate}
            startOptions={{ weekday: 'short', month: 'short', day: 'numeric' }}
            endDate={endDate}
            endOptions={DateTime.DATE_MED_WITH_WEEKDAY}
            junction=" thru "
          />
          <RideRelativeDays startDate={startDate} endDate={endDate} status={status} />
        </div>
      </div>
    </div>
  )
}
