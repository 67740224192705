import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/reservations/{rideUuid}/itinerary/{itemUuid}'
const method = 'delete'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type RideItineraryDeleteInput = PathParams<ApiOperation>

export function rideItineraryDelete(input: RideItineraryDeleteInput) {
  const { rideUuid, itemUuid } = input
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, { rideUuid, itemUuid }),
    method,
  })
}

export type RideItineraryDeleteResult = ResultType<typeof rideItineraryDelete>
