import { createContext, useContext } from 'react'

import type { Payment, RideDetailResult, Rider } from '../services/rideDetailGet'

export interface RideState {
  ride: RideDetailResult
  activeRider?: Rider
  activePayment?: Payment
  editMode: 'edit' | 'view'
}

export const defaultRideState: RideState = {
  ride: undefined as never,
  activeRider: undefined,
  activePayment: undefined,
  editMode: 'view',
}

export const RideStateContext = createContext<RideState>(defaultRideState)

export const useRideContext = (): Readonly<RideState> => useContext(RideStateContext)
