import Headline from '../headline'

import { useLoginContext } from './login-form-context'

export default function FormTitle() {
  const { formTitle } = useLoginContext()
  return (
    <Headline level={2} className="mt-8">
      {formTitle}
    </Headline>
  )
}
