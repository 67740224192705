import type { ReactNode } from 'react'

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import { twMerge } from 'tailwind-merge'

import type { SemanticColor } from '../types/semantic-color'

interface Props {
  message: ReactNode
  truncate?: boolean
  variant: SemanticColor
  onCloseClick?: JSX.IntrinsicElements['button']['onClick']
}

function getPalette(semanticColor: SemanticColor) {
  switch (semanticColor) {
    case 'negative':
      return {
        bg: 'bg-negative',
        accent: 'bg-negative-800',
        hover: 'hover:bg-negative-600',
        text: 'text-light-200',
      }
    case 'positive':
      return {
        bg: 'bg-positive',
        accent: 'bg-positive-800',
        hover: 'hover:bg-positive-600',
        text: 'text-light-200',
      }
    case 'warning':
      return {
        bg: 'bg-amber',
        accent: 'bg-amber-800',
        hover: 'hover:bg-amber-600',
        text: 'text-light-100',
      }
    case 'secondary':
      return {
        bg: 'bg-secondary',
        accent: 'bg-secondary-800',
        hover: 'hover:bg-secondary-600',
        text: 'text-dark-800',
      }
    case 'light':
      return {
        bg: 'bg-sectionBackground',
        accent: 'text-formBorderOnLight',
        hover: 'hover:bg-light-600',
        text: 'text-paragraphText',
      }
    case 'dark':
      return {
        bg: 'bg-formBackgroundOnDark',
        accent: 'bg-formBorderOnDark',
        hover: 'hover:bg-formBorderBottomOnDark',
        text: 'text-formTextFocusedOnDark',
      }
    default:
      return {
        bg: 'bg-primary',
        accent: 'bg-primaryLight',
        hover: 'hover:bg-primaryMedium',
        text: 'text-buttonPrimaryText',
      }
  }
}

function getIcon(semanticColor: SemanticColor) {
  switch (semanticColor) {
    case 'negative':
      return <ExclamationCircleIcon className="h-6 w-6 text-white " aria-hidden="true" />
    case 'positive':
      return <CheckCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
    case 'warning':
      return <ExclamationTriangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
    default:
      return <InformationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
  }
}

export default function MessageBanner(props: Props) {
  const { message, onCloseClick, truncate, variant } = props

  const icon = getIcon(variant)
  const { bg, accent, text, hover } = getPalette(variant)

  return (
    <div className="py-3">
      <div className={twMerge('rounded-lg  p-2 shadow-lg sm:p-3', bg)}>
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-0 flex-1 items-center">
            <span className={twMerge('flex rounded-lg  p-2', accent)}>{icon}</span>
            <p className={twMerge('ml-3 font-medium ', text, truncate && 'truncate')}>{message}</p>
          </div>

          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
            <button
              type="button"
              onClick={onCloseClick}
              className={twMerge('-mr-1 flex rounded-md p-2  focus:outline-none focus:ring-2 focus:ring-white', hover)}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
