import { useState } from 'react'

import type { RideDetailResult } from '../../services/rideDetailGet'
import RoundedButton from '../button-rounded'

import CancelRideModal from './cancel-ride-modal'

interface Props {
  ride: RideDetailResult
}

export default function RideManager(props: Props) {
  const { ride } = props
  const { status } = ride

  const [cancelRideModalOpen, setCancelRideModalOpen] = useState(false)

  const canBeCancelled = status !== 'cancelled' && status !== 'completed'

  const closeCancelRideModal = () => {
    setCancelRideModalOpen(false)
  }

  const openCancelRideModal = () => {
    setCancelRideModalOpen(true)
  }

  return (
    <div>
      {canBeCancelled && (
        <>
          <CancelRideModal open={cancelRideModalOpen} onComplete={closeCancelRideModal} ride={ride} />
          <div className="mt-6 flex justify-center p-6">
            <RoundedButton onClick={openCancelRideModal} size="lg" className="px-12" variant="negative">
              Cancel Ride
            </RoundedButton>
          </div>
        </>
      )}
    </div>
  )
}
