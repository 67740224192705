import type { QuoteSearchItem } from '../../services/quoteSearch'
import Card from '../card'

import QuoteSearchHitActions from './quote-search-hit-actions'
import QuoteSearchHitEventDisplay from './quote-search-hit-event-display'
import QuoteSearchHitInfoTable from './quote-search-hit-info-table'

interface Props {
  quote: QuoteSearchItem
}

export default function QuoteSearchHit(props: Props) {
  const { quote } = props

  const actionButtons = <QuoteSearchHitActions quote={quote} />

  return (
    <Card cardActions={actionButtons}>
      <div>
        <QuoteSearchHitEventDisplay quote={quote} />
        <QuoteSearchHitInfoTable quote={quote} />
      </div>
    </Card>
  )
}
