import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, RequestBody, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/reservations/{rideUuid}/itinerary/{itemUuid}'
const method = 'put'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type RideItineraryUpdateInput = PathParams<ApiOperation> & RequestBody<ApiOperation>

export function rideItineraryUpdate(input: RideItineraryUpdateInput) {
  const { rideUuid, itemUuid, ...body } = input
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, { rideUuid, itemUuid }),
    method,
    data: body,
  })
}

export type RideItineraryUpdateResult = ResultType<typeof rideItineraryUpdate>
