type SpanProps = JSX.IntrinsicElements['span']

interface Props extends SpanProps {
  amount: number | undefined
  locale?: string
  options?: Intl.NumberFormatOptions
}

export default function DisplayMoney({ amount, options, locale, ...spanProps }: Props) {
  const numberFormat = new Intl.NumberFormat(locale || 'en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
    ...options,
  })

  return <span {...spanProps}>{amount && numberFormat.format(amount)}</span>
}
