import { twMerge } from 'tailwind-merge'

import type { QuoteGetResult } from '../../services/quoteGet'

type QuoteStatus = QuoteGetResult['quoteStatus']

interface Props {
  quoteStatus: QuoteStatus
}

export default function DisplayQuoteStatus(props: Props) {
  const { quoteStatus } = props

  const className = getStatusClass(quoteStatus)
  const text = getText(quoteStatus)
  return (
    <div className={twMerge('flex h-full flex-row items-center capitalize', className)}>
      <div>{text}</div>
    </div>
  )
}

function getStatusClass(status: QuoteStatus) {
  switch (status) {
    case 'sent':
    case 'booked':
      return 'text-successOnLight'
    case 'notYet':
    case 'viewed':
      return 'text-primary'
    case 'cancelled':
    case 'declined':
      return 'text-errorOnLight'
    default:
      return 'text-mediumGrey'
  }
}

function getText(quoteStatus: QuoteStatus) {
  switch (quoteStatus) {
    case 'notYet':
      return 'Not Yet'
    default:
      return quoteStatus
  }
}
