import type { ErrorBoundaryPropsWithRender } from 'react-error-boundary'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { ToastProvider } from 'react-toast-notifications'

import { useAppContext } from '../context/app-state'
import shouldUseDarkMode from '../utils/shouldUseDarkMode'

import { disableDarkMode } from './flags'
import Footer from './footer'
import NavHeader from './nav-header'
import SiteNav from './site-nav'

interface Props {
  children?: React.ReactNode
}

const fallbackRender: ErrorBoundaryPropsWithRender['fallbackRender'] = ({ error }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre className="text-red-600">{error.message}</pre>
    </div>
  )
}

export default function DashboardLayout(props: Props) {
  const { children } = props
  const { colorTheme: colorMode } = useAppContext()

  const isDarkMode = disableDarkMode ? false : shouldUseDarkMode(colorMode) // useMemo(() => shouldUseDarkMode(colorMode), [colorMode])

  return (
    <>
      <Helmet
        title="Catch Admin"
        titleTemplate="%s | Catch Transport Admin"
        htmlAttributes={{ class: 'h-full', 'data-mode': isDarkMode ? 'dark' : undefined }}
        bodyAttributes={{ class: 'h-full bg-bodyBackground dark:bg-formBackgroundOnDark' }}
      />
      <div className="flex min-h-screen flex-col bg-backgroundPrimaryUI dark:bg-formBackgroundOnDark">
        <div className="flex flex-grow flex-col">
          <div className="bg-primary pb-32">
            <SiteNav />
            <NavHeader />
          </div>
          <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col">
            <ToastProvider>
              <ErrorBoundary fallbackRender={fallbackRender}>
                <main className="-mt-32 flex-grow rounded-t-xl bg-bodyBackground dark:bg-formBackgroundOnDark md:p-6 lg:p-8">
                  {children}
                </main>
              </ErrorBoundary>
            </ToastProvider>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
