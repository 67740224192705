import { useEffect, useRef, useState } from 'react'
import type { ComponentProps } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'

import { ArrowLeftCircleIcon, ArrowPathRoundedSquareIcon } from '@heroicons/react/20/solid'
import type { CognitoUser } from 'amazon-cognito-identity-js'

import RoundedButton from '../components/button-rounded'
import LoadingContent from '../components/loading-content'
import { LoginFormActionType, useLoginFormDispatch } from '../components/login/login-form-context'
import NavigationLink from '../components/navigation-link'
import TextInput from '../components/text-input'
import createCognitoUser from '../services/cognito/createCognitoUser'

export default function PasswordConfirmForgotPage() {
  const { state: locationState } = useLocation()
  const loginFormDispatch = useLoginFormDispatch()
  const navigate = useNavigate()
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)
  const [sendCodeDisabled] = useState(true)

  const otpRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const username = locationState?.username

  useEffect(() => {
    createCognitoUser(username).then(setCognitoUser)
  }, [username])

  useEffect(() => {
    loginFormDispatch({ type: LoginFormActionType.SetLoginState, payload: { formTitle: 'Forgot Password' } })
  }, [loginFormDispatch])

  if (!username) {
    navigate('/login')
    return null
  }

  if (!cognitoUser) {
    return <LoadingContent />
  }

  const resendCode: ComponentProps<typeof NavigationLink>['onClick'] = e => {
    e.preventDefault()

    if (sendCodeDisabled) {
      return
    }

    cognitoUser.resendConfirmationCode(err => {
      if (err) {
        loginFormDispatch({
          type: LoginFormActionType.SetLoginState,
          payload: {
            errorMessage: err.message,
          },
        })
      }
    })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const code = otpRef.current?.value
    const newPassword = passwordRef.current?.value

    if (!code || !newPassword) {
      return
    }

    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess() {
        navigate('/login', { state: { successMessage: 'Password changed successfully' } })
      },
      onFailure(err) {
        loginFormDispatch({
          type: LoginFormActionType.SetLoginState,
          payload: {
            errorMessage: err.message,
          },
        })
      },
    })
  }

  return (
    <>
      <Helmet title="Forgot Password" />
      <form className="space-y-6" onSubmit={onSubmit}>
        <input type="hidden" name="username" defaultValue={username} autoComplete="email" />
        <TextInput
          ref={otpRef}
          labelText="Enter Code"
          labelAccessory={
            <NavigationLink
              to="#"
              disabled={sendCodeDisabled}
              onClick={resendCode}
              className="inline-flex items-center gap-x-1.5"
            >
              <ArrowPathRoundedSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Resend Code
            </NavigationLink>
          }
          id="otp"
          name="otp"
          type="otp"
          autoComplete="one-time-code"
          placeholder="000000"
          required
        />

        <TextInput
          ref={passwordRef}
          labelText="New Password"
          id="password"
          name="password"
          type="password"
          autoComplete="new-password"
          required
        />

        <div>
          <RoundedButton type="submit" className="w-full" size="xl" variant="accent">
            Reset Password
          </RoundedButton>
        </div>
        <div className="mt-6 flex flex-row justify-between">
          <NavigationLink to="/login" className="inline-flex items-center gap-x-1.5" variant="accent">
            <ArrowLeftCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> Back to Login
          </NavigationLink>
        </div>
      </form>
    </>
  )
}
