/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ClientMetadata, CognitoUser } from 'amazon-cognito-identity-js'

import type CognitoAuthResponse from './cognito-auth-response'
import promisifyAuthCallbacks from './promisifyAuthCallback'

export default async function completeCognitoNewPasswordChallenge(
  user: CognitoUser,
  newPassword: string,
  requiredAttributeData?: any,
  clientMetaData?: ClientMetadata,
) {
  return new Promise<CognitoAuthResponse>((resolve, reject) => {
    user.completeNewPasswordChallenge(
      newPassword,
      requiredAttributeData ?? {},
      promisifyAuthCallbacks(user, resolve, reject),
      clientMetaData,
    )
  })
}
