import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, RequestBody, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/reservations/{rideUuid}/itinerary'
const method = 'post'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type RideItineraryCreateInput = PathParams<ApiOperation> & RequestBody<ApiOperation>

export function rideItineraryCreate(input: RideItineraryCreateInput) {
  const { rideUuid, ...body } = input
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, { rideUuid }),
    method,
    data: body,
  })
}

export type RideItineraryCreateResult = ResultType<typeof rideItineraryCreate>
