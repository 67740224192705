import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import { UserIcon, UserPlusIcon, UsersIcon } from '@heroicons/react/20/solid'
import { twMerge } from 'tailwind-merge'

import Block from '../components/block'
import RoundedButton from '../components/button-rounded'
import LoadingTable from '../components/loading-table'
import NavigationLink from '../components/navigation-link'
import SelectList from '../components/select-list'
import Tabs from '../components/tabs'
import TextInput from '../components/text-input'
import UserSearchHit from '../components/users/user-search-hit'
import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'
import type { UserSearchInput, UserSearchResults } from '../services/userSearch'
import userSearch from '../services/userSearch'
import CarIcon from '../svg/car-icon'
import type { SelectListOption } from '../types/select-list-option'
import type { TabItem } from '../types/tab-item'
import createErrorToast from '../utils/createErrorToast'

const searchTabs: TabItem[] = [
  {
    id: 'adminsOnly' as const,
    label: ({ selected }) => (
      <div className="flex items-center p-6 sm:py-0 md:items-end">
        <UserIcon
          className={twMerge(
            selected ? 'text-buttonPrimaryText' : 'text-lightGrey group-hover:text-grey500',
            '-ml-0.5 mr-2 h-5 w-5',
          )}
          aria-hidden="true"
        />
        <span>Admins</span>
      </div>
    ),
  },
  {
    id: 'ridersOnly' as const,
    label: ({ selected }) => (
      <div className="flex items-center p-6 sm:py-0 md:items-end">
        <CarIcon
          className={twMerge(
            selected ? 'text-buttonPrimaryText' : 'text-lightGrey group-hover:text-grey500',
            '-ml-0.5 mr-2 h-5 w-6',
          )}
          aria-hidden="true"
        />
        <span>Riders</span>
      </div>
    ),
  },
  {
    id: 'search' as const,
    label: ({ selected }) => (
      <div className="flex items-center p-6 sm:py-0 md:items-end">
        <UsersIcon
          className={twMerge(
            selected ? 'text-buttonPrimaryText' : 'text-lightGrey group-hover:text-grey500',
            '-ml-0.5 mr-2 h-5 w-5',
          )}
          aria-hidden="true"
        />
        <span>Search</span>
      </div>
    ),
  },
]

type UserSearchType = NonNullable<UserSearchInput['searchType']>

const searchTypes: SelectListOption<UserSearchType>[] = [
  { label: 'Phone Number', value: 'phone' },
  { label: 'Email', value: 'email' },
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
]

interface FormData {
  searchType: string
  searchValue: string
}

export default function UsersPage() {
  const dispatch = useAppDispatch()
  const { addToast } = useToasts()

  const [searchResults, setSearchResults] = useState<UserSearchResults | undefined>()
  const [searching, setSearching] = useState(false)
  const [placeholderText, setPlaceholderText] = useState('')
  const [selectedTab, setSelectedTab] = useState(searchTabs[0])
  const [searchFilter, setSearchFilter] = useState<UserSearchInput | undefined>()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>()

  const selectedSearchType = watch('searchType')

  useEffect(() => {
    dispatch({ type: AppStateActionType.SetNavTitle, payload: 'Users' })
    dispatch({
      type: AppStateActionType.SetActionPanel,
      payload: (
        <NavigationLink as="button" to="users/create" variant="accent">
          <UserPlusIcon className="mx-2 h-6 w-6" />
          Create Admin User
        </NavigationLink>
      ),
    })
  }, [dispatch])

  useEffect(() => {
    const abortController = new AbortController()
    if (searchFilter !== undefined) {
      setSearching(true)
      setSearchResults(undefined)

      userSearch(searchFilter, abortController.signal)
        .then(setSearchResults)
        .catch(error => {
          createErrorToast(error, addToast)
        })
        .finally(() => setSearching(false))
    }
    return () => {
      abortController.abort()
    }
  }, [addToast, searchFilter])

  useEffect(() => {
    switch (selectedSearchType) {
      case 'email':
        setPlaceholderText('user@company.com')
        break
      case 'phone':
        setPlaceholderText('(555) 555-1212')
        break
      case 'firstName':
      case 'lastName':
        setPlaceholderText('name')
        break
      default:
        setPlaceholderText('')
        break
    }
  }, [clearErrors, selectedSearchType])

  const onSearchFormSubmit = (formData: FormData) => {
    const { searchType, searchValue } = formData
    setSearchResults(undefined)
    switch (searchType) {
      case 'email':
      case 'phone':
      case 'firstName':
      case 'lastName':
        setSearchFilter({ searchType, searchValue })
        break
      default:
        setSearchFilter(undefined)
        break
    }
  }

  useEffect(() => {
    clearErrors()
    setSearchResults(undefined)
    setSearchFilter(undefined)
    switch (selectedTab.id) {
      case 'adminsOnly':
      case 'ridersOnly':
        setSearchFilter({ searchType: selectedTab.id })
        break
      default:
        clearErrors()
        setValue('searchType', 'phone')
        setValue('searchValue', '')
        break
    }
  }, [clearErrors, selectedTab.id, setValue])

  const hits = searchResults?.results ?? []

  const enableUserSearching = false

  return (
    <>
      {enableUserSearching && <Tabs tabs={searchTabs} onTabChange={setSelectedTab} />}
      {selectedTab.id === 'search' && (
        <form onSubmit={handleSubmit(onSearchFormSubmit)} noValidate>
          <Block
            as="div"
            className="flex flex-col space-y-3 p-4 lg:flex-row lg:items-start lg:justify-between lg:space-x-12 lg:space-y-0"
          >
            <SelectList
              {...register('searchType')}
              labelText="Search By"
              defaultValue={selectedSearchType}
              value={selectedSearchType}
              options={searchTypes}
              validationMessage={errors?.searchType}
            />
            <TextInput
              {...register('searchValue', { required: true })}
              labelText="Search For"
              placeholder={placeholderText}
              validationMessage={errors?.searchValue}
            />
            <RoundedButton type="submit" variant="secondary" className="mt-6 px-20 lg:mt-0 lg:place-self-end">
              Search
            </RoundedButton>
          </Block>
        </form>
      )}
      <div className="-mx-4 mt-8 sm:-mx-0" />
      {searching && <LoadingTable />}
      {!searching && hits.length > 0 && (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          {hits.map(user => (
            <UserSearchHit key={user.id} user={user} />
          ))}
        </ul>
      )}
    </>
  )
}
