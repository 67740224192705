import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, RequestBody, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'

const url = '/api/v1/users/create-user'
const method = 'post'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type UserCreateInput = RequestBody<ApiOperation>

export function userCreate(input: UserCreateInput) {
  return sendRequest<ApiOperation>({
    url,
    method,
    data: input,
  })
}

export type UserCreateResult = ResultType<typeof userCreate>
