import { DateTime } from 'luxon'

export default function getDateTimeValue(value: DateTime | Date | string | undefined | null): DateTime | undefined {
  if (typeof value === 'string') {
    return DateTime.fromISO(value)
  }
  if (value instanceof Date && !Number.isNaN(value.getTime())) {
    return DateTime.fromJSDate(value)
  }
  if (DateTime.isDateTime(value)) {
    return value
  }
  return undefined
}
