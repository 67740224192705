import type { Config } from 'tailwindcss'

const colors = {
  backgroundPrimary: '#FFFFFF',
  backgroundPrimaryUI: '#F4F4F5',
  backgroundSecondary: '#F5F5F5',
  backgroundUISection: '#21ADDF',
  modalBackground: 'rgba(221, 221, 221, 0.33)',
  loginBackground: 'rgba(33, 173, 223, 0.65)',
  white: '#FFF',
  black: '#000',
  bodyBackground: '#FCFCFC',
  grey100: '#F4F4F4',
  grey200: '#D8DADD',
  grey300: '#6B7280',
  grey500: '#6B7280',
  grey700: '#374151',
  lightGrey: '#E7EBF3',
  mediumGrey: '#6D7B8B',
  darkGrey: '#353541',
  primary: '#21ADDF',
  primaryLight: '#E9F7FC',
  primaryMedium: '#90D6EF',
  secondary: '#F5DF31',
  secondaryLight: '#FDF9D6',
  secondaryMedium: '#FAEF98',
  tertiary: '#58B6D2',
  tertiaryLight: '#DEF0F6',
  tertiaryMedium: '#9BD3E4',
  sectionBackground: '#f8f9fb',
  mainHeadline: '#2E2E32',
  paragraphText: 'rgba(72, 68, 70, 1)',
  buttonPrimary: 'rgba(33, 173, 223, 1)',
  buttonSecondary: 'rgba(245, 223, 49, 1)',
  buttonTertiary: '#FFFFFF',
  buttonPrimaryHover: 'rgba(41, 194, 249, 1)',
  buttonSecondaryHover: 'rgba(255, 236, 83, 1)',
  buttonTertiaryHover: '#F9FAFB',
  buttonPrimaryText: '#FFFFFF',
  buttonSecondaryText: '#00833E',
  buttonFocus: '#0F773C',
  buttonDisabled: '#CBD2CC',
  formBackgroundOnLight: '#FAFAFA',
  formBackgroundOnDark: '#021137',
  formBorderOnLight: '#C7D0DD',
  formBorderOnDark: '#1E1E2F',
  formBorderBottomOnLight: '#9CA3AF',
  formBorderBottomOnDark: '#1E1E2F',
  formIconsOnLight: '#AAAFB7',
  formTextFocusedOnLight: '#475162',
  formTextFocusedOnDark: '#F5F5F5',
  formPlaceHolderOnLight: '#798599',
  formPlaceHolderOnDark: '#727A86',
  placeholder: '#798599',
  errorOnLight: '#871924',
  errorOnDark: '#F58087',
  errorLightTint: '#FCD5D7',
  successOnDark: '#AADF7A',
  successOnLight: '#5B9F1C',
  successLightTint: '#DFFBC6',
  dividerOnLight: '#C7D0DD',
  dividerOnDark: '#F5DF31',
  textLink: '#1F93BD',
  textLinkHover: '#082D78',
  transparent: 'transparent',
} satisfies Config['Theme']['colors']

export default colors
