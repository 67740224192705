import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { useRideContext } from '../../context/ride-state'
import { RideStateActionType, useRideDispatch } from '../../context/ride-state-reducer'
import { rideManualPaymentSubmit } from '../../services/rideManualPaymentSubmit'
import createErrorToast from '../../utils/createErrorToast'
import RoundedButton from '../button-rounded'
import FormBlocker from '../form-blocker'
import TextInput from '../text-input'

interface FormData {
  notes: string
}

export default function RiderManualPayment() {
  const rideDispatch = useRideDispatch()
  const { ride, activePayment, activeRider } = useRideContext()
  const { addToast } = useToasts()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>()

  const [submitting, setSubmitting] = useState(false)

  const { uuid: rideUuid } = ride
  const { uuid: riderUuid } = activeRider ?? {}
  const { uuid: paymentUuid } = activePayment ?? {}

  const closeDrawer = () => {
    rideDispatch({ type: RideStateActionType.SetActivePayment, payload: undefined })
  }

  const onSubmit = (data: FormData) => {
    const { notes } = data

    if (!riderUuid || !paymentUuid) {
      return
    }

    setSubmitting(true)

    rideManualPaymentSubmit({
      rideUuid,
      riderUuid,
      paymentUuid,
      notes,
    })
      .then(updatedRide => {
        rideDispatch({ type: RideStateActionType.SetRide, payload: updatedRide })
        reset()
        closeDrawer()
      })
      .catch(err => {
        createErrorToast(err, addToast)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const open = !!activePayment

  if (!open) {
    return null
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeDrawer}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-base font-semibold leading-6 text-darkGrey">
                            Mark <span className="text-primary">{activePayment.description}</span> as Paid
                          </Dialog.Title>
                        </div>
                        <div className="flex h-7 items-center">
                          <button type="button" className="text-lightGrey hover:text-grey500" onClick={closeDrawer}>
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Content */}
                    <FormBlocker active={submitting}>
                      <form
                        className="flex flex-1 flex-col space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0"
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                      >
                        {/* Form Fields */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-1 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <TextInput
                              {...register('notes', {
                                required: {
                                  value: true,
                                  message: 'Please add any notes relating to this manual payment',
                                },
                              })}
                              labelText="Notes"
                              className="mb-8"
                              placeholder="Add check number, confirmation number, amount received, etc..."
                              multiline
                              rows={5}
                              validationMessage={errors.notes?.message}
                            />
                          </div>
                        </div>
                        {/* Action buttons */}
                        <div className="flex-shrink-0 border-t border-formBorderOnLight px-4 py-5 sm:px-6">
                          <div className="flex justify-end space-x-3">
                            <RoundedButton variant="secondary" className="px-10" onClick={closeDrawer}>
                              Cancel
                            </RoundedButton>
                            <RoundedButton variant="positive" className="px-10" type="submit">
                              Submit
                            </RoundedButton>
                          </div>
                        </div>
                      </form>
                    </FormBlocker>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
