import type { UserSearchResults } from '../../services/userSearch'
import Card from '../card'
import DisplayUser from '../shared/display-user'

interface Props {
  user: UserSearchResults['results'][number]
}

export default function UserSearchHit(props: Props) {
  const { user } = props

  return (
    <Card key={user.id}>
      <div className="p-6">
        <DisplayUser user={user} role={user.isAdmin ? 'admin' : 'rider'} />
      </div>
    </Card>
  )
}
