import React, { useState } from 'react'

import { BookmarkIcon, HomeIcon, MapPinIcon, PlusCircleIcon, QueueListIcon } from '@heroicons/react/20/solid'
import { DateTime } from 'luxon'

import type { ItineraryItem } from '../../services/types'
import getDateTimeValue from '../../utils/getDateTimeValue'
import Block from '../block'
import RoundedButton from '../button-rounded'
import Headline from '../headline'
import ItineraryEdit from '../itinerary-edit'
import ItineraryLocation from '../itinerary-item'

type RideItineraryProps = {
  itinerary?: ItineraryItem[]
  drawerOpen: boolean
  setDrawerOpen: (open: boolean) => void
  updateItineraryItem: (item: ItineraryItem) => void
  deleteItineraryItem: (itemUuid: string) => void
  editable?: boolean
}

export default function RideItinerary(props: RideItineraryProps) {
  const [itemToEdit, setItemToEdit] = useState<ItineraryItem | null>(null)

  const { itinerary, drawerOpen, setDrawerOpen, updateItineraryItem, deleteItineraryItem, editable = true } = props

  const hasItineraryItems = !!itinerary?.length

  const getNewItineraryDate = () => {
    const itineraryItems: number = itinerary?.length ?? 0

    if (itineraryItems) {
      return itinerary?.at(-1)?.date
    }

    return ''
  }

  const editItineraryItem = (itineraryItem: ItineraryItem) => {
    setItemToEdit({
      ...itineraryItem,
      time: getDateTimeValue(itineraryItem.time)?.toLocaleString(DateTime.TIME_24_SIMPLE) || '',
    })
    setDrawerOpen(true)
  }

  const closeDrawer = () => {
    setDrawerOpen(false)
  }

  const addStopClick = () => {
    const newItinerary = {
      uuid: '',
      time: '',
      date: getNewItineraryDate() ?? '',
      location: '',
    }

    setItemToEdit(newItinerary)
    setDrawerOpen(true)
  }

  return (
    <Block as="div" className="mt-3 p-6">
      <div className="flex flex-row justify-between">
        <Headline level={3} className="inline-flex items-center gap-x-1.5">
          <QueueListIcon className="-ml-0.5 h-5 w-5 text-formIconsOnLight" aria-hidden="true" /> Ride Itinerary
        </Headline>
        {editable && (
          <RoundedButton
            type="button"
            className="inline-flex w-16 items-center gap-x-1.5 lg:w-36"
            onClick={addStopClick}
          >
            <PlusCircleIcon className="text-light-100 -ml-0.5 h-5 w-5" aria-hidden="true" />
            <span className="sr-only lg:not-sr-only">Add</span>
          </RoundedButton>
        )}
      </div>
      {itemToEdit && (
        <ItineraryEdit
          itineraryItem={itemToEdit}
          updateItineraryItem={updateItineraryItem}
          open={drawerOpen}
          onClose={closeDrawer}
        />
      )}
      <ul className="mt-4 flex list-none flex-col">
        {itinerary && hasItineraryItems ? (
          itinerary.map((item, i, items) => {
            return (
              <li key={item.uuid} className="flex flex-row items-center border-t-2 border-darkGrey/10 pl-4">
                {i === 0 && (
                  <div className="mr-4 flex w-16 flex-col items-center">
                    <MapPinIcon className="h-5 w-5 text-formIconsOnLight" aria-hidden="true" />
                    <span className="block whitespace-nowrap text-xs font-medium text-primary">Pickup</span>
                  </div>
                )}
                {i !== 0 && i !== items.length - 1 && (
                  <div className="mr-4 flex w-16 flex-col items-center">
                    <BookmarkIcon className="h-5 w-5 text-formIconsOnLight" aria-hidden="true" />
                    <span className="block whitespace-nowrap text-xs font-medium text-primary">Stop # {i}</span>
                  </div>
                )}
                {i !== 0 && i === items.length - 1 && (
                  <div className="mr-4 flex w-16 flex-col items-center">
                    <HomeIcon className="h-5 w-5 text-formIconsOnLight" aria-hidden="true" />
                    <span className="block whitespace-nowrap text-xs font-medium text-primary">Drop-off</span>
                  </div>
                )}
                <ItineraryLocation
                  className="w-full"
                  itineraryItem={item}
                  onDelete={deleteItineraryItem}
                  onEdit={editItineraryItem}
                  editable={editable}
                />
              </li>
            )
          })
        ) : (
          <div className="flex flex-col items-center justify-center p-4">
            <div className="text-center text-paragraphText">
              <p className="mt-2 text-sm font-medium">No itinerary items</p>
              <RoundedButton
                type="button"
                className="my-6 inline-flex items-center gap-x-1.5 px-6"
                onClick={addStopClick}
              >
                <PlusCircleIcon className="-ml-0.5 h-5 w-5 text-buttonPrimaryText" aria-hidden="true" />
                Add Location
              </RoundedButton>
            </div>
          </div>
        )}
      </ul>
    </Block>
  )
}
