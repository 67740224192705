import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import RoundedButton from '../components/button-rounded'
import { LoginFormActionType, useLoginFormDispatch } from '../components/login/login-form-context'
import NavigationLink from '../components/navigation-link'
import TextInput from '../components/text-input'
import authenticateCognitoUser from '../services/cognito/authenticateCognitoUser'
import getErrorMessage from '../utils/getErrorMessage'

interface FormData {
  username: string
  password: string
}

export default function PasswordLoginPage() {
  const loginFormDispatch = useLoginFormDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>()

  const [email] = watch(['username'])

  useEffect(() => {
    if (!email && searchParams.get('email')) {
      setValue('username', searchParams.get('email') || '')
    }
  }, [email, searchParams, setValue])

  useEffect(() => {
    loginFormDispatch({ type: LoginFormActionType.SetLoginState, payload: { formTitle: 'Sign in to your account' } })
  }, [loginFormDispatch])

  const returnUrl = searchParams.get('returnUrl') || '/'

  const onSubmit = (data: FormData) => {
    const { username, password } = data

    authenticateCognitoUser(username, password)
      .then(({ type }) => {
        switch (type) {
          case 'success': {
            navigate(returnUrl)
            break
          }
          case 'newPasswordRequired': {
            navigate('/change-password', { state: { username, password, returnUrl: returnUrl || '/' } })
            break
          }
          default: {
            loginFormDispatch({
              type: LoginFormActionType.SetLoginState,
              payload: { errorMessage: `Unexpected result type ${type}` },
            })
            break
          }
        }
      })
      .catch(err => {
        loginFormDispatch({
          type: LoginFormActionType.SetLoginState,
          payload: { errorMessage: getErrorMessage(err) },
        })
      })
  }

  return (
    <>
      <Helmet title="Sign in" />
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('username', {
            required: { value: true, message: 'Email is required' },
            pattern: { value: /.+@.+\..+/, message: 'Enter not valid' },
          })}
          labelText="Email"
          type="email"
          autoComplete="email"
          placeholder="Enter your email address"
          size="lg"
          validationMessage={errors?.username}
        />
        <TextInput
          {...register('password', { required: { value: true, message: 'Password is required' } })}
          labelText="Password"
          labelAccessory={
            <NavigationLink
              to={{
                pathname: '/forgot-password',
                search: email ? new URLSearchParams({ email }).toString() : undefined,
              }}
              variant="accent"
            >
              Forgot your password?
            </NavigationLink>
          }
          type="password"
          autoComplete="current-password"
          size="lg"
          validationMessage={errors?.password}
        />
        <div>
          <RoundedButton type="submit" variant="accent" size="xl" className="w-full">
            Sign In
          </RoundedButton>
        </div>
      </form>
    </>
  )
}
