import { CheckCircleIcon, FlagIcon } from '@heroicons/react/20/solid'
import { ClockIcon } from '@heroicons/react/24/outline'

import type { RideSearchResultItem } from '../../services/rideSearch'
import type { SemanticColor } from '../../types/semantic-color'

type RideStatus = RideSearchResultItem['rideStatus']

interface Props {
  rideStatus: RideStatus
}

export default function DisplayRideStatus(props: Props) {
  const { rideStatus } = props

  const { variant, text } = getProps(rideStatus)

  switch (variant) {
    case 'negative':
      return (
        <span className="flex items-center text-errorOnLight">
          <FlagIcon className="mr-1.5 inline-block h-6 w-6" aria-hidden="true" />
          {text}
        </span>
      )

    case 'positive':
      return (
        <span className="flex  items-center text-successOnLight">
          <CheckCircleIcon className="mr-1.5 inline-block h-6 w-6" aria-hidden="true" />
          {text}
        </span>
      )

    case 'primary':
      return (
        <span className="flex items-center text-primary">
          <ClockIcon className="mr-1.5 inline-block h-6 w-6" aria-hidden="true" />
          {text}
        </span>
      )

    default:
      return <span className="flex  items-center text-mediumGrey">{text}</span>
  }
}

function getProps(rideStatus: RideStatus): { variant: SemanticColor; text: string } {
  switch (rideStatus) {
    case 'cancelled':
      return {
        variant: 'negative',
        text: 'Cancelled',
      }
    case 'created':
      return {
        variant: 'primary',
        text: 'Accepted',
      }
    case 'depositPending':
      return {
        variant: 'primary',
        text: 'Contract Signed',
      }
    case 'depositPaid':
      return {
        variant: 'positive',
        text: 'Deposit Paid',
      }
    case 'finalPaymentPending':
      return {
        variant: 'warning',
        text: 'Awaiting Final Payment',
      }
    case 'paidInFull':
      return {
        variant: 'positive',
        text: 'Paid',
      }
    case 'overagePaymentPending':
      return {
        variant: 'warning',
        text: 'Awaiting Overage Payment',
      }
    case 'completed':
      return {
        variant: 'positive',
        text: 'Complete',
      }
    default:
      return {
        variant: 'primary',
        text: rideStatus,
      }
  }
}
