import ReverseText from '../svg/reverse-text'

export default function LoadingApp() {
  return (
    <div className="relative">
      <div role="status" className="absolute inset-0 flex h-screen items-center justify-center bg-primary py-32">
        <div>
          <ReverseText width={350} />
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  )
}
