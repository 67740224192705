import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, RequestBody, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'

const url = '/api/v1/quotes/send-to-customer'
const method = 'post'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type QuoteSendToCustomerInput = RequestBody<ApiOperation>

export function quoteSendToCustomer(input: QuoteSendToCustomerInput) {
  return sendRequest<ApiOperation>({
    url,
    method,
    data: input,
  })
}

export type QuoteSendToCustomerResult = ResultType<typeof quoteSendToCustomer>
