import type { AppState } from '../context/app-state'

export default function shouldUseDarkMode(colorTheme: AppState['colorTheme']) {
  if (colorTheme === 'dark') {
    return true
  }
  if (colorTheme === 'light') {
    return false
  }
  return window.matchMedia('(prefers-color-scheme: dark)').matches
}
