import { useCallback } from 'react'
import { NavLink } from 'react-router-dom'

import { Disclosure } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

import DarkModeSwitch from '../components/dark-mode-switch'
import { useAppContext } from '../context/app-state'
import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'
import DesktopUserMenu from '../nav/desktop-user-menu'
import MobileMenu from '../nav/mobile-menu'
import MobileMenuButton from '../nav/mobile-menu-button'
import navigation from '../nav/navigation-routes'
import SearchInput from '../nav/search-input'
import CircleLogo from '../svg/circle-logo'
import type NavLinkStateCallback from '../types/nav-link-state-callback'

import { disableDarkMode, disableNotifications, disableSiteSearch } from './flags'

const linkStyle: NavLinkStateCallback = ({ isActive }) =>
  twMerge(
    isActive ? 'bg-secondary text-darkGrey' : 'text-white hover:bg-primaryMedium hover:bg-opacity-75',
    'rounded-md py-2 px-3 text-sm font-medium',
  )

export default function SiteNav() {
  const { user, colorTheme } = useAppContext()
  const appDispatch = useAppDispatch()

  const isDarkMode = disableDarkMode ? false : colorTheme === 'dark'

  const toggleTheme = useCallback(() => {
    appDispatch({ type: AppStateActionType.SetTheme, payload: isDarkMode ? 'light' : 'dark' })
  }, [appDispatch, isDarkMode])

  return (
    <Disclosure
      as="nav"
      className="border-b border-primaryMedium border-opacity-25 bg-primary dark:bg-formBackgroundOnDark lg:border-none"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl sm:px-4 lg:px-8 xl:px-0">
            <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-primary lg:border-opacity-25">
              <div className="flex items-center px-2 lg:px-0">
                <div className="flex-shrink-0">
                  <NavLink to="/" className="flex items-center">
                    <CircleLogo width={48} height={48} desc="Catch Transportation Logo" />
                  </NavLink>
                </div>
                <div className="hidden lg:ml-10 lg:block">
                  <div className="flex space-x-4">
                    {navigation.map(item => (
                      <NavLink key={item.name} to={item.href} className={linkStyle}>
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                {!disableSiteSearch && <SearchInput />}
              </div>
              <div className="flex lg:hidden">
                <MobileMenuButton open={open} />
              </div>
              <div className="hidden lg:ml-4 lg:block">
                <div className="flex items-center">
                  {!disableNotifications && (
                    <button
                      type="button"
                      className="flex-shrink-0 rounded-full bg-buttonPrimary p-1 text-backgroundPrimary hover:bg-buttonPrimaryHover focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  )}
                  {!disableDarkMode && (
                    <DarkModeSwitch
                      checked={isDarkMode}
                      sunColor="hsla(53, 91%, 58%, 1)"
                      onChange={toggleTheme}
                      className="ml-3 h-6 w-6"
                      title={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
                    />
                  )}
                  {user && <DesktopUserMenu user={user} />}
                </div>
              </div>
            </div>
          </div>
          <MobileMenu />
        </>
      )}
    </Disclosure>
  )
}
