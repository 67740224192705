import React, { forwardRef, Fragment, useCallback, useId } from 'react'
import type { ReactNode, Ref } from 'react'
import type { FieldError } from 'react-hook-form'

import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { twMerge } from 'tailwind-merge'

import type { SelectListOption } from '../types/select-list-option'

import ValidationMessage from './validation-message'

type LabelProps = React.ComponentProps<typeof Listbox.Label>
type DivProps = JSX.IntrinsicElements['div']
type ParagraphProps = JSX.IntrinsicElements['p']

interface Props<TValue extends string | number> {
  defaultValue: TValue
  name?: string
  value?: TValue
  onChange: (event: { type: string; target: { name?: string; value: TValue } }) => void
  className?: string
  options: SelectListOption<TValue>[]
  labelText: ReactNode
  helpText?: ReactNode
  helpTextProps?: ParagraphProps
  validationMessage?: ReactNode | FieldError
  validationMessageProps?: ParagraphProps
  labelProps?: LabelProps
  containerProps?: DivProps
  hideLabel?: boolean
  bottomBorderProps?: ParagraphProps
  size?: 'sm' | 'md' | 'lg'
}

const SelectList = forwardRef(function SelectList<TValue extends string | number>(
  props: Props<TValue>,
  ref: Ref<HTMLElement>,
) {
  const {
    className: inputClassName,
    name,
    defaultValue,
    value: currentValue,
    onChange,
    options,
    hideLabel,
    labelProps,
    bottomBorderProps,
    containerProps,
    labelText,
    validationMessage,
    validationMessageProps,
    helpText,
    helpTextProps,
    size,
  } = props
  const { className: containerClassName, ...otherContainerProps } = containerProps ?? {}
  const { className: labelClassName, ...otherLabelProps } = labelProps ?? {}
  const { className: helpTextClassName, ...otherHelpTextProps } = helpTextProps ?? {}
  const { className: validationMessageClassName, ...otherValidationMessageProps } = validationMessageProps ?? {}
  const { className: bottomBorderClassName, ...otherBottomBorderProps } = bottomBorderProps ?? {}
  const autoId = useId()

  const errorId = `${autoId}-error`
  const helpTextId = `${autoId}-description`

  const hasValidationMessage = !!validationMessage

  const describedby = [helpText && helpTextId, hasValidationMessage && errorId].filter(Boolean).join(' ')

  const onChangeProxy = useCallback(
    (value: TValue) => {
      onChange?.({ type: 'change', target: { name: name ?? autoId, value } })
    },
    [autoId, name, onChange],
  )

  return (
    <div {...otherContainerProps} className={twMerge('flex-grow', containerClassName)}>
      <Listbox defaultValue={defaultValue} value={currentValue} onChange={onChangeProxy} ref={ref}>
        {({ open, value }) => {
          const selectedItem = options.find(type => type.value === value) || options[0]

          return (
            <>
              <Listbox.Label
                {...otherLabelProps}
                className={twMerge(
                  'dark:text-light-100 block font-sans text-sm font-medium leading-6 text-darkGrey',
                  size === 'sm' && 'text-sm',
                  size === 'md' && 'text-md',
                  labelClassName,
                  hideLabel && 'sr-only',
                )}
              >
                {labelText}
              </Listbox.Label>
              <div className="relative mt-2">
                <Listbox.Button
                  className={twMerge(
                    'translate-all peer block w-full border-0 bg-formBackgroundOnLight px-1 py-1.5 text-formTextFocusedOnLight duration-300 placeholder:text-formPlaceHolderOnLight focus:outline-0 focus:ring-0 sm:text-sm sm:leading-6',
                    hasValidationMessage &&
                      'border-negative-400 text-negative-900 ring-negative-300 placeholder:text-negative-300 focus:ring-negative dark:ring-negative-600 border-l-8 transition-colors',
                    size === 'sm' && 'text-sm',
                    size === 'lg' && 'sm:text-md px-8 py-2 text-lg',
                    inputClassName,
                  )}
                  aria-describedby={describedby}
                >
                  <span className="block truncate text-left">
                    {typeof selectedItem.label === 'function'
                      ? selectedItem.label({ active: true })
                      : selectedItem.label}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-6 w-6 text-lightGrey" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {options.map(type => (
                      <Listbox.Option
                        key={type.value}
                        className={({ active }) =>
                          twMerge(
                            active ? 'bg-primary text-white' : 'text-darkGrey',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          )
                        }
                        value={type.value}
                      >
                        {({ selected, active, disabled }) => (
                          <>
                            <span className={twMerge(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {typeof type.label === 'function'
                                ? type.label({ selected, active, disabled })
                                : type.label}
                            </span>

                            {selected && (
                              <span
                                className={twMerge(
                                  active ? 'text-white' : 'text-primary',
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
                <div
                  {...otherBottomBorderProps}
                  className={twMerge(
                    'border-dark-300 dark:border-light-300 peer-focus:dark:border-primary-400 absolute inset-x-0 bottom-0 border-t peer-focus:border-t-2 peer-focus:border-primary',
                    bottomBorderClassName,
                  )}
                  aria-hidden="true"
                />
                {helpText && (
                  <p
                    {...otherHelpTextProps}
                    className={twMerge('dark:text-light mt-2 text-sm text-grey500', helpTextClassName)}
                    id={helpTextId}
                  >
                    {helpText}
                  </p>
                )}
                {validationMessage && (
                  <p
                    {...otherValidationMessageProps}
                    className={twMerge('text-negative-600 mt-2 text-sm', validationMessageClassName)}
                    id={errorId}
                    role="alert"
                  >
                    <ValidationMessage message={validationMessage} />
                  </p>
                )}
              </div>
            </>
          )
        }}
      </Listbox>
    </div>
  )
})

SelectList.displayName = 'SelectList'

export default SelectList
