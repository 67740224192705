import type { GenericAbortSignal } from 'axios'

import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/customer/{customerUuid}/quote'
const method = 'post'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type QuoteCreateForCustomerInput = PathParams<ApiOperation>

export function quoteCreateForCustomer(input: QuoteCreateForCustomerInput, abortSignal?: GenericAbortSignal) {
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, input),
    method,
    signal: abortSignal,
  })
}

export type QuoteCreateForCustomerResponse = ResultType<typeof quoteCreateForCustomer>
