interface Props {
  children: React.ReactNode
}

export default function CardActionContainer(props: Props) {
  const { children } = props
  return (
    <div className="-mt-px flex divide-x divide-dividerOnLight border-t border-dividerOnLight">
      <div className="flex w-0 flex-1">{children}</div>
    </div>
  )
}
