import { useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { CheckIcon, PencilIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { DateTime } from 'luxon'
import { twMerge } from 'tailwind-merge'
import { useOnClickOutside } from 'usehooks-ts'

import type { ItineraryItem } from '../services/types'

import RoundedButton from './button-rounded'
import DisplayDate from './display-date'
import DisplayTime from './display-time'

type DivProps = JSX.IntrinsicElements['div']

interface Props extends DivProps {
  itineraryItem: ItineraryItem
  onEdit: (item: ItineraryItem) => void
  onDelete: (itemUuid: string) => void
  editable?: boolean
}

export default function ItineraryLocation({ itineraryItem, onEdit, onDelete, editable = false, ...divProps }: Props) {
  const { className, ...otherDivProps } = divProps
  const [confirmDelete, setConfirmDelete] = useState(false)
  const ref = useRef<HTMLButtonElement>(null)

  useOnClickOutside<HTMLButtonElement>(ref, () => {
    setConfirmDelete(false)
  })

  const onEditClicked = () => {
    onEdit(itineraryItem)
  }

  const onDeleteClicked = () => {
    if (confirmDelete) {
      onDelete(itineraryItem.uuid)
    } else {
      setConfirmDelete(true)
    }
  }

  return (
    <div {...otherDivProps} className={twMerge('flex items-center justify-between gap-x-6 py-5', className)}>
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <div className="flex flex-col text-sm font-semibold leading-6 text-darkGrey">
            <span className="dark:text-light-300 text-grey500">
              <DisplayDate date={itineraryItem.date} options={DateTime.DATE_MED_WITH_WEEKDAY} /> at{' '}
              <DisplayTime time={itineraryItem.time} />
            </span>
            <ReactMarkdown className="font-medium">{itineraryItem.location}</ReactMarkdown>
          </div>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        {editable && (
          <>
            <RoundedButton
              type="button"
              className="inline-flex w-16 items-center gap-x-1.5 lg:w-28"
              variant="secondary"
              size="sm"
              onClick={onEditClicked}
            >
              <PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              <span className="sr-only lg:not-sr-only">Edit</span>
            </RoundedButton>
            <RoundedButton
              ref={ref}
              type="button"
              className="inline-flex w-16 items-center gap-x-1.5 lg:w-28"
              variant="negative"
              size="sm"
              onClick={onDeleteClicked}
            >
              {confirmDelete ? (
                <>
                  <CheckIcon className="-ml-0.5 h-5 w-5 " aria-hidden="true" />
                  <span className="sr-only lg:not-sr-only">Confirm</span>
                </>
              ) : (
                <>
                  <XMarkIcon className="-ml-0.5 h-5 w-5 " aria-hidden="true" />
                  <span className="sr-only lg:not-sr-only">Delete</span>
                </>
              )}
            </RoundedButton>
          </>
        )}
      </div>
    </div>
  )
}
