import type { GenericAbortSignal } from 'axios'

import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, QueryParams, ResultItemType, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'

const url = '/api/v1/users'
const method = 'get'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type UserSearchInput = QueryParams<ApiOperation>

export default function userSearch(input: UserSearchInput, abortSignal?: GenericAbortSignal) {
  return sendRequest<ApiOperation>({
    url,
    method,
    params: input,
    signal: abortSignal,
  })
}

export type UserSearchResults = ResultType<typeof userSearch>
export type UserSearchItem = ResultItemType<UserSearchResults>
