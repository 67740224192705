import React from 'react'
import { NavLink } from 'react-router-dom'

import { twMerge } from 'tailwind-merge'

import type { ButtonVariant } from '../types/ButtonVariant'

type NavLinkProps = React.ComponentProps<typeof NavLink>

interface Props extends Omit<NavLinkProps, 'className'> {
  variant?: ButtonVariant
  className?: string
  activeClassName?: string
  pendingClassName?: string
  size?: 'sm' | 'md' | 'lg'
  as?: 'button'
  disabled?: boolean
}

export default function NavigationLink(props: Props) {
  const {
    variant = 'primary',
    size = 'md',
    activeClassName,
    pendingClassName,
    className,
    children,
    as,
    disabled,
    'aria-disabled': ariaDisabled,
    ...navLinkProps
  } = props

  const classNameCallback: NavLinkProps['className'] = ({ isActive, isPending }) => {
    if (as === 'button') {
      return twMerge(
        'flex justify-center rounded-full border-2 px-3 py-1.5 font-sans font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2',
        size === 'sm' && 'px-2.5 py-1.5 text-xs',
        size === 'md' && 'px-3 py-3 text-sm',
        size === 'lg' && 'px-4 py-5 text-base',
        variant === 'primary' &&
          'border-primary bg-buttonPrimary text-white hover:bg-buttonPrimaryHover focus-visible:ring-primary',
        variant === 'secondary' &&
          'border-buttonPrimary bg-buttonTertiary text-buttonPrimary hover:border-buttonPrimaryHover hover:text-buttonPrimaryHover focus-visible:ring-primary',
        variant === 'accent' &&
          'border-buttonSecondary bg-buttonSecondary text-darkGrey hover:bg-buttonSecondaryHover focus-visible:ring-buttonSecondaryHover',
        variant === 'negative' && 'bg-errorOnLight text-white hover:bg-errorOnDark focus-visible:ring-errorOnDark',
        variant === 'positive' &&
          'bg-successOnLight text-white hover:bg-successOnDark focus-visible:ring-successOnDark',
        disabled && 'cursor-not-allowed opacity-50',
        className,
      )
    }

    return twMerge(
      'font-sans font-medium leading-6',
      isActive && activeClassName,
      isPending && pendingClassName,
      variant === 'primary' && 'text-buttonPrimary hover:text-buttonPrimaryHover',
      variant === 'secondary' && 'text-buttonTertiary hover:text-buttonTertiaryHover',
      variant === 'accent' && 'text-buttonSecondary hover:text-buttonSecondaryHover',
      variant === 'negative' && 'hover:text-errorOnLight text-errorOnDark',
      variant === 'positive' && 'hover:text-successOnLight text-successOnDark ',
      size === 'sm' && 'text-sm',
      size === 'md' && 'text-md',
      size === 'lg' && 'text-lg',
      disabled && 'opacity-50 cursor-not-allowed',
      className,
    )
  }

  return (
    <NavLink {...navLinkProps} className={classNameCallback} aria-disabled={ariaDisabled ?? disabled}>
      {children}
    </NavLink>
  )
}
