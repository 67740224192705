import { BanknotesIcon } from '@heroicons/react/20/solid'
import { twMerge } from 'tailwind-merge'

import { useAppContext } from '../../context/app-state'
import { RideStateActionType, useRideDispatch } from '../../context/ride-state-reducer'
import type { Rider } from '../../services/rideDetailGet'
import MoneySign from '../../svg/money-sign'
import Card from '../card'
import CardActionContainer from '../card-action-container'
import CardActionLink from '../card-action-link'
import DisplayDate from '../display-date'
import DisplayMoney from '../display-money'
import Headline from '../headline'
import TableRow from '../table-row'

type Payment = Rider['payments'][0]

interface Props {
  payment: Payment
}

export default function PaymentCard(props: Props) {
  const { config } = useAppContext()
  const { stripeDashboardUrl } = config
  const { payment } = props

  const rideDispatch = useRideDispatch()

  const {
    uuid,
    purpose,
    paymentStatus,
    dueDate,
    paymentDate,
    paymentType,
    amount,
    fee,
    total,
    transactionId,
    description,
    metadata,
  } = payment

  const { enteredBy, notes, card } = metadata ?? {}

  const onMarkAsPaid: JSX.IntrinsicElements['a']['onClick'] = e => {
    e.preventDefault()
    rideDispatch({ type: RideStateActionType.SetActivePayment, payload: payment })
  }

  const cardActions =
    paymentStatus !== 'paid' ? (
      <CardActionContainer>
        <CardActionLink to="#" onClick={onMarkAsPaid} className="rounded-b-lg">
          <BanknotesIcon className="text-dark h-5 w-6" aria-hidden="true" />
          Mark as Paid
        </CardActionLink>
      </CardActionContainer>
    ) : undefined

  return (
    <Card
      data-uuid={uuid}
      cardActions={cardActions}
      className={twMerge(
        (paymentStatus === 'paid' || paymentStatus === 'pending') && 'border-l-4 border-successOnLight',
        paymentStatus === 'scheduled' && 'border-l-4 border-secondary',
        paymentStatus === 'due' && 'border-l-4 border-primary',
        (paymentStatus === 'declined' || paymentStatus === 'cancelled' || paymentStatus === 'refunded') &&
          'border-l-4 border-errorOnLight',
      )}
    >
      <div
        className={twMerge(
          'flex flex-row justify-between rounded-t-lg p-6',
          (paymentStatus === 'paid' || paymentStatus === 'pending') && 'border-l-4 bg-successLightTint',
          paymentStatus === 'scheduled' && 'border-l-4 bg-secondaryLight',
          paymentStatus === 'due' && 'border-l-4 bg-primaryLight',
          (paymentStatus === 'declined' || paymentStatus === 'cancelled' || paymentStatus === 'refunded') &&
            'border-l-4 bg-errorLightTint',
        )}
      >
        <PaymentPurpose purpose={purpose} />
        <div>
          <PaymentStatus status={paymentStatus} paymentType={paymentType} />
        </div>
      </div>
      <div className="divide-y px-6">
        {!!notes && <TableRow label="Notes">{notes}</TableRow>}
        {!!enteredBy && <TableRow label="Entered By">{enteredBy}</TableRow>}
        <TableRow label="Due">
          <DisplayDate date={dueDate} />
        </TableRow>
        <TableRow label="Description">{description}</TableRow>
        {!!transactionId && (
          <>
            <TableRow label="Transaction ID">
              <a href={`${stripeDashboardUrl}/payments/${transactionId}`} target="_blank" rel="noopener noreferrer">
                {transactionId}
              </a>
            </TableRow>
            {!!card && <TableRow label="Card">{card}</TableRow>}
          </>
        )}
        {!!paymentDate && (
          <TableRow label="Payment Date">
            <DisplayDate date={paymentDate} />
          </TableRow>
        )}
        <TableRow label="Amount">
          <DisplayMoney amount={amount} />
        </TableRow>
        <TableRow label="Fee">
          <DisplayMoney amount={fee} />
        </TableRow>
        <TableRow label={<span className="text-lg font-bold">Total</span>}>
          <DisplayMoney className="text-lg font-bold" amount={total} />
        </TableRow>
      </div>
    </Card>
  )
}

function PaymentStatus({
  status,
  paymentType,
}: {
  status: Payment['paymentStatus']
  paymentType: Payment['paymentType']
}) {
  const className = getStatusClass(status)
  return (
    <div className={twMerge('flex h-full flex-row items-center capitalize', className)}>
      <div>
        {status}
        {paymentType === 'manual' && <>*</>}
      </div>
      <MoneySign className="ml-0.5 inline-block h-6 w-6" aria-hidden="true" />
    </div>
  )
}

function getStatusClass(status: Payment['paymentStatus']) {
  switch (status) {
    case 'paid':
    case 'pending':
      return 'text-successOnLight'
    case 'due':
    case 'scheduled':
      return 'text-primary'
    case 'refunded':
    case 'cancelled':
    case 'declined':
      return 'text-errorOnLight'
    default:
      return 'text-mediumGrey'
  }
}

function PaymentPurpose({ purpose }: { purpose: Payment['purpose'] }) {
  const value = getPurposeName(purpose)

  return (
    <Headline level={5} className="text-mediumGrey">
      {value}
    </Headline>
  )
}

function getPurposeName(purpose: Payment['purpose']) {
  switch (purpose) {
    case 'deposit':
      return 'Deposit Payment'
    case 'finalPayment':
      return 'Final Payment'
    case 'fullPayment':
      return 'Full Payment'
    case 'overage':
      return 'Overage Payment'
    default:
      return 'Payment'
  }
}
