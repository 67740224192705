import { createContext, useContext } from 'react'
import type { Dispatch } from 'react'

import type { RideState } from './ride-state'

export enum RideStateActionType {
  SetRide = 'SET_RIDE',
  SetActiveRider = 'SET_ACTIVE_RIDER',
  ShowOverview = 'SHOW_OVERVIEW',
  UpdateItinerary = 'UPDATE_ITINERARY',
  UpdateComments = 'UPDATE_COMMENTS',
  SetActivePayment = 'SET_ACTIVE_PAYMENT',
  SetEditMode = 'SET_EDIT_MODE',
}

export type RideStateAction =
  | {
      type: RideStateActionType.SetRide
      payload: RideState['ride']
    }
  | {
      type: RideStateActionType.SetActiveRider
      payload: RideState['activeRider']
    }
  | {
      type: RideStateActionType.UpdateItinerary
      payload: RideState['ride']['itinerary']
    }
  | {
      type: RideStateActionType.SetActivePayment
      payload: RideState['activePayment']
    }
  | {
      type: RideStateActionType.ShowOverview
    }
  | {
      type: RideStateActionType.UpdateComments
      payload: RideState['ride']['comments']
    }
  | {
      type: RideStateActionType.SetEditMode
      payload: RideState['editMode']
    }

export const rideStateReducer = (state: RideState, action: RideStateAction): Readonly<RideState> => {
  switch (action.type) {
    case RideStateActionType.SetRide:
      return {
        ...state,
        ride: action.payload,
        activeRider: state.activeRider
          ? {
              ...state.activeRider,
              ...action.payload.riders.find(r => r.uuid === state.activeRider?.uuid),
            }
          : undefined,
        activePayment: undefined,
      }
    case RideStateActionType.SetActiveRider:
      return {
        ...state,
        activeRider: action.payload,
        activePayment: undefined,
      }
    case RideStateActionType.SetActivePayment:
      return {
        ...state,
        activePayment: action.payload,
      }
    case RideStateActionType.ShowOverview:
      return {
        ...state,
        activePayment: undefined,
        activeRider: undefined,
      }
    case RideStateActionType.UpdateItinerary:
      return {
        ...state,
        ride: {
          ...state.ride,
          itinerary: action.payload,
        },
      }
    case RideStateActionType.UpdateComments:
      return {
        ...state,
        ride: {
          ...state.ride,
          comments: action.payload,
        },
      }
    case RideStateActionType.SetEditMode:
      return {
        ...state,
        editMode: action.payload,
      }
    default:
      return state
  }
}

export const RideDispatchContext = createContext<Dispatch<RideStateAction>>(undefined as never)

export const useRideDispatch = () => useContext(RideDispatchContext)
