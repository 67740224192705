import type { GenericAbortSignal } from 'axios'

import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/customer/{customerUuid}/requests'
const method = 'get'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type CustomerRideRequestInput = PathParams<ApiOperation>

export function customerRideRequests(input: CustomerRideRequestInput, abortSignal?: GenericAbortSignal) {
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, input),
    method,
    signal: abortSignal,
  })
}

export type CustomerRideRequestResponse = ResultType<typeof customerRideRequests>
export type CustomerRideRequestItem = CustomerRideRequestResponse['requests'][number]
export type CustomerRideRequestForm = CustomerRideRequestItem['form']
export type CustomerInfo = CustomerRideRequestResponse['customer']
