import type { GenericAbortSignal } from 'axios'

import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, QueryParams, ResultItemType, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'

const url = '/api/v1/quotes'
const method = 'get'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type QuoteSearchInput = QueryParams<ApiOperation>

export function quoteSearch(input?: QuoteSearchInput, abortSignal?: GenericAbortSignal) {
  return sendRequest<ApiOperation>({
    url,
    method,
    params: input,
    signal: abortSignal,
  })
}

export type QuoteSearchResults = ResultType<typeof quoteSearch>
export type QuoteSearchItem = ResultItemType<QuoteSearchResults>
