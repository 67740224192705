import { NavLink } from 'react-router-dom'

import { twMerge } from 'tailwind-merge'

import ReverseText from '../svg/reverse-text'

type FooterProps = JSX.IntrinsicElements['footer']

type Props = FooterProps

export default function Footer(props: Props) {
  const { className, ...footerProps } = props
  return (
    <footer
      {...footerProps}
      className={twMerge('mt-4 bg-primary px-6 pb-10 pt-5 dark:bg-formBackgroundOnDark', className)}
    >
      <div className="flex flex-col justify-around lg:flex-row">
        <div className="order-last flex flex-col">
          <ReverseText width={150} />
          <p className="mt-2 text-sm text-white">&copy; {new Date().getFullYear()} Catch Transport, LLC</p>
        </div>
        <div className="my-4 flex flex-col lg:my-0">
          <p className="mb-3 border-b-2 border-secondary text-white">
            <strong>Ride Management</strong>
          </p>
          <p className="text-white">
            <NavLink to="/">Quotes</NavLink>
          </p>
          <p className="text-white">
            <NavLink to="/rides">Rides</NavLink>
          </p>
        </div>
        <div className="my-4 flex flex-col lg:my-0">
          <p className="mb-3 border-b-2 border-secondary text-white">
            <strong>Administration</strong>
          </p>
          <p className="text-white">
            <NavLink to="/customers">Customers</NavLink>
          </p>
          <p className="text-white">
            <NavLink to="/users">Users</NavLink>
          </p>
        </div>
      </div>
    </footer>
  )
}
