import { AuthenticationDetails } from 'amazon-cognito-identity-js'

import type CognitoAuthResponse from './cognito-auth-response'
import createCognitoUser from './createCognitoUser'
import promisifyAuthCallbacks from './promisifyAuthCallback'

export default async function authenticateCognitoUser(username: string, password: string) {
  const user = await createCognitoUser(username)

  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  })

  return new Promise<CognitoAuthResponse>((resolve, reject) => {
    user.authenticateUser(authenticationDetails, promisifyAuthCallbacks(user, resolve, reject))
  })
}
