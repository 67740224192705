import type { GenericAbortSignal } from 'axios'

import type { paths } from '../types/_generated_admin_v1_ts'
import type { Operation, PathParams, ResultType } from '../types/schema-utils'

import sendRequest from './sendRequest'
import substitutePathParams from './substitutePathParams'

const url = '/api/v1/customer/{customerUuid}/requests/{rideRequestUuid}'
const method = 'post'

type ApiOperation = Operation<paths, typeof url, typeof method>

export type QuoteCreateFromRideRequestInput = PathParams<ApiOperation>

export function quoteCreateFromRideRequest(input: QuoteCreateFromRideRequestInput, abortSignal?: GenericAbortSignal) {
  return sendRequest<ApiOperation>({
    url: substitutePathParams(url, input),
    method,
    signal: abortSignal,
  })
}

export type QuoteCreateFromRideResponse = ResultType<typeof quoteCreateFromRideRequest>
