import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ArrowLeftCircleIcon } from '@heroicons/react/20/solid'

import RoundedButton from '../components/button-rounded'
import { LoginFormActionType, useLoginFormDispatch } from '../components/login/login-form-context'
import NavigationLink from '../components/navigation-link'
import TextInput from '../components/text-input'
import initiateForgotPassword from '../services/cognito/initiateForgotPassword'
import getErrorMessage from '../utils/getErrorMessage'

interface FormData {
  email: string
}

export default function PasswordForgotPage() {
  const loginFormDispatch = useLoginFormDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  useEffect(() => {
    loginFormDispatch({ type: LoginFormActionType.SetLoginState, payload: { formTitle: 'Forgot Password' } })
  }, [loginFormDispatch])

  const onSubmit = (data: FormData) => {
    const { email } = data

    initiateForgotPassword(email)
      .then(({ user, destination, deliveryMedium }) => {
        navigate('/confirm-forgot-password', {
          state: {
            username: user.getUsername(),
            successMessage: `A verification code has been sent to ${destination} via ${deliveryMedium}`,
          },
        })
      })
      .catch(err => {
        loginFormDispatch({
          type: LoginFormActionType.SetLoginState,
          payload: {
            errorMessage: getErrorMessage(err),
          },
        })
      })
  }

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        {...register('email', { required: true })}
        labelText="Email"
        type="email"
        defaultValue={searchParams.get('email') ?? undefined}
        autoComplete="email"
        placeholder="Enter your email address"
        validationMessage={errors.email?.message}
        required
      />

      <div>
        <RoundedButton type="submit" className="w-full" size="xl" variant="accent">
          Reset Password
        </RoundedButton>
      </div>
      <div className="mt-6">
        <NavigationLink to="/login" className="inline-flex items-center gap-x-1.5" variant="accent">
          <ArrowLeftCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> Back to Login
        </NavigationLink>
      </div>
    </form>
  )
}
