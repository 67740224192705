import { createContext, useContext } from 'react'
import type { ReactNode } from 'react'

import type { AdminConfig } from '../services/configGet'

export interface User {
  id: string
  fullName: string
  email?: string
  imageUrl?: string
  phone?: string
  initials: string
  isAdmin: boolean
  isRider: boolean
  firstName: string
  lastName: string
}

export interface AppState {
  navTitle: string
  actionPanel: ReactNode | null
  backPanel: ReactNode | null
  user: User | null
  colorTheme?: 'light' | 'dark'
  config: AdminConfig
}

export const defaultAppState: AppState = {
  navTitle: 'Dashboard',
  actionPanel: null,
  backPanel: null,
  user: null,
  config: undefined as never,
}

export const AppStateContext = createContext<AppState>(defaultAppState)

export const useAppContext = (): Readonly<AppState> => useContext(AppStateContext)
