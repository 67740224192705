import { useEffect, useReducer } from 'react'
import type { ReactNode } from 'react'

import LoadingApp from '../components/loading-app'
import { Client, configGet } from '../services/configGet'

import { AppStateContext, defaultAppState } from './app-state'
import { AppDispatchContext, AppStateActionType, appStateReducer } from './app-state-reducer'

interface Props {
  children?: ReactNode | undefined
}

export default function AppContextProvider(props: Props) {
  const { children } = props
  const [state, dispatch] = useReducer(appStateReducer, defaultAppState)

  useEffect(() => {
    configGet(Client.admin).then(config => {
      setTimeout(() => {
        dispatch({ type: AppStateActionType.SetConfig, payload: config })
      }, 1000)
    })

    const currentTheme = localStorage.getItem('colorTheme')
    if (currentTheme === 'dark' || currentTheme === 'light' || currentTheme === undefined) {
      dispatch({ type: AppStateActionType.SetTheme, payload: currentTheme })
    }
  }, [])

  if (state.config === undefined) {
    return <LoadingApp />
  }

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}
