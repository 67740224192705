import EmptyContainer from '../empty-container'
import Headline from '../headline'

export default function RideSearchEmptyResults() {
  return (
    <EmptyContainer>
      <Headline level={4} className="mb-4">
        No matching rides found
      </Headline>
    </EmptyContainer>
  )
}
