import type { QuoteSearchItem } from '../../services/quoteSearch'
import CarIcon from '../../svg/car-icon'
import CardActionContainer from '../card-action-container'
import CardActionLink from '../card-action-link'

interface Props {
  quote: QuoteSearchItem
}

export default function QuoteSearchHitActions(props: Props) {
  const { quote } = props

  return (
    <CardActionContainer>
      <CardActionLink to={`/quotes/${quote.quoteUuid}`} className="rounded-b-lg">
        <CarIcon className="h-5 w-6" aria-hidden="true" />
        View Quote
      </CardActionLink>
    </CardActionContainer>
  )
}
