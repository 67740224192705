import { Link } from 'react-router-dom'

import { DocumentTextIcon } from '@heroicons/react/24/outline'

import type { RideSearchResultItem } from '../../services/rideSearch'
import DisplayDate from '../display-date'
import DisplayMoney from '../display-money'
import TableRow from '../table-row'

import DisplayRideStatus from './display-ride-status'

interface Props {
  ride: RideSearchResultItem
}

export default function RideSearchHitInfoTable(props: Props) {
  const { ride } = props
  const {
    groupLead,
    externalId,
    contractSignedAt,
    rideStatus,
    numberOfRiders,
    vehicleCapacity,
    vehicleType,
    depositAmount,
    depositDueDate,
    finalAmount,
    totalAmount,
    totalAmountWithOverages,
    totalDueDate,
  } = ride
  const { uuid: customerUuid, firstName, lastName } = groupLead || {}
  return (
    <div className="divide-y">
      <TableRow label="Organizer">
        <Link to={`/customers/${customerUuid}`} className="flex-shrink-0">
          <div className="flex items-center truncate text-lg text-primary">
            {firstName} {lastName}
            <span className="sr-only">
              Ride organized by {firstName} {lastName}
            </span>
          </div>
        </Link>
      </TableRow>
      <TableRow label="Contract">
        {contractSignedAt ? (
          <div className="flex items-center text-successOnLight">
            <DocumentTextIcon className="-ml-0.5 mr-1 h-4 w-4" aria-hidden="true" /> Signed&nbsp;
            <DisplayDate
              date={contractSignedAt}
              options={{
                dateStyle: 'long',
              }}
            />
          </div>
        ) : (
          <div className="flex items-center text-errorOnLight">
            <DocumentTextIcon className="-ml-0.5 mr-1 h-4 w-4" aria-hidden="true" /> Not Signed
          </div>
        )}
      </TableRow>
      <TableRow label="Status">
        <DisplayRideStatus rideStatus={rideStatus} />
      </TableRow>
      <TableRow label="Vehicle">
        <div className="flex items-center gap-x-2">
          <span className="capitalize">{vehicleType}</span>&nbsp;({numberOfRiders}/{vehicleCapacity})
        </div>
      </TableRow>
      {!!externalId && <TableRow label="ID">{externalId}</TableRow>}
      {!!depositAmount && depositAmount > 0 && (
        <TableRow
          label={
            <>
              Deposit Amount <br />
              <DisplayDate date={depositDueDate} className="text-xs text-errorOnLight">
                {formattedDate => <>due {formattedDate}</>}
              </DisplayDate>
            </>
          }
        >
          <DisplayMoney amount={depositAmount} />
        </TableRow>
      )}
      <TableRow
        label={
          <>
            Final Amount <br />
            <DisplayDate date={totalDueDate} className="text-xs text-errorOnLight">
              {formattedDate => <>due {formattedDate}</>}
            </DisplayDate>
          </>
        }
      >
        <DisplayMoney amount={finalAmount} />
      </TableRow>
      <TableRow
        label={
          <span className="font-bold">
            Ride Total
            <br />
            {totalAmountWithOverages > totalAmount && <span className="text-xs text-errorOnLight">w/ overages</span>}
          </span>
        }
      >
        <DisplayMoney className="font-bold" amount={totalAmountWithOverages} />
      </TableRow>
    </div>
  )
}
