import type { CustomerSearchResults } from '../../services/customerSearch'
import Card from '../card'
import DisplayUser from '../shared/display-user'

import CustomerSearchHitActions from './customer-search-hit-actions'
import CustomerSearchHitInfoTable from './customer-search-hit-info-table'

interface Props {
  customer: CustomerSearchResults['results'][number]
}

export default function CustomerSearchHit(props: Props) {
  const { customer } = props

  const actionButtons = <CustomerSearchHitActions customer={customer} />

  return (
    <Card key={customer.uuid} cardActions={actionButtons}>
      <div className="p-6">
        <DisplayUser
          user={customer}
          paymentProfile={customer?.paymentProviderLink?.href}
          customerUuid={customer.uuid}
        />
      </div>
      <CustomerSearchHitInfoTable customer={customer} />
    </Card>
  )
}
