import { DateTime } from 'luxon'

export default function formatDateTime(
  dateTimeValue: DateTime | undefined,
  relative?: boolean,
  options?: Intl.DateTimeFormatOptions,
) {
  if (!dateTimeValue) {
    return null
  }

  return relative ? dateTimeValue.toRelative() : dateTimeValue.toLocaleString(options ?? DateTime.DATE_SHORT)
}
