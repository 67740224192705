import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import LoadingTable from '../components/loading-table'
import NavigationLink from '../components/navigation-link'
import QuoteSearchEmptyResults from '../components/quotes/quote-search-empty-results'
import QuoteSearchHit from '../components/quotes/quote-search-hit'
import type { FormData } from '../components/shared/search-form'
import SearchForm from '../components/shared/search-form'
import { AppStateActionType, useAppDispatch } from '../context/app-state-reducer'
import type { QuoteSearchInput, QuoteSearchResults } from '../services/quoteSearch'
import { quoteSearch } from '../services/quoteSearch'
import CarIcon from '../svg/car-icon'
import createErrorToast from '../utils/createErrorToast'

const excludeQuoteStatus: QuoteSearchInput['excludeQuoteStatus'] = ['expired', 'declined', 'booked', 'cancelled']

export default function QuotesPage() {
  const dispatch = useAppDispatch()
  const { addToast } = useToasts()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const [searchFilter, setSearchFilter] = useState<QuoteSearchInput>()
  const [searching, setSearching] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<QuoteSearchResults>()

  useEffect(() => {
    dispatch({ type: AppStateActionType.SetNavTitle, payload: 'Quotes' })
    dispatch({
      type: AppStateActionType.SetActionPanel,
      payload: (
        <NavigationLink as="button" to="/customers" variant="accent">
          <CarIcon className="mx-2 h-6 w-6" />
          Create Quote
        </NavigationLink>
      ),
    })
  }, [dispatch])

  const onSearchFormSubmit = useCallback((formData: FormData) => {
    setSearchFilter(s => ({
      ...s,
      excludeQuoteStatus: formData.searchText ? [] : excludeQuoteStatus,
      searchText: formData.searchText,
    }))
  }, [])

  useEffect(() => {
    setSearchFilter({
      perPage: 20,
      page: 1,
      orderBy: 'createdAt',
      sortDirection: 'desc',
      excludeQuoteStatus,
    })
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    setSearching(true)
    setSearchResults(undefined)

    if (searchFilter) {
      quoteSearch(searchFilter, abortController.signal)
        .then(setSearchResults)
        .catch(err => {
          createErrorToast(err, addToast)
        })
        .finally(() => {
          setSearching(false)
        })
    }
    return () => {
      abortController.abort()
    }
  }, [searchFilter, addToast])

  const hits = searchResults?.results ?? []

  return (
    <>
      <SearchForm
        handleSubmit={handleSubmit}
        onSearchFormSubmit={onSearchFormSubmit}
        register={register}
        errors={errors}
        placeholder="Search by event name, id or by organizer name, email, or phone"
      />
      <div>
        {searching && <LoadingTable />}
        {!searching && hits.length === 0 && <QuoteSearchEmptyResults />}
        {!searching && hits.length > 0 && (
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {hits.map(quote => (
              <QuoteSearchHit key={quote.quoteUuid} quote={quote} />
            ))}
          </ul>
        )}
      </div>
    </>
  )
}
