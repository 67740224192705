import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { Semaphore } from 'async-mutex'

import { Client, configGet } from './configGet'

const userPools = new Map<Client, CognitoUserPool>()

async function getAdminPoolData() {
  const data = await configGet(Client.admin)

  return {
    UserPoolId: data.cognitoPoolId,
    ClientId: data.cognitoClientId,
  }
}

async function getAppPoolData() {
  const data = await configGet(Client.app)

  return {
    UserPoolId: data.cognitoPoolId,
    ClientId: data.cognitoClientId,
  }
}

const lock = new Semaphore(1)

export default async function userPoolGet(client = Client.admin) {
  try {
    await lock.acquire()

    const cachedUserPool = userPools.get(client)

    if (cachedUserPool) {
      return cachedUserPool
    }

    const poolData = client === Client.admin ? await getAdminPoolData() : await getAppPoolData()

    const userPool = new CognitoUserPool(poolData)
    userPools.set(client, userPool)

    return userPool
  } finally {
    lock.release()
  }
}
