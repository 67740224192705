import { twMerge } from 'tailwind-merge'

type SpanProps = JSX.IntrinsicElements['span']

interface Props extends SpanProps {
  initials: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  textProps?: SpanProps
}

export default function AvatarInitials(props: Props) {
  const { className, initials, size, textProps, ...spanProps } = props
  const { className: textClassName, ...otherTextProps } = textProps ?? {}

  return (
    <span
      {...spanProps}
      className={twMerge(
        'inline-flex items-center justify-center rounded-full bg-primary',
        size === 'xs' && 'h-6 w-6',
        size === 'sm' && 'h-8 w-8',
        size === 'md' && 'h-10 w-10',
        size === 'lg' && 'h-12 w-12',
        size === 'xl' && 'h-14 w-14',
        className,
      )}
    >
      <span
        {...otherTextProps}
        className={twMerge(
          'font-medium leading-none text-white',
          size === 'xs' && 'text-xs',
          size === 'sm' && 'text-sm',
          size === 'lg' && 'text-lg',
          size === 'xl' && 'text-xl',
          textClassName,
        )}
      >
        {initials}
      </span>
    </span>
  )
}
