import type { RideDetailResult, Rider } from '../../services/rideDetailGet'
import Block from '../block'
import DisplayUser from '../shared/display-user'

import RiderDetailInfoTable from './rider-detail-info-table'
import RiderDetailPayments from './rider-detail-payments'

interface Props {
  ride: RideDetailResult
  rider: Rider
}

export default function RiderDetail(props: Props) {
  const { ride, rider } = props

  const paymentProfile = ride.riderPaymentProfileLinks.find(x => x.riderUuid === rider.uuid)
  const role = getRoleDisplayValue(rider.riderRole)

  return (
    <>
      <Block as="div" className="mb-6 p-6">
        <DisplayUser
          user={rider}
          paymentProfile={paymentProfile?.paymentProfileLink?.href}
          customerUuid={rider.customerUuid}
          role={role}
        />
      </Block>
      <RiderDetailInfoTable rider={rider} />
      <RiderDetailPayments rider={rider} />
    </>
  )
}

function getRoleDisplayValue(role: Rider['riderRole']) {
  switch (role) {
    case 'groupLeader':
      return 'Event Organizer'
    case 'payingGroupMember':
      return 'Paying Group Member'
    default:
      return 'Non-Paying Group Member'
  }
}
