/* eslint-disable @typescript-eslint/no-explicit-any */

import type { CognitoUser, IAuthenticationCallback } from 'amazon-cognito-identity-js'

import type CognitoAuthResponse from './cognito-auth-response'

export default function promisifyAuthCallbacks(
  user: CognitoUser,
  resolve: (value: CognitoAuthResponse) => void,
  reject: (reason?: any) => void,
) {
  return {
    onSuccess(session, userConfirmationNecessary) {
      resolve({
        type: 'success',
        user,
        session,
        userConfirmationNecessary,
      })
    },
    newPasswordRequired(userAttributes, requiredAttributes) {
      resolve({
        type: 'newPasswordRequired',
        user,
        userAttributes,
        requiredAttributes,
      })
    },
    mfaRequired(challengeName, challengeParameters) {
      resolve({
        type: 'mfaRequired',
        user,
        challengeName,
        challengeParameters,
      })
    },
    customChallenge(challengeParameters) {
      resolve({
        type: 'customChallenge',
        user,
        challengeParameters,
      })
    },
    totpRequired(challengeName, challengeParameters) {
      resolve({
        type: 'totpRequired',
        user,
        challengeName,
        challengeParameters,
      })
    },
    mfaSetup(challengeName, challengeParameters) {
      resolve({
        type: 'mfaSetup',
        user,
        challengeName,
        challengeParameters,
      })
    },
    selectMFAType(challengeName, challengeParameters) {
      resolve({
        type: 'selectMFAType',
        user,
        challengeName,
        challengeParameters,
      })
    },
    onFailure(err) {
      reject(err)
    },
  } as IAuthenticationCallback
}
